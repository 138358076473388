div.phone-button-component {
  display: flex;
  margin-left: 5px;

  span.phone-button-component-trigger {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;

    svg {
      color: #707070;
      height: 35px;
      width: 35px;
      padding: 5px;
      stroke-width: 1.5;
    }
  }

  span.phone-button-component-trigger.disconnected {
    svg {
      color: #f36a6b;
    }
  }

  span.phone-button-component-trigger.connected {
    box-shadow: inset 0px 0px 2px 2px #4A69BD;

    svg {
      color: #4A69BD;
    }
  }

  span.phone-button-component-trigger.in-call {
    box-shadow: inset 0px 0px 2px 2px #28973f;

    svg {
      color: #28973f;
    }
  }

  span.phone-button-component-trigger:hover,
  span.phone-button-component-trigger.opened {
    background-color: #dddddd;
    transition: 0.3s;
  }

  .ringing {
    animation-name: trin;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .ringing-live {
    animation: live-icon 1.5s ease infinite backwards;
  }

  .in-call {
    animation: live-in-call 1.5s ease infinite backwards;
  }

  @keyframes trin {
    from {
      transform:rotate3d(0, 0, 1, 0deg);
    }
    20%, 32%, 44%, 56%, 68% {
      transform: rotate3d(0, 0, 1, 0deg);
    }
    23%, 35%, 47%, 59%, 71% {
      transform: rotate3d(0,0,1,15deg);
    }
    26%, 38%, 50%, 62%, 74% {
      transform: rotate3d(0,0,1,0deg);
    }
    29%, 41%, 53%, 65%, 77% {
      transform: rotate3d(0,0,1,-15deg);
    }
    80% {
      transform:rotate3d(0, 0, 1, 0deg);
    }
  }

  @keyframes live-icon {
    0% {
      box-shadow: 0px 0px 0px 0px #8c9ba700;
    }
    80% {
      box-shadow: 0px 0px 0px 4px #0068b733;
    }
    100% {
      box-shadow: 0px 0px 0px 6px transparent;
    }
  }

  @keyframes live-in-call {
    0% {
      box-shadow: 0px 0px 0px 0px #8ca79600;
    }
    80% {
      box-shadow: 0px 0px 0px 4px #01963833;
    }
    100% {
      box-shadow: 0px 0px 0px 6px transparent;
    }
  }
}