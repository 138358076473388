div.register-form-component {

  .register-form-component-textBlack {
    text-align: left;
    font: normal normal bold calc(0.5em + 0.5vw) 'Montserrat';
    color: #1E1E1E;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer
  }

  .register-form-component-textFontBlue {
    text-align: left;
    font: normal normal bold calc(0.5em + 0.7vw) Montserrat;
    color: #0C2461;
  }

  .register-form-component-textHeaderMobile {
    font: var(--px17_24_Bold-font) !important;
  }

  .register-form-component-textFontGlayMobile {
    text-align: left;
    font: var(--px17_24-font);
    color: #707070;
  }

  .register-form-component-textFontGlay {
    text-align: left;
    font: var(--px12-font) !important;
    color: #707070;
  }

  .register-form-component-paddingLarger {
    padding-left: 4.2vw;
    padding-right: 4.2vw;
    padding-bottom: 1vh;
  }

  .register-form-component-textBlackMobile {
    text-align: left;
    font: var(--px14_22_Bold-Montserrat) /* !important */;
    color: #1E1E1E !important;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer
  }

  .register-form-component-textBlackBoldMobile {
    font: var(--px14_22_Bold-Montserrat);
    color: var(--black-color);
    text-decoration: 'none';
    background-color: 'transparent';
    border: 'none';
    cursor: 'pointer';
  }

  .register-form-component-textBlackBold {
    font: var(--px15_19_Bold-font);
    color: var(--black-color);
    text-decoration: 'none';
    background-color: 'transparent';
    border: 'none';
    cursor: 'pointer';
  }

  @media only screen and (min-width: 670px ) and (max-width: 1000px) {

    .dropdownButtonRegisterMobile {
      font: var(--px20_24-font) !important;
    }
    
    .form-control-Default {
      height: 5vh !important;
      border-radius: 0.3rem !important;
    }
    
    .form-control-Default:not(:placeholder-shown), .inputFilledMobile {
      font: var(--px20_24-font) !important;
      color: #1E1E1E;
    }
    
    .form-control-Default:placeholder-shown, .inputShowPlaceholderMobile {
      font: var(--px17_24-font) !important;
    }
    
    .buttonBlueRegisterMobile, .buttonWhiteRegisterMobile {
      font: var(--px16_24-font) !important;
    }
     
    .fontStepMobile {
      font: var(--px17_24_Bold-font) !important;
    }
  }

  .dropdownButtonRegisterMobile {
    background: var(--white-color) 0% 0% no-repeat padding-box !important;
    border: 1px solid #ced4da !important;
    font: var(--px10_13-font) /* !important */;
    color: rgb(112, 112, 112) !important;
    width: 100% !important;
    height: 5vh;
    text-align: left !important;
    border-radius: 0.3rem !important;
  }

  .dropdownButtonRegisterMobile::after {
    color: #6A6A6A !important;
    margin-left: 40% !important;
    position: relative;
    border-top: 0.6em solid !important;
    border-left: 0.4em solid transparent !important;
    border-right: 0.4em solid transparent !important;
    vertical-align: 0.0em !important;
  }

  .register-form-component-valid-property {
    color: #198754;
  }

  .register-form-component-invalid-property {
    color: #dc3545;
  }
}

@media only screen and (max-width: 1280px) {
  div.register-form-component{

    .register-form-component-registerDesktopOrangeLabel {
      font: normal normal bold min(max(20px, calc(0.2em + 5vw)), 28px) 'Montserrat';
    }
    
    .register-form-component-registerDesktopHeader {
      font: normal normal normal min(max(18px, calc(0.2em + 4vw)), 26px) 'Montserrat';
    }
    
    .register-form-component-boldTextHeader {
      font: normal normal bold min(max(20px, calc(0.2em + 5vw)), 26px) 'Montserrat';
    }
    
    .register-form-component-backgroundLogin {
      padding-top: 12vh ;
    }
  }
  
}