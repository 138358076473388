div.ticket-details-component {
  // background: #ffee8f;
  display: flex;
  flex-direction: column;
  // height: 100%;
  min-width: 28%;
  border-left: 1px solid #ececec;
  transition: 0.3s;
  position: relative;

  div.ticket-details-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #eef3ff;
    padding: 5px 5px 5px 8px;
    transition: 0.3s;
    height: 40px;
    box-shadow: 0px 3px 9px -5px rgba(0, 0, 0, 0.4);

    span.ticket-details-header-action {
      display: flex;
      background: #cfdcff;
      border-radius: 4px;
      cursor: pointer;
      padding: 3px;
      transition: 0.3s;

      svg {
        height: 22px;
        width: 22px;
        stroke-width: 1.5;
        color: #4a69bd;
        transition: 0.3s;
      }
    }

    span.ticket-details-header-action:hover {
      background: #bcccf7;
      transition: 0.3s;
    }

    span.ticket-details-header-action.details-header-action-active {
      background:#4a69bd;
      transition: 0.3s;

      svg {
        color: #fff;
        transition: 0.3s;
      }
    }

    span.ticket-details-header-action:not(:last-child) {
      margin-right: 5px;
    }
  }

  div.ticket-details-header.ticket-details-header-hide {
    height: 100%;
  }
}

div.ticket-details-component.ticket-details-component-hide {
  min-width: unset;
  transition: 0.3s;

  div.ticket-details-header {
    height: 100%;
    transition: 0.3s;
    padding: 0px;

    span.ticket-details-header-action.ticket-details-show-hide {
      height: 100%;
      align-items: center;
      border-radius: unset;
      background: #eef3ff;
    }
  }
}

@media only screen and (max-width: 1000px) {
  div.ticket-details-component {
    height: unset;

    div.ticket-details-section {
      div.ticket-details-section-edit {
        max-height: 212px;
        overflow: auto;
      }

      div.ticket-details-section-info {
        max-height: 212px;
        overflow: auto;
      }
    }
  }

  div.ticket-details-component.ticket-details-component-hide {
    height: 40px !important;

    span.ticket-details-show-hide {
      width: 100%;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 870px) {
  div.ticket-details-component {
    height: 30%;

    div.ticket-details-section {
      div.ticket-details-section-edit {
        max-height: 150px;
        overflow: auto;
      }

      div.ticket-details-section-info {
        max-height: 150px;
        overflow: auto;
      }
    }
  }

  div.ticket-details-component.ticket-details-component-hide {
    height: 40px !important;
  }
}