.modalEmailConfirmation {
  * {
    font-family: Montserrat;
  }
  .modal-header {
    span {
      text-align: left;
      font: normal normal bold calc(0.5em + 0.7vw) Montserrat;
      letter-spacing: 0px;
      color: #1E1E1E;
      opacity: 1;
    }
  }
}
