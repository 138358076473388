div.audio-player-component2 {
  display: grid;
  grid-template-rows: 1fr 1fr 2fr;
  height: 100%;
  width: 100%;
  user-select: none;
  min-width: 210px;

  div.audio-player-info {
    background-color: #f8f8f8;
    color: #4a69bd;
    padding: 5px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #ececec;

    span.audio-player-info-filename {
      text-overflow: ellipsis;
      text-wrap: nowrap;
      max-width: 170px;
      overflow: hidden;
    }
  }

  div.audio-player-content-controller {
    display: flex;
    width: 100%;
    font-size: 12px;
    align-items: center;
    gap: 4px;
    padding: 5px;
    padding-bottom: 0px;
    padding-top: 8px;

    span.audio-player-duration-total {
      svg {
        height: 18px;
        width: 18px;
        min-width: 18px;
        stroke-width: 1;
      }
    }
  }

  div.audio-player-content-controller.audio-player-error {
    opacity: 0.5;

    input.audio-player-input-range::-webkit-slider-thumb {
      cursor: default !important;
    }
  }

  div.audio-player-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;

    span.audio-player-button-main {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4a69bd;
      color: #fff;
      border-radius: 5px;
      padding: 4px;
      cursor: pointer;
      transition: 0.3s;

      svg {
        height: 20px;
        width: 20px;
        min-width: 20px;
      }
    }

    span.audio-player-button-volume,
    span.audio-player-button-download {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e4e4e4;
      color: #707070;
      border-radius: 5px;
      padding: 2px;
      cursor: pointer;
      transition: 0.3s;

      svg {
        height: 18px;
        width: 18px;
        min-width: 18px;
      }
    }

    span.audio-player-error {
      opacity: 0.5;
      cursor: default !important;
    }

    span.audio-player-button-main:not(.audio-player-error):hover {
      background-color: #304b93;
    }
  }

  div.audio-player-actions.audio-player-volume-control {
    // justify-content: flex-start;
    
    div.audio-player-volume-control-range {
      display: flex;
      align-items: center;

      input.audio-player-input-range,
      input.audio-player-input-range::-webkit-slider-runnable-track,
      input.audio-player-input-range::-moz-range-track,
      input.audio-player-input-range::-webkit-slider-thumb,
      input.audio-player-input-range::-moz-range-thumb {
        height: 10px;
      }

      input.audio-player-input-range::-webkit-slider-thumb,
      input.audio-player-input-range::-moz-range-thumb {
        width: 10px;
      }

      input.audio-player-input-range::-webkit-slider-thumb {
        border: 1px solid #707070 !important;
        box-shadow: -407px 0 0 400px #707070 !important;
      }

      input.audio-player-input-range::-moz-range-thumb {
        border: 1px solid #707070 !important;
        box-shadow: -407px 0 0 400px #707070 !important;
      }

      span.audio-player-volume-current {
        font-size: 12px;
        margin-left: 2px;
        width: 26px;
      }

    }
  }

  // Estilos do slider
  input.audio-player-input-range {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none; 
    /* creating a custom design */
    width: 100%;
    cursor: default;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 16px;
    height: 12px;
  }

  /* Track: webkit browsers */
  input.audio-player-input-range::-webkit-slider-runnable-track {
    height: 12px;
    background: #ccc;
    border-radius: 16px;
    cursor: default;
  }

  /* Track: Mozilla Firefox */
  input.audio-player-input-range::-moz-range-track {
    height: 12px;
    background: #ccc;
    border-radius: 16px;
    cursor: default;
  }

  /* Thumb: webkit */
  input.audio-player-input-range::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none; 
    /* creating a custom design */
    height: 12px;
    width: 12px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #4a69bd;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #4a69bd;
    cursor: pointer;
  }

  /* Thumb: Firefox */
  input.audio-player-input-range::-moz-range-thumb {
    height: 12px;
    width: 12px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #4a69bd;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #4a69bd;
    cursor: pointer;
  }

  span.audio-player-error-load-message {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 12px;
    color: #f36a6b;
    padding-bottom: 2px;
  }
}
