div.select-paginated-component {
  display: flex;
  flex-direction: column;
  flex: 1;

  div.select-paginated-main.select-paginated-disabled {
    background: #ececec !important;
  }

  div.select-paginated-main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 40px;
    width: 100%;
    border-radius: 5px;
    padding: 5px 4px 5px 8px;
    z-index: 0;
    border: 1px solid #ced4da;
    background-color: #fff;

    div.select-paginated-content-selected {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      cursor: default;

      span.select-paginated-placeholder {
        color: #919191;
        user-select: none;
      }

      span.select-paginated-content-item {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      span.select-paginated-content-selected-badge {
        background: #d5d5d5;
        padding: 3px 6px;
        border-radius: 5px;
        font-size: 14px;
      }

      span.select-paginated-content-selected-badge:not(:last-child) {
        margin-right: 3px;
      }
    }
  
    div.select-paginated-icon-action {
      display: flex;
      align-items: center;
      padding-left: 5px;

      svg {
        color: #4a69bd;
        transition: 0.3s;
        cursor: pointer;
      }
  
      svg.icon-opened {
        transform: scaleY(-1);
      }

      svg.select-action-clear {
        width: 16px;
        height: 16px;
        color: #de2450;
      }
    }
  }

  div.select-paginated-main.select-paginated-main-loading {
    position: relative;
    overflow: hidden;

    div.select-paginated-icon-action {
      z-index: 10;
    }
  }

  div.select-paginated-main-invalid {
    border: 1px solid #f36a6b;

    div.select-paginated-content-selected {
      span.select-paginated-placeholder {
        color: #f36a6b;
      }
    }

    div.select-paginated-icon-action {
      svg.select-paginated-icon-chevron-down {
        color: #f36a6b;
      }
    }
  }

  div.select-paginated-container {
    position: relative;
  }
}

div.select-paginated-items-popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  background: #fff;
  border: 1px solid #ececec;
  width: 100%;
  margin-top: 5px;
  border-radius: 5px;
  // padding: 5px 8px;
  overflow: hidden;
  z-index: 5;
  // box-shadow: -1px 0px 11px -7px rgba(0, 0, 0, 0.7);
  // box-shadow: -1px 0px 9px 0px rgb(0 0 0 / 30%);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.04) 0px 6px 10px, rgba(0, 0, 0, 0) 0px 1px 11px;
  user-select: none;

  div.select-paginated-popup-selected-tags {
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    gap: 3px;
    max-height: 65px;
    overflow: auto;
    box-shadow: 0px 2px 11px -7px rgba(0, 0, 0, 0.7);
    scrollbar-width: thin;

    div.selected-paginated-popup-selected-item {
      display: flex;
      align-items: center;
      background-color: #cfdcff;
      padding: 0px 4px 0px 6px;
      border-radius: 5px;
      overflow: hidden;
      text-overflow: ellipsis;

      span.selected-paginated-popup-selected-item-value {
        font-size: 14px;
        color: #4a69bd;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg.select-paginated-popup-selected-item-clear {
        width: 13px;
        height: 13px;
        color: #4a69bd;
        margin-left: 3px;
        cursor: pointer;
        transition: 0.3s;
        stroke-width: 3;
      }

      svg.select-paginated-popup-selected-item-clear:hover {
        color: #082598;
      }
    }
  }

  div.select-paginated-popup-filter {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececec;
    position: relative;

    input {
      border: none;
      padding: 10px 40px 10px 10px;
      height: 40px;
      width: 100%;
      font-size: 15px;
      color: #919191;
      box-shadow: 0px 2px 11px -7px rgba(0, 0, 0, 0.7) !important;
    }

    div.select-paginated-popup-filter-actions {
      position: absolute;
      display: flex;
      align-items: center;
      right: 10px;
      background-color: #fff;

      svg:not(:last-child) {
        margin-right: 4px;
      }

      svg {
        // position: absolute;
        // right: 10px;
        color: #707070;
        cursor: pointer;
        width: 20px;
        height: 20px;
      }

      svg.select-paginated-popup-filter-clear {
        width: 16px;
        height: 16px;
      }
    }

  }

  div.select-paginated-popup-list {
    max-height: 175px;
    height: 100%;
    overflow: auto;
    position: relative;

    div.select-paginated-option {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: default;
      transition: 0.3s;
      padding: 5px 10px;
      text-wrap: nowrap;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      svg {
        min-width: 20px;
        min-height: 20px;
        stroke-width: 2;
      }
    }

    div.select-paginated-option:not(:last-child) {
      border-bottom: 1px solid #ececec;
    }
    div.select-paginated-option.select-paginated-option-selected:not(:last-child) {
      border-bottom: 1px solid #ffffff;
    }

    div.select-paginated-option.select-paginated-option-selected {
      background-color: #f0f5ff;
    }

    div.select-paginated-option:not(.select-paginated-option-selected):hover {
      background-color: #f7f7f7;
      cursor: pointer;
      transition: 0.3s;
    }

    div.select-paginated-option.selected-paginated-option-system {
      span {
        background-color: #e3eafa;
        color: #4a69bd;
        padding: 0px 6px;
        border-radius: 5px;
      }
    }
  }

  span.select-paginated-popup-empty-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 15px;
    color: #919191;
  }
}