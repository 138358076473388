.validateControlPanel {

  .body-content {
    background-color: #E5ECFF;
    height: 90.5vh;

    .box-content {

      background-color: white; 
      border-radius: 10px; 
      padding: 10px 20px;
      padding-bottom: 20px;

      div.validateLogo {
        width: fit-content; 
        padding: 0; 
        margin-left: -2vh;

        img {
          width: 55%;
        }
      }

      div.not-confirmed {
        p.description {
          margin-bottom: 0;
        }
      }

      span.title {
        display: flex;
        justify-content: center;
        text-align: center;
        font: normal normal bold 17px/20px Montserrat;
        letter-spacing: 0px;
        color: #0C2461;
      }
    
      p.description {
        color: #707070;
        font: Montserrat;
      }

      button:hover {
        background-color: #c1c1c1 !important;
        transition: 0.3s;
      }
    }
  }

  .footer-content {
    background-color: #FFFFFF;
    height: 9.5vh;

    p.footer-text {
      span {
        text-align: left;
        font: normal normal normal 15px/19px Montserrat;
        letter-spacing: 0px;
        color: #707070;
      }

      span:first-child {
        font-weight: bold;
      }
    }
  }
}
