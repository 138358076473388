div.modal-delete-task-component {
  div.modal-content {
    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      button.btn-close {
        margin-top: -15px;
        transition: 0.3s;
      }

      div.modal-delete-task-header-actions {
        display: flex;
        gap: 6px;

        svg {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }

        svg:hover {
          color: #606060;
        }
      }
    }

    div.modal-body {
      user-select: none;
      
      span.modal-delete-task-message {
        font-size: 18px;

        span {
          font-weight: 600;
        }
      }

      div.modal-delete-task-info {
        display: flex;
        flex-direction: column;

        span.modal-delete-task-info-message {
          font-weight: 500;
          margin-bottom: 10px;
        }

        div.modal-delete-task-info-fields {
          display: flex;
          flex-direction: column;
          gap: 5px;
          overflow: auto;
          scrollbar-width: thin;

          div.modal-delete-task-info-fields-row {
            display: flex;
            gap: 10px;

            div.modal-delete-task-info-fields-item {
              text-overflow: ellipsis;
              text-wrap: nowrap;
              max-width: calc(100% / 2);
              overflow: hidden;

              span.modal-delete-task-info-fields-item-value {
                text-overflow: ellipsis;
                text-wrap: nowrap;
                overflow: hidden;
              }
            }
          }

          div.modal-delete-task-info-fields-item {
            display: flex;
            flex-direction: column;
            width: 100%;

            span.modal-delete-task-info-fields-item-label {
              font-size: 15px;
              font-weight: 500;
              color: #707070;
            }

            span.modal-delete-task-info-fields-item-value {
              font-size: 15px;
              background-color: #ececec;
              padding: 10px 10px;
              border-radius: 5px;
            }

            span.modal-delete-task-info-fields-item-value.info-fields-item-overflow {
              max-height: 90px;
              overflow: auto;
              scrollbar-width: thin;
            }
          }
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 1;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-delete-task-btn-save {
        background-color: #d12d2d;
        color: #fff;
        border: none;
      }

      button.modal-delete-task-btn-save:not(:disabled):hover {
        background-color: #b21f1f;
      }

      button.modal-delete-task-btn-save:disabled {
        opacity: 0.4;
        cursor: default;
      }

      button.modal-delete-task-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-delete-task-btn-cancel:hover {
        background-color: #e8e8e8;
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  div.modal-delete-task-component {
    div.modal-content {
      div.modal-body {
        div.modal-delete-task-info {
          div.modal-delete-task-info-fields {
            div.modal-delete-task-info-fields-row {
              flex-direction: column;

              div.modal-delete-task-info-fields-item {
                max-width: unset;
              }
            }
          }
        }
      }
    }
  }
}