.buttonWhiteBroker {
    border-radius: 4px !important;
    background: var(--white-color) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--main-color) !important;
    opacity: 1 !important;
    width: 100%;
    text-decoration: none;
    height: min(max(35px, 5.0vh), 70px) !important;
    color: var(--main-color) !important;
    font: var(--px15_19-font) !important;
}