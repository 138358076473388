div.modal-export-all-component {
  div.modal-content {

    div.modal-header {
      user-select: none;
      z-index: 11;
      box-shadow: 0px 0px 12px -8px;

      div.modal-export-all-header-actions {
        display: flex;
        align-items: center;
        gap: 6px;

        svg.modal-export-all-header-actions-close {
          cursor: pointer;
          color: #707070;
        }
      }
    }

    div.modal-body {
      min-height: 80px;

      div.modal-export-all-section.section-margin-top {
        margin-top: 20px;
      }

      div.modal-export-all-section {
        display: flex;
        flex-direction: column;
        gap: 5px;

        div.modal-export-all-items-group {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          div.modal-export-all-item {
            max-width: calc(50% - 5px);
          }
        }

        div.modal-export-all-item {
          width: 100%;
          position: relative;

          div.modal-export-all-item-header {
            user-select: none;
            display: flex;
            align-items: center;

            span.modal-export-all-item-label {
              font-size: 15px;
              font-weight: 600;
              color: #717171;
            }

            span.modal-export-all-item-label-required {
              color: #f36a6b;
              font-weight: 500;
            }

            svg.modal-export-all-item-action {
              color: #4a69bd;
              cursor: pointer;
              width: 20px;
              height: 20px;
              margin-left: 5px;
            }

            svg.modal-export-all-item-info {
              color: #4a69bd;
              width: 20px;
              height: 20px;
              margin-left: 3px;
            }
          }

          span.modal-export-all-item-invalid-message {
            position: absolute;
            font-size: 10px;
            color: #f36a6b;
            right: 0;
            font-weight: 600;
            cursor: default;
          }
        }

        span.modal-export-all-section-title {
          display: flex;
          align-items: center;
          font-weight: 600;
          user-select: none;
          margin-bottom: 5px;
          width: fit-content;

          svg {
            margin-left: 5px;
            transition: 0.3s;
          }
        }

        div.modal-export-all-section-check {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: #f1f1f1;
          border-radius: 5px;
          padding: 5px 10px;

          div.modal-export-all-section-check-info {
            display: flex;
            flex-direction: column;
            cursor: default;
            user-select: none;

            span.modal-export-all-section-check-title {
              font-weight: 600;
            }
            span.modal-export-all-section-check-message {
              font-size: 14px;
              color: #707070;
            }
          }

          div.modal-export-all-section-check-switch {
            display: flex;
            align-items: center;
            min-width: 50px;
            justify-content: flex-end;
          }
        }

        div.modal-export-all-links-container {
          display: flex;
          flex-direction: column;
          gap: 15px;
          
          span.modal-export-all-links-message-info {
            font-weight: 500;
            cursor: default;
          }

          div.modal-export-all-links-list {
            display: flex;
            flex-direction: column;
            gap: 5px;
            background-color: #f3f3f3;
            border-radius: 5px;
            padding: 8px;
            max-height: 230px;
            overflow-y: auto;
            user-select: none;

            div.modal-export-all-links-item {
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: space-between;
              background-color: #ececec;
              transition: 0.3s;
              padding: 6px;
              border-radius: 5px;
              cursor: default;

              span.modal-export-all-links-item-name {
                overflow: hidden;
                text-overflow: ellipsis;
              }

              button.modal-export-all-links-item-download {
                display: flex;
                align-items: center;
                gap: 2px;
                border: none;
                outline: none;
                border-radius: 5px;
                font-size: 15px;
                padding: 2px 4px;
                padding-right: 8px;
                background-color: #fff;
                transition: 0.3s;
                
                svg {
                  color: #4a69bd;
                  width: 20px;
                  height: 20px;
                  transition: 0.3s;
                }
              }

              button.modal-export-all-links-item-download:hover {
                background-color: #4a69bd;
                color: #fff;

                svg {
                  color: #fff;
                }
              }
            }

            div.modal-export-all-links-item:hover {
              background-color: #d8d8d8;
            }
          }
        }
      }
    }

    div.modal-body.modal-body-loading {
      display: flex;
      align-items: center;
      justify-content: center;

      div.modal-body-loading-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          color: #4a69bd;
          stroke: #4a69bd;
          stroke-width: 1;
          height: 80px;
          width: 80px;
        }
      
        span.modal-body-loading-info-message {
          color: #4a69bd;
          font-family: Montserrat;
          text-align: center;
          cursor: default;
        }
      }

      div.modal-body-loading-info.modal-body-loading-error {
        svg {
          color: #f36a6b;
          stroke: #f36a6b;
        }

        span.modal-body-loading-info-message {
          color: #f36a6b;
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 11;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-export-all-btn-save {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-export-all-btn-save:not(:disabled):hover {
        background-color: #334f9d;
      }

      button.modal-export-all-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-export-all-btn-cancel:hover {
        background-color: #ebf1ff;
      }

      button:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }

    div.modal-footer.modal-footer-only-close {
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: 575px) {
  div.modal-export-all-component {
    max-width: unset !important;

    div.modal-content {
      div.modal-body {
        max-height: unset;

        div.modal-export-all-section {
          div.modal-export-all-links-container {
            div.modal-export-all-links-list {
              max-height: unset;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  div.modal-export-all-component {

    div.modal-content {
      div.modal-body {
        div.modal-export-all-section {
          div.modal-export-all-items-group {
            flex-direction: column;
            gap: 5px;

            div.modal-export-all-item {
              max-width: unset;
            }
          }
        }
      }
    }
  }
}