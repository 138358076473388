.viewSummaryPage {

    .cardInsights {
        border: solid 1px #dddddd;
        margin: 0 15px;
        padding: 13px;
        width: 10%;
        border-radius: 6px;
        background-color: white;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 6px 3px -5px;
    }

    .content {
        margin-top: 2vh;
        height: 20vh;

         p {
            color: #4A69BD;
            font: normal normal bold min(max(10px, calc(0.2em + 1vw)), 20px) 'Montserrat';
         }
    }

    .insights {
        margin-top: 2vh;
        height: 20vh;

        p {
            max-height: 11vh;
            overflow: auto;
            color: #4A69BD;
            font: normal normal bold min(max(10px, calc(0.2em + 1vw)), 20px) 'Montserrat';
        }
    }

    .info{
        margin-bottom: 1.2vh;
    }
    
    .fontDarkBlueTitleCard {
        color: #0C2461;
        font: normal 600 normal min(max(10px, calc(0.1em + 0.9vw)), 20px) 'Montserrat'; 
    }

    .fontDarkTitle {
        color: #0C2461;
        font: normal normal bold calc(0.6em + 0.6vw) 'Montserrat';
        margin-bottom: 2vh;
    }

    .fontInfoBlue {
        color: #4A69BD;
        font: normal normal bold min(max(10px, calc(0.4em + 1vw)), 20px) 'Montserrat';
    }

    .tabler-icon {
        color: #4a69bd; 
    }

    hr {
        border: #a3a3a3 solid 1px;
    }
    p {
        margin: 0;
    }

    .divContent {
        background-color:rgb(232, 240, 252) ;
        margin: 0 3vh 10px;
        border-radius: 5px;
        padding: 25px;
    }

    .listTags {
        height: 5vh;
        flex-wrap: wrap;
        //max-height: 15vh;
        overflow: auto;
        overflow-x: hidden;
        
        p {
            color: white;
            font: normal normal bold 13px 'Montserrat'; 
            padding: 5px;
            background: #4a69bd;
            border-radius: 5px;
            margin-right: 10px;
            margin-top: 0.8vh;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 190px;
        }
    }

    .content-text {
        max-height: 16vh;
        overflow: auto;
    }

    @media screen and (max-width: 1280px) {
        .divContent {
          padding: 18px;
        }

        .content {
            height: 28vh;
             p {
                font: normal 580 normal min(max(10px, calc(0.2em + 1vw)), 13px) 'Montserrat';
             }
        }

        .content-text {
            max-height: 24vh;
            overflow: auto;
        }
    
        .insights {
            height: auto;
            p {
                max-height: none;
                font: normal 580 normal min(max(10px, calc(0.2em + 1vw)), 13px) 'Montserrat';
            }
        }

        .fontDarkTitle {
            font: normal normal bold calc(0.4em + 0.6vw) 'Montserrat';
        }
        
        .fontInfoBlue {
            font: normal normal bold min(max(10px, calc(0.4em + 1vw)), 13px) 'Montserrat';
        }

        .icon {
            height: 20px;
            width: 20px;
        }
    }

    @media screen and (min-width: 1290px) and (max-width: 1720px) {
        .content {
             p {
                font: normal 580 normal min(max(10px, calc(0.2em + 1vw)), 12.8px) 'Montserrat';
             }
        }

        .insights {
            p {
                max-height: none;
                font: normal 580 normal min(max(10px, calc(0.2em + 1vw)), 12.8px) 'Montserrat';
            }
        }
    }
}

