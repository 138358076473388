.integration-params {
    /* display: flex; */
    /* flex-direction: column; */
    justify-content: center;
    text-align: center;
    /* border: 1px black solid; */
    /* width: min-content; */
    width: 100%;
    height: 20vh;
    /* margin: auto; */
    overflow-y: auto;
    overflow-x: hidden;
}

.integration-param {
    padding: 0.3rem;
    /* white-space: nowrap; */
    cursor: pointer;
    /* min-height: 2rem !important; */
    height: 2.0rem;
    /* background-color: white; */
}

.floating-param {
    position: fixed;
    z-index: 10;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}

.integration-param.drop-zone-param {
    background-color: #f0f8ff;
    transition-property: height padding;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    overflow: hidden;
}
  
.integration-param.drop-zone-param.hidden {
    height: 0px !important;
    padding: 0px;
    visibility: hidden;
}