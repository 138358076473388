div.notifications-component {
  display: flex;

  span.notifications-component-trigger {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;

    svg {
      height: 35px;
      width: 35px;
      padding: 5px;
      stroke-width: 1.5;
    }

    svg.notifications-component-bell {
      color: #707070;
    }

    svg.notifications-component-has-new {
      color: #4a69bd;
    }
  }

  span.notifications-component-trigger:hover,
  span.notifications-component-trigger.notifications-component-popup-opened {
    background-color: #dddddd;
    transition: 0.3s;
  }

  div.notifications-component-popup {
    position: absolute;
    right: 0;
    top: 45px;
    opacity: 1;
    background: #fff;
    box-shadow: 0px 1px 8px -2px rgb(0, 0, 0, 0.44);
    border-radius: 5px;
    padding: 10px;
    min-width: 380px;

    display: flex;
    flex-direction: column;
    z-index: 10;

    div.notifications-component-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      user-select: none;

      span.notifications-component-header-title {
        color: #0c2461;
        font-weight: 600;
        font-family: Montserrat, system-ui;
        font-size: 18px;
      }

      div.notifications-component-header-actions {
        display: flex;
        align-items: center;

        svg:not(:last-child) {
          margin-right: 4px;
        }

        svg {
          color: #707070;
          background-color: #f5f5f5;
          padding: 4px;
          width: 30px;
          height: 30px;
          border-radius: 5px;
          transition: 0.3s;
          cursor: pointer;
        }

        svg:hover,
        svg.notifications-component-header-action-active {
          background-color: #dcdcdc;
        }
      }
    }

    div.notifications-component-container {
      display: flex;
      flex-direction: column;

      div.notifications-component-container-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div.notifications-component-search {
          display: flex;
          align-items: center;
          position: relative;

          input {
            padding: 5px 25px 5px 35px;
            border-radius: 5px;
            outline: none;
            border: none;
            background-color: #f9f9f9;
            max-width: 230px;
            color: #707070;
          }

          svg:not(.notifications-component-search-clear) {
            position: absolute;
            left: 5px;
            color: #707070;
            width: 20px;
            height: 20px;
          }

          svg.notifications-component-search-clear {
            position: absolute;
            right: 6px;
            color: #f36a6b;
            width: 15px;
            height: 15px;
            cursor: pointer;
            transition: 0.3s;
          }

          svg.notifications-component-search-clear:hover {
            color: #d24646;
          }
        }

        span.notifications-component-container-action {
          font-size: 13px;
          color: #4A69BD;
          cursor: pointer;
          transition: 0.3s;
          user-select: none;
        }

        span.notifications-component-container-action.notifications-component-list-mark-all-active {
          color: #fff;
          background-color: #4A69BD;
          padding: 0px 4px;
          border-radius: 5px;
        }

        span.notifications-component-container-action:not(.notifications-component-list-mark-all-active):hover {
          color: #3958ab;
        }
      }

      div.notifications-component-container-list {
        position: relative;
        margin-top: 10px;
        font-family: Roboto;

        div.notifications-component-list-items-empty {
          justify-content: center;

          div.notifications-component-list-empty-alert {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #4a69bd;
            font-family: Montserrat;

            svg {
              height: 50px;
              width: 50px;
              stroke-width: 1.5;
            }
          }
        }

        div.notifications-component-container-list-items {
          min-height: 200px;
          max-height: 200px;
          overflow: auto;
          display: flex;
          flex-direction: column;

          div.notifications-component-list-item:not(:last-child) {
            margin-bottom: 10px;
          }
  
          div.notifications-component-list-item.notifications-component-list-item-not-read {
            border-left: 4px solid #4A69BD;
          }
  
          div.notifications-component-list-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 8px;
            padding-bottom: 4px;
            padding-top: 4px;
            border-radius: 5px;
            transition: 0.3s;
            margin-right: 5px;
            font-size: 15px;
            border-left: 4px solid #d9d9d9;
            cursor: default;
            border-bottom: 1px solid #ececec;
            position: relative;
  
            div.notifications-component-list-item-info {
              display: flex;
              flex-direction: column;
  
              span.notifications-component-list-item-author {
                font-weight: 600;
              }
  
              div.notifications-component-list-item-message {
  
                span.notifications-component-list-item-message-ticket {
                  font-weight: 600;
                  color: #4a69bd;
                  background-color: #e5ecff;
                  padding: 1px 4px;
                  border-radius: 4px;
                  cursor: pointer;
                  transition: 0.3s;
                }
  
                span.notifications-component-list-item-message-ticket:hover {
                  background-color: #c3d1f9;
                }
              }
  
              span.notifications-component-list-item-date {
                color: #707070;
                font-size: 11px;
              }
  
  
            }
  
            div.notifications-component-list-item-actions {
              display: flex;
              flex-direction: column;
              margin-right: 5px;
              margin-left: 5px;
              transition: 0.3s;
              width: 26px;
              user-select: none;
  
              svg {
                opacity: 0;
                cursor: pointer;
                color: #909090;
                transition: 0.2s;
              }
  
              svg:not(:last-child) {
                margin-bottom: 5px;
              }
  
              svg:hover {
                color: #4A69BD;
              }

            }

            div.notifications-component-item-delete-confirmation {
              position: absolute;
              width: calc(100% + 1px);
              height: 100%;
              left: -1px;
              background-color: #373737ab;
              backdrop-filter: blur(2px);
              border-radius: 5px;
              padding: 10px;
              color: #fff;
            }
          }

          div.notifications-component-list-item.notifications-component-item-show-delete {
            background-color: #ffefef;
            border-left: 4px solid #d74242;
            border-bottom: 1px solid #d74242;
            transition: 0.3s;

            div.notifications-component-list-item-info {
              padding: 5px;
            }

            div.notifications-component-list-item-delete-btn {
              display: flex;
              gap: 5px;
              margin-top: 4px;

              span {
                border-radius: 5px;
                cursor: pointer;
                transition: 0.3s;
                font-size: 14px;
                padding: 0px 6px;
              }

              span.notifications-component-list-item-delete-cancel {
                background-color: #909090;
                color: #fff;
              }

              span.notifications-component-list-item-delete-cancel:hover {
                background-color: #808080;
              }

              span.notifications-component-list-item-delete-confirm {
                background-color: #d24646;
                color: #fff;
              }

              span.notifications-component-list-item-delete-confirm:hover {
                background-color: #be3636;
              }
            }
          }
  
          div.notifications-component-list-item:not(.notifications-component-item-show-delete):hover {
            background-color: #f9f9f9;
  
            div.notifications-component-list-item-actions {
              svg {
                opacity: 1;
                display: block;
              }
            }
          }
        }

        div.notifications-component-mark-all {
          display: flex;
          flex-direction: column;
          position: absolute;
          background-color: #fff;
          top: 0;
          height: 100%;
          width: 100%;
          padding: 15px;
          justify-content: space-between;
          cursor: default;

          div.notifications-component-mark-all-info {
            display: flex;
            flex-direction: column;

            span.notifications-component-mark-all-title {
              font-weight: 600;
            }
  
            span.notifications-component-mark-all-message {
              margin-top: 20px;
            }
          }

          div.notifications-component-mark-all-actions {
            display: flex;
            gap: 5px;
            margin-top: 10px;

            span {
              border-radius: 5px;
              cursor: pointer;
              transition: 0.3s;
              font-size: 14px;
              padding: 2px 8px;
            }

            span.notifications-component-mark-all-btn-cancel {
              background-color: #909090;
              color: #fff;
            }

            span.notifications-component-mark-all-btn-cancel:hover {
              background-color: #808080;
            }

            span.notifications-component-mark-all-btn-confirm {
              background-color: #4a69bd;
              color: #fff;
            }

            span.notifications-component-mark-all-btn-confirm:hover {
              background-color: #3856a8;
            }
          }
        }
      }
    }

    div.notifications-component-container.notifications-component-list {
      animation: show-list 0.2s ease backwards;
    }

    div.notifications-component-container.notifications-component-configurations {
      display: flex;
      flex-direction: column;
      gap: 10px;
      user-select: none;
      animation: show-config 0.2s ease backwards;

      div.notifications-component-configurations-sections {
        display: flex;
        flex-direction: column;

        div.notifications-component-configurations-section:not(:last-child) {
          // padding-bottom: 15px;
          margin-bottom: 10px;
          // border-bottom: 1px solid #ececec;
        }
        
        div.notifications-component-configurations-section {
          display: flex;
          flex-direction: column;
          justify-content: center;

          border: 1px solid #ececec;
          border-radius: 5px;
          padding: 2px 8px;
          padding-bottom: 10px;
          background-color: #fff;
          box-shadow: 0px 1px 8px -6px #000;

          div.notifications-component-configurations-section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span.notifications-component-configurations-section-header-title {
              font-size: 16px;
              font-weight: 600;
              display: flex;
              align-items: center;
  
              svg {
                width: 20px;
                height: 20px;
                color: #4A69BD;
                margin-left: 3px;
              }
            }

            span.notifications-component-configurations-section-header-action {
              svg {
                height: 30px;
                width: 30px;
                color: #4A69BD;
                cursor: pointer;
              }

              svg.configuration-header-action-inactive {
                color: #707070;
              }

              svg.configuration-header-action-active {
                color: #4A69BD;
              }
            }
          }
  
          span.notifications-component-configurations-section-subtitle {
            font-size: 13px;
            color: #707070;
            margin-bottom: 15px;
          }

          span.notifications-component-configurations-section-subtitle.section-subtitle-no-margin {
            margin-bottom: 0;
          }
  
          div.notifications-component-configurations-section-range-form {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 5px;
            margin-top: 5px;
  
            span.notifications-component-configurations-range-limit {
              font-size: 14px;
              text-wrap: nowrap;
            }
  
            div.notifications-component-configurations-range-input {
              width: 100%;
              position: relative;
              height: 21px;
  
              span.notifications-component-range-input-value-display {
                position: absolute;
                top: -20px; /* Altere conforme necessário */
                left: calc(50%); /* Para centralizar */
                width: 20px;
                height: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #4a69bd;
                border-radius: 2px;
                color: #fff;
                font-weight: bold;
                font-size: 13px;
                user-select: none;
                padding: 10px 0px;
              }
  
              // Estilos do slider
              input.expire-days-input-range {
                /* removing default appearance */
                -webkit-appearance: none;
                appearance: none; 
                /* creating a custom design */
                width: 100%;
                cursor: default;
                outline: none;
                /*  slider progress trick  */
                overflow: hidden;
                border-radius: 16px;
                height: 15px;
              }
      
              /* Track: webkit browsers */
              input.expire-days-input-range::-webkit-slider-runnable-track {
                height: 15px;
                background: #ccc;
                border-radius: 16px;
                cursor: default;
              }
      
              /* Track: Mozilla Firefox */
              input.expire-days-input-range::-moz-range-track {
                height: 15px;
                background: #ccc;
                border-radius: 16px;
                cursor: default;
              }
      
              /* Thumb: webkit */
              input.expire-days-input-range::-webkit-slider-thumb {
                /* removing default appearance */
                -webkit-appearance: none;
                appearance: none; 
                /* creating a custom design */
                height: 15px;
                width: 15px;
                background-color: #fff;
                border-radius: 50%;
                border: 2px solid #4a69bd;
                /*  slider progress trick  */
                box-shadow: -407px 0 0 400px #4a69bd;
                cursor: pointer;
              }
      
              /* Thumb: Firefox */
              input.expire-days-input-range::-moz-range-thumb {
                height: 15px;
                width: 15px;
                background-color: #fff;
                border-radius: 50%;
                border: 1px solid #4a69bd;
                /*  slider progress trick  */
                box-shadow: -407px 0 0 400px #4a69bd;
                cursor: pointer;
              }
            }
          }

          div.notifications-component-configurations-section-form {
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: 0.3s;

            span.notifications-component-section-form-item {
              display: flex;
              align-items: center;
              gap: 4px;
              font-size: 14px;
              cursor: pointer;
              user-select: none;
              
              svg {
                color: #4a69bd;
                cursor: pointer;
              }
            }

            span.notifications-component-configurations-section-button {
              background-color: #808080;
              color: #fff;
              font-size: 15px;
              padding: 4px 8px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              gap: 5px;
              cursor: pointer;
              transition: 0.3s;

              svg {
                width: 19px;
                height: 19px;
                stroke-width: 1.5;
              }
            }

            span.notifications-component-configurations-section-button:hover {
              background-color: #707070;
            }
          }
        }

        div.notifications-component-configurations-section.notifications-component-configurations-section-inactive {
          span.notifications-component-configurations-section-subtitle {
            margin-bottom: 0;
          }

          div.notifications-component-configurations-section-form {
            display: none;
          }
        }
      }

      div.notifications-component-configurations-alert {
        display: flex;
        flex-direction: column;
        background-color: #fad8d8;
        border-radius: 5px;
        padding: 10px;
        color: #d12d2d;

        span.notifications-component-configurations-alert-message-title {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          font-weight: 600;
        }

        span.notifications-component-configurations-alert-message-subtitle {
          font-size: 12px;
          font-weight: 400;
        }
      }

      div.notifications-component-configurations-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span.notifications-component-configurations-action-save {
          background-color: #4a69bd;
          color: #fff;
          outline: none;
          border: none;
          padding: 5px 10px;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        span.notifications-component-configurations-action-save:hover {
          background-color: #3a58a9;
        }
      }
    }

    @keyframes show-list {
      from {
        opacity: 0;
        transform: translateX(-10px);
      }
    
      to {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    @keyframes show-config {
      from {
        opacity: 0;
        transform: translateX(10px);
      }
    
      to {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }
}
