div.expired-token-open-ai-bar-component {
  background-color: #e5ecff;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  span.expired-token-open-ai-bar-message {
    color: #d24646;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    padding: 5px 10px;

    span {
      color: #d24646;
      font-weight: bold;
      margin-left: 5px;
    }
  }
}

div.expired-token-open-ai-bar-component:hover {
  background-color: #cdd9fa;
}