.editor {
  border: none;
  cursor: text;
  overflow-y: auto;
  text-align: left;
  font: var(--px15_19-font-Roboto);
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  /* max-height: 9vh; */
  min-height: 6vh;
  width: 100%;
  word-break: break-all; 
  display: 'block';
  padding-left: 12px !important;
  resize: none;
}

.buttonBlueSendMessage {
  border-radius: 4px!important;
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: min(max(30.4px, 3.8vh), 41px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}