.bellContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.notification-bell {
    cursor: pointer;
    color: #0a53be !important;
}

/* @-webkit-keyframes ringing {
  0%, 100% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(10deg);
  }
  40%, 80% {
    transform: rotate(-6deg);
  }
  60% {
    transform: rotate(6deg);
  }
}

@keyframes ringing {
  0%, 100% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(10deg);
  }
  40%, 80% {
    transform: rotate(-6deg);
  }
  60% {
    transform: rotate(6deg);
  }
}
@-webkit-keyframes clapp {
  0%, 100% {
    transform: translate(6px, 18px) rotate(0);
  }
  20% {
    transform: translate(10px, 18px) rotate(-10deg);
  }
  40% {
    transform: translate(2px, 18px) rotate(10deg);
  }
  60% {
    transform: translate(9px, 18px) rotate(-8deg);
  }
  80% {
    transform: translate(3px, 18px) rotate(8deg);
  }
}
@keyframes clapp {
  0%, 100% {
    transform: translate(6px, 18px) rotate(0);
  }
  20% {
    transform: translate(10px, 18px) rotate(-10deg);
  }
  40% {
    transform: translate(2px, 18px) rotate(10deg);
  }
  60% {
    transform: translate(9px, 18px) rotate(-8deg);
  }
  80% {
    transform: translate(3px, 18px) rotate(8deg);
  }
}
.notification-bell {
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  cursor: pointer;
  transform-origin: 50% 0%;
  position: relative;
  transition: 200ms ease-in-out color;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.bellContainer .notification-bell {
  color: rgba(0, 0, 0, 0.38);
}

.notification-bell:after {
  background-color: #00bf92;
  border: 2px solid;
  border-radius: 11px;
  content: attr(data-count);
  color: #fff;
  display: block;
  font-size: 10px;
  font-family: helvetica;
  height: 9px;
  line-height: 8px;
  min-width: 9px;
  padding: 4px;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 3px;
  transition: 100ms ease-in-out all;
}
.bellContainer .notification-bell:after {
  border-color: #fafafa;
}
.notification-bell[data-count="0"]:after {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
.notification-bell.is-muted:after {
  content: "";
  color: transparent;
  height: 2px;
  min-width: 0;
  padding: 2px;
  right: 4px;
  top: 4px;
  width: 2px;
}
.bellContainer .notification-bell:not([data-count="0"]) {
  color: rgba(0, 0, 0, 0.87);
}
.bellContainer .notification-bell:not([data-count="0"]).is-muted {
  color: rgba(0, 0, 0, 0.54);
}
.bellContainer .notification-bell:hover {
  color: rgba(0, 0, 0, 0.38);
}
.notification-bell:hover:after {
  background-color: #00aa8d;
} */

/* sininho balançando */
svg.bell {
    cursor:pointer;
}
svg.bell path {
    transform-origin: 50% 50%;
    animation-name: bellring;
    animation-duration: 1s; 
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;  
}
@keyframes bellring { 
    0% { transform: rotate(0deg); }
    10% { transform: rotate(10deg); }
    20% { transform: rotate(-10deg); }
    30% { transform: rotate(10deg); }
    40% { transform: rotate(-10deg);}
    50% { transform: rotate(0deg);}
}

/* .notification-bell.is-animating {
  -webkit-animation: ringing 800ms;
          animation: ringing 800ms;
}
.notification-bell.is-animating .notification-bell__clapper {
  -webkit-animation: clapp 800ms;
          animation: clapp 800ms;
}


.notification-bell .notification-bell__bow,
.notification-bell .notification-bell__clapper {
  fill: currentColor;
}
.notification-bell svg {
  overflow: visible !important;
}

.notification-bell__clapper {
  -webkit-animation-timing-function: inherit;
          animation-timing-function: inherit;
  transform: translate(6px, 18px) rotate(0);
  transform-origin: inherit;
} */