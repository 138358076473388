.editor {
  border: none;
  cursor: text;
  overflow-y: auto;
  text-align: left;
  font: var(--px15_20-Roboto);
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  max-height: 5vh;
  min-height: 3vh;
  /* height: 3vh; */
  width: 100%;
  /* width: calc(100% - max(72px, 6vw)); */
  word-break: break-all; 
  display: 'block';
  padding-left: 12px !important;
  resize: none;
}

.buttonBlueSendMessage {
  border-radius: 4px !important;
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  /* width: 100%; */
  /* min-width: 40px;
  max-width: 72px; */
  height: max(23px, 4vh) !important;
  /* height: min(max(20px, 3vh), 30px) !important; */
  /* width: max(72px, 6vw); */
  /* height: 4.5vh; */
  border: none !important;
  font: var(--px17_24-font) !important;
}
.img-iframe {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.img-iframe:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.slideIn {
  animation: slideIn 1s forwards;
  overflow: hidden;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideOut {
  animation: slideOut 2s forwards;
  overflow: hidden;
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

body {
  background: transparent !important;
}