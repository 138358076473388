.backgroundImage {
    background-color: var(--main-color);
}

.backgroundLogin {
    background-color: var(--white2-color);
    height: 90.5%;
}

.backgroundLogin2 {
    background-color: var(--white-color);
    font: var(--px15_19-font);
    color: var(--glay-color);
    height: 9.5%;
}

.textFontBlue {
    font: var(--px20_24_Bold-font);
    color: var(--blue-color);
}

.textFontBlue2 {
    font: var(--px15_19_Bold-font);
    color: var(--main-color);
}

.textFontBlue3 {
    font: var(--px15_19-font);
    color: var(--main-color);
    text-decoration: underline;
}

.textFontGlay {
    font: var(--px15_19-font);
    color: var(--glay-color);
}

.LinkGlay {
    text-decoration: none;
    color: var(--glay-color);
    font: var(--px15_19-font);
}
