.item-download-button {
    padding: 16px;
    background-color: hsl(0, 0%, 96%);
    border-bottom: 1px solid hsl(210, 14%, 89%);
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 1.2rem;
        margin: 0;
    }

    button {
        display: flex;
        align-items: center;
        display: block;
        border-radius: 16px;
        text-align: center;
        padding: 4px 5px 4px 2px;
        border: 1px solid transparent;
        background-color: hsl(0, 0%, 80%);
        color: #707070;
        font-size: 14px !important;
        font-weight: 400;
        transition: background-color 0.3s ease;
    }

    button.clicked {
        background-color: #e2e2e2;
        color: #9b9b9b;
    }

    .tabler-icon {
        height: 15px;
        align-items: center;
    }
    
    button:hover {
        border: 1px solid hsl(0, 0%, 80%);
    }
}

.item-download-button:last-of-type {
    border-width: 0px;
}

.text-links {
    color: #707070;
    font-size: 16px !important;
    font-family: 'Montserrat';
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
}