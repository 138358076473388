.container-input {
    background-color: #efefef;
    height: 100px;
    width: 515px;
    display: grid;
    grid-template-columns: 220px 220px 100px;
    align-items: center;

    div.container-col-inputs {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        input.container-input {
            height: 18px;
            width: 90%;
            height: 35px;
            padding-left: 10px;
            border-radius: 5px;
            font-family: 'Montserrat';
            background-color: #fff;
            border: 0.01rem solid #e0e3e7;
        }
    }

    div.container-title {
        font-family: 'Montserrat';
        text-align: left;
        letter-spacing: 0px;
        color: #707070;
        opacity: 1;
        font-size: 0.8rem;
    }

    div.container-col-button {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 25px;

        Button.container-button {
            height: 38px;
            width: 38px;
            background-color: #eb5555;
            border: none;
            display: flex;
            align-items: center;
            margin-top: 15px;
        }
    }
}