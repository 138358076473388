div.details-ticket-info-component {
  display: flex;
  flex-direction: column;

  div.details-ticket-info-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    // border-bottom: 1px solid #ececec;
    padding: 15px;
    // max-height: calc((100vh - 195px) / 2);
    overflow: auto;
  
    div.details-ticket-info-group {
      display: flex;
      // flex-direction: row;
      align-items: flex-end;
      flex-wrap: wrap;
      // margin-bottom: 10px;
      gap: 5px;
    }
  
    div.details-ticket-info-item {
      display: flex;
      flex-direction: column;
      padding: 10px;
      background: #f6f6f6;
      border-radius: 5px;
      // min-width: 125px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 125px;
      max-height: 62px;
      min-height: 62px;
      overflow: hidden;
      cursor: default;
  
      span.ticket-info-item-label {
        font-size: 13px;
        font-weight: 600;
        color: #585858;
        cursor: default;
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
  
      span.ticket-info-item-value {
        display: flex;
        align-items: center;
        font-size: 15px;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
  
        svg {
          margin-right: 4px;
        }
      }
    }
  
    div.details-ticket-info-item-small {
      flex-basis: 80px;
    }
  }

}