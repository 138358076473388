div.consumer-info-component {
  display: flex;
  flex-direction: column;

  div.consumer-info-section {
    padding: 15px;

    div.consumer-info-details {
      display: flex;
      align-items: center;
      border: 1px solid #f0f0f0;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 5px;
      cursor: default;
      transition: 0.3s;

      svg.consumer-info-icon {
        min-width: 40px;
        width: 40px;
        height: 40px;
        stroke-width: 1.3;
        margin-right: 3px;
        -webkit-user-select: none;
        user-select: none;
        color: #919191;
      }

      svg.consumer-info-icon-open {
        align-self: flex-start;
        color: transparent;
        transition: 0.3s;
      }

      div.consumer-details-identifier {
        display: flex;
        flex-direction: column;
        line-height: 1.3;
        overflow: hidden;
        width: 100%;

        span.consumer-details-name {
          font-size: 14px;
          font-weight: 500;
          color: #555555;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }

        span.consumer-details-email {
          font-size: 12px;
          font-weight: 400;
          color: #919191;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }
      }
    }

    div.consumer-info-details.consumer-details-deleted {
      border: 1px solid #d24646;
      cursor: default;

      svg.consumer-info-icon {
        color: #d24646;
      }

      div.consumer-details-identifier {
        span.consumer-details-name {
          color: #d24646;
        }
        
        span.consumer-details-email {
          color: #d24646;
        }
      }
    }

    div.consumer-info-details:not(.consumer-details-deleted):not(.consumer-info-webchat):hover {
      background-color: #f8f8f8;
      cursor: pointer;

      svg.consumer-info-icon-open {
        color: #919191;
      }
    }
  }

  div.consumer-info-separator {
    width: 100%;
    height: 1px;
    background-color: #ececec;
  }

  div.details-consumers-info-item:not(:last-child) {
    // border-bottom: 1px solid #f1f1f8;
    margin-bottom: 10px;
  }

  div.details-consumer-info-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    div.details-consumer-info-item:not(:last-child) {
      margin-right: 10px;
    }

    div.details-consumer-info-item {
      margin-bottom: 0 !important;
      width: 100%;
    }
  }

  div.details-consumer-info-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #f6f6f6;
    border-radius: 5px;

    span.consumer-info-item-label {
      font-size: 13px;
      font-weight: 600;
      color: #585858;
      cursor: default;
    }

    div.details-consumer-info-channels {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;

      span.consumer-info-item-channel {

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #b6b6b6;
        padding: 5px;
        border-radius: 5px;
        margin-top: 5px;
        cursor: pointer;
        transition: 0.3s;

        svg {
          width: 20px;
          height: 20px;
          min-width: 20px;
          color: #fff;
        }
      }

      span.consumer-info-item-channel:hover {
        background-color: #979797;
      }

      span.consumer-info-item-channel.consumer-info-item-channel-contains {
        background-color: #4a69bd;
      }

      span.details-consumer-info-channels-empty {
        font-size: 12px;
        color: #717171;
        user-select: none;
      }
    }

  }

  div.consumer-info-section.consumer-info-history {
    // padding-top: 0;
    position: relative;

    span.consumer-info-section-title {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;

      div.consumer-info-section-actions {
        display: flex;
        align-items: center;

        svg {
          stroke-width: 2;
          color: #717171;
          cursor: pointer;
          transition: 0.3s;
        }

        svg.consumer-info-section-reload {
          width: 18px;
          height: 18px;
          min-width: 18px;
        }

        svg.consumer-info-section-collapse {
          width: 22px;
          height: 22px;
          min-width: 22px;
          margin-left: 4px;
        }

        svg.consumer-info-section-collapse.consumer-info-section-collapsed {
          transform: scaleY(-1);
        }

        svg:hover {
          color: #585858;
        }
      }

    }

    div.consumer-info-history-open {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 10px;
      border-radius: 5px;
      margin-top: 5px;
      background-color: #eef3ff;
      cursor: pointer;
      transition: 0.3s;
      user-select: none;

      svg {
        height: 20px;
        width: 20px;
        min-width: 20px;
        color: #4a69bd;
        stroke-width: 1.3;
        margin-right: 5px;
      }

      span.consumer-info-history-open-text {
        color: #4a69bd;
        font-size: 15px;
      }
    }

    div.consumer-info-history-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 10px;
      border-radius: 5px;
      margin-top: 5px;
      background-color: #ececec;
      user-select: none;

      svg {
        height: 20px;
        width: 20px;
        min-width: 20px;
        color: #717171;
        stroke-width: 1.3;
        margin-right: 5px;
      }

      span.consumer-info-history-open-text {
        color: #717171;
        font-size: 15px;
      }
    }

    div.consumer-info-history-open:hover {
      background-color: #cddaf6;
    }
    
    div.consumer-info-history-list {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      overflow: auto;
      // max-height: calc(100vh - 365px);
      // max-height: calc(100vh - 420px);
      background-color: #f5f5f5;
      padding: 8px;
      border-radius: 5px;

      div.consumer-info-history-item:not(:last-child) {
        padding-bottom: 10px;
      }

      div.consumer-info-history-item:last-child {
        span.consumer-info-history-item-status {
          span.consumer-info-history-item-border {
            display: none;
          }
        }
      }

      div.consumer-info-history-item {
        display: flex;
        cursor: pointer;

        span.consumer-info-history-item-status {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          position: relative;
  
          height: 20px;
          width: 20px;
          min-width: 20px;
          display: flex;
          color: #fff;
          border-radius: 5px;
          margin-right: 10px;
          font-weight: 600;
          font-size: 13px;
  
          span.consumer-info-history-item-border {
            position: absolute;
            height: 50px;
            width: 1px;
            background-color: #d4d4d4;
            top: 20px;
          }
        }

        div.consumer-info-history-item-content {
          display: flex;
          flex-direction: column;
          transition: 0.3s;
          width: 100%;
          border-radius: 5px;
          overflow: hidden;

          span.consumer-info-history-item-content-header {
            display: flex;

            span.consumer-info-history-ticket-id {
              margin-right: 5px;
              background: #d3ddf6;
              color: #0C2461;
              border-radius: 4px;
              font-size: 15px;
              font-weight: 600;
              padding: 0px 4px;
            }

            span.consumer-info-history-ticket-subject {
              font-size: 15px;
              text-wrap: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          span.consumer-info-history-item-content-footer {
            margin-bottom: 4px;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            svg {
              margin-left: 5px;
              color: #707070;
              width: 18px;
              height: 18px;
            }

            span.consumer-info-history-ticket-date {
              font-size: 12px;
              color: #919191;
              padding-left: 2px;
            }
          }
        }
      }

      div.consumer-info-history-item.consumer-info-history-item-selected {

        div.consumer-info-history-item-content {
          background-color: #d3ddf6;
          width: 100%;
          border-radius: 5px;
        }
      }

      div.consumer-info-history-item:hover {
        div.consumer-info-history-item-content {
          background-color: #d3ddf6;
          width: 100%;
          border-radius: 5px;
        }
      }
    }
  }
}