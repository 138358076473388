div.main-header-component {
  display: flex;
  flex-direction: column;

  div.main-header-actions-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    box-shadow: -5px -2px 10px -3px rgba(0, 0, 0, 0.7);
    padding: 10px 14px;
    gap: 10px;
    z-index: 100;

    div.main-header-actions-search {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
    }

    div.main-header-actions-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
    }
  }

  div.main-header-info {
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    cursor: default;
    user-select: none;
    padding: 10px 14px;

    span.main-header-info-title {
      font-size: 22px;
      font-weight: bold;
    }

    span.main-header-info-subtitle {
      font-size: 14px;
      color: #707070;
    }
  }
}

div.main-header { // remover
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 14px 14px 10px 14px;

  div.main-header-info {
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    cursor: default;
    user-select: none;

    span.main-header-info-title {
      font-size: 22px;
      font-weight: bold;
    }

    span.main-header-info-subtitle {
      font-size: 14px;
      color: #707070;
    }
  }

  div.main-header-actions {
    display: flex;
    align-items: center;
    position: relative;
  }
}

div.main-header.main-header-sidebar-opened {
  transition: 0.3s;
  width: calc(100% - 247px);
}

div.main-header.main-header-sidebar-closed {
  transition: 0.3s;
  width: calc(100% - 52px);
}

@media only screen and (max-width: 700px) {
  div.main-header {
    flex-direction: column-reverse;

    div.main-header-actions {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
    }
  }
}