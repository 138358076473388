div.modal-agent-component {
  div.modal-content {

    div.modal-header {
      user-select: none;
      z-index: 11;
      box-shadow: 0px 0px 12px -8px;

      div.modal-agent-header-actions {
        display: flex;
        align-items: center;
        gap: 6px;

        svg.modal-agent-header-actions-close {
          cursor: pointer;
          color: #707070;
        }
      }
    }

    div.modal-body {
      min-height: 200px;
      overflow: auto;

      div.modal-agent-section:last-child {
        margin-top: 15px;
      }

      // div.modal-agent-section:not(:last-child) {
      //   border-bottom: 1px solid #ececec;
      //   padding-bottom: 15px;
      // }

      div.modal-agent-section {
        display: flex;
        flex-direction: column;
        gap: 5px;

        div.modal-agent-items-group {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }

        div.modal-agent-item {
          width: 100%;
          position: relative;

          div.modal-agent-item-header {
            user-select: none;

            span.modal-agent-item-label {
              font-size: 15px;
              font-weight: 600;
              color: #717171;
            }

            span.modal-agent-item-label-required {
              color: #f36a6b;
              font-weight: 500;
            }
          }

          span.modal-agent-item-invalid-message {
            position: absolute;
            font-size: 12px;
            color: #f36a6b;
            right: 0;
            font-weight: 600;
          }
        }

        span.modal-agent-section-title {
          display: flex;
          align-items: center;
          font-weight: 600;
          user-select: none;
          margin-bottom: 5px;
          width: fit-content;

          svg {
            margin-left: 5px;
            transition: 0.3s;
          }

          svg.modal-agent-import-download-icon {
            color: #4a69bd;
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }

        div.modal-agent-section-profile {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: #f1f1f1;
          border-radius: 5px;
          padding: 5px 10px;

          div.modal-agent-section-profile-info {
            display: flex;
            flex-direction: column;
            cursor: default;

            span.modal-agent-section-profile-title {
              font-weight: 600;
            }
            span.modal-agent-section-profile-message {
              font-size: 14px;
              color: #707070;
            }
          }

          div.modal-agent-section-profile-switch {
            display: flex;
            align-items: center;
            min-width: 50px;
            justify-content: flex-end;
          }
        }
      }
    }

    div.modal-body.modal-body-loading {
      display: flex;
      align-items: center;
      justify-content: center;

      div.modal-body-loading-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          color: #4a69bd;
          stroke: #4a69bd;
          stroke-width: 1;
          height: 80px;
          width: 80px;
        }
      
        span.modal-body-loading-info-message {
          color: #4a69bd;
          font-family: Montserrat;
          text-align: center;
          cursor: default;
        }
      }

      div.modal-body-loading-info.modal-body-loading-error {
        svg {
          color: #f36a6b;
          stroke: #f36a6b;
        }

        span.modal-body-loading-info-message {
          color: #f36a6b;
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 11;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-agent-btn-save {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-agent-btn-save:not(:disabled):hover {
        background-color: #334f9d;
      }

      button.modal-agent-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-agent-btn-cancel:hover {
        background-color: #e8e8e8;
      }

      button:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  div.modal-agent-component {
    max-width: unset !important;

    div.modal-content {
      div.modal-body {
        max-height: unset;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  div.modal-agent-component {

    div.modal-content {
      div.modal-body {
        div.modal-agent-section {
          div.modal-agent-items-group {
            flex-direction: column;
            gap: 5px;
          }
        }
      }
    }
  }
}