div.file-viewer-component {

  div.file-viewer-component__list-attachments-no-content {
    margin-top: 0 !important;
  }

  div.file-viewer-component__list-attachments {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 7px;
    padding: 5px;
    border-radius: 5px;
    background-color: #0000000f;
    align-items: center;
    width: 100%;

    span.file-viewer-component__attachment-item {
      width: 100px;
      height: 100px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #c0c0c0;
      border-radius: 5px;
      background-color: #fff;
      position: relative;
      flex: 1 1 100px;

      span.file-viewer-component_attachment-item-overlay {
        background-color: rgba(0, 0, 0, 0);
        height: 100%;
        width: 100%;
        position: absolute;
        transition: 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span.file-viewer-component_attachment-item-name {
          display: none;
          font-size: 14px;
          color: #fff;
          max-height: 65px;
          overflow: hidden;
          white-space: normal;
          word-wrap: break-word;
          padding: 5px;
          cursor: default;
        }

        span.file-viewer-component_attachment-actions {
          display: none;
          margin-bottom: 4px;

          span.file-viewer-component_attachment-actions-item {
            background-color: rgba(154, 154, 154, 0.522);
            border-radius: 5px;
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s;
            cursor: pointer;

            svg {
              color: #fff;
              height: 20px;
              width: 20px;
              min-width: 20px;
            }
          }

          span.file-viewer-component_attachment-actions-item:hover {
            background-color: #4d4d4d85;
          }
        }
      }

      span.file-viewer-component_attachment-item-overlay:hover {
        background-color: rgba(0, 0, 0, 0.4);

        span.file-viewer-component_attachment-item-name {
          display: block;
        }

        span.file-viewer-component_attachment-actions {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    span.file-viewer-component__attachment-item__audio_file {
      width: 100%;
      // max-width: 250px;
      flex: 1 1 250px;
      // height: 70px;
      // height: fit-content;
    }
  }

  div.file-viewer-component-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(0, 0, 0, 0.8);
    z-index: 1000;

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);
    }

    span.file-viewer-component-wrapper__actions {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      gap: 10px;

      svg.file-viewer-component-wrapper__open,
      svg.file-viewer-component-wrapper__close {
        color: #fff;
        cursor: pointer;
        transition: 0.3s;
      }

      svg.file-viewer-component-wrapper__open:hover,
      svg.file-viewer-component-wrapper__close:hover {
        color: #d7d7d7;
      }
    }

    div.file-viewer-component-wrapper__content {
      display: grid;
      grid-template-rows: 1fr auto;
      z-index: 1002;
      height: calc(100vh - 60px);

      div.file-viewer-component-wrapper__selected-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;

        span.file-viewer-component-wrapper__selected-wrapper-container {
          position: relative;
          max-height: 85%;
          max-width: 85%;
          margin-bottom: 10px;
          border-radius: 5px;
          overflow: hidden;

          img.file-viewer-component-wrapper__selected {
            max-height: 100%;
            max-width: 100%;
          }

          span.file-viewer-component-page-info {
            font-size: 14px;
            color: #fff;
            position: absolute;
            bottom: 6px;
            right: 6px;
            background-color: #0000004b;
            padding: 0px 4px;
            border-radius: 5px;
            user-select: none;
          }
        }


        span.file-viewer-component-wrapper__selected-filename {
          color: #fff;
          font-size: 12px;
          cursor: default;
          text-align: center;
        }

        span.file-viewer-component-selected-navigator {
          position: absolute;
          cursor: pointer;
          user-select: none;

          svg {
            color: #fff;
            width: 64px;
            height: 64px;
            min-width: 64px;
            transition: 0.3s;
          }
        }

        span.file-viewer-component-selected-navigator.selected-navigator-left {
          left: 0;
        }
        span.file-viewer-component-selected-navigator.selected-navigator-right {
          right: 0;
        }

        span.file-viewer-component-selected-navigator:hover {
          svg {
            color: #cfcfcf;
          }
        }
      }

      div.file-viewer-component-wrapper__thumbnails-container {
        height: 120px;
        width: 100vw;
        position: relative;

        div.file-viewer-component-wrapper__thumbnails {
          display: flex;
          justify-content: center;
          gap: 10px;
          overflow-x: auto;
          scrollbar-width: none;
          user-select: none;
          
          position: absolute;
          bottom: 10px;
          transition: 0.3s;
  
          &::-webkit-scrollbar {
            display: none;
          }
    
          span.file-viewer-component-wrapper__attachment-item {
            width: 100px;
            height: 100px;
            min-width: 100px;
            max-width: 100px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            background-color: #fff;
            position: relative;
            cursor: pointer;
    
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: 0.3s;
  
              &.selected {
                border: 2px solid #fff;
                ;
              }
            }
          }

          span.file-viewer-component-wrapper__attachment-item:not(.attachment-selected):hover {
            img {
              filter: brightness(0.8);
            }
          }
        }
  
        span.file-viewer-component-wrapper__attachment-item.attachment-selected {
          border: 2px solid #fff;
          transform: scale(0.9);
          transition: 0.3s;
        }
      }

    }


    &__attachment-item {

      img {
        width: 100px;
        height: 75px;
        margin: 0 5px;
        cursor: pointer;
        border: 2px solid transparent;
        transition: border 0.3s;
    
        &.selected {
          border-color: white;
        }
      }
    }
  }
}
