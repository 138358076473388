div.modal-change-status-tag-component {
  div.modal-content {
    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      button.btn-close {
        margin-top: -15px;
        transition: 0.3s;
      }

      div.modal-change-status-tag-header-actions {
        display: flex;
        gap: 6px;

        svg {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }

        svg:hover {
          color: #606060;
        }
      }
    }

    div.modal-body {
      user-select: none;
      min-height: 86px;
      
      span.modal-change-status-tag-message {
        font-size: 18px;

        span {
          font-weight: 600;
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 1;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-change-status-tag-btn-activate {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-change-status-tag-btn-activate:not(:disabled):hover {
        background-color: #3856a7;
      }

      button.modal-change-status-tag-btn-inactivate {
        background-color: #d12d2d;
        color: #fff;
        border: none;
      }

      button.modal-change-status-tag-btn-inactivate:not(:disabled):hover {
        background-color: #b21f1f;
      }

      button.modal-change-status-tag-btn-activate:disabled,
      button.modal-change-status-tag-btn-inactivate:disabled {
        opacity: 0.4;
        cursor: default;
      }

      button.modal-change-status-tag-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-change-status-tag-btn-cancel:hover {
        background-color: #e8e8e8;
      }
    }
  }
}
