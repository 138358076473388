

.bi09khh{
    display:inline-block;
}

.bc4rxid, .buttonAttachmentBlur {
    background: transparent;
    color:#888;
    font-size:18px;
    border:0;
    vertical-align: bottom;
    height: 100%,
    /*height:24px;
    width:36px;*/
}

.bc4rxid:hover, 
.buttonAttachmentBlur:hover,
.buttonAttachmentFocus:hover,
.buttonAttachmentFocus2:hover,
.bc4rxid:focus  {
    background:#EEF3FF !important;
    outline:0 ;
    border-radius: 0;
}
.bc4rxid svg{
    fill:#888;
}

.buttonAttachmentFocus2 {
    background: #FFF;
    outline: 0;
    color: #888;
    font-size: 10px;
    border: 0;
    border-radius: 0;
    vertical-align: bottom;
    height: 100%;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.buttonAttachmentFocus {
    background:#EEF3FF;
    outline:0;
    color:#888;
    font-size:10px;
    border:0;
    border-radius: 0;
    vertical-align: bottom;
    height: 100%;
    width:36px;
}

.akzb7t5{
    background:#EEF3FF;
    color:#444;
}
.akzb7t5 svg{
    fill:#444;
}

.s6m29i4{
    display:inline-block;
    border-right:1px solid #ddd;
    height:24px;
    margin:0 0.5em;
}

/*Emoji position*/
.ejr02pv {
    transform: scale(1) translateY(-100%);
    top: 78%;
}


.e183m4hm {
    /*height:100% !important;*/
    width:36px !important;
    border: 0 !important;
}

.e13wqaj6, .e8k2yoa {
    height: 100% !important;
    width: 100% !important;
    border-radius: 0em !important;
    
    background: transparent !important;
    color:#888 !important;
    font-size:22px !important;
    border:0 !important;
    vertical-align: bottom !important;
}

.e13wqaj6, .e8k2yoa:hover {
    background:#EEF3FF !important;
    outline:0 !important;
}