div.loader {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: 0.3s;
  z-index: 10;
  top: 0;
  left: 0;

  div.icon-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100px;
  }
}

div.loader.loader-relative {
  position: relative;
}

div.loader.loader-blur {
  backdrop-filter: blur(2px);
}