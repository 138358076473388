div.toolbar-audio-recorder-component {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border: 2px dashed #4a69bd;
  margin: 0px 5px;
  border-radius: 5px;
  user-select: none;

  span.toolbar-audio-recorder-permission-denied {
    font-size: 13px;
    line-height: 18px;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #ececec;
    padding: 6px 10px;
    border-radius: 5px;

    span.toolbar-audio-recorder-permission-denied-title {
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  div.toolbar-audio-recorder-container {
    width: 100%;
    text-align: center;

    button.toolbar-audio-recorder-action-start {
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #cfdcff;
      color: #4a69bd;
      transition: 0.3s;
    }

    button.toolbar-audio-recorder-action-start:hover {
      background-color: #b2c7ff;
    }

    button.toolbar-audio-recorder-action-stop {
      border: none;
      border-radius: 5px;
      padding: 0px 10px;
      height: 40px;
    }

    div.toolbar-audio-recorder-container-recording {
      display: flex;
      align-items: center;
      justify-content: center;

      span.toolbar-audio-recorder-container-recording-time {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0px 10px;
        border-radius: 5px;

        span.toolbar-audio-recorder-icon {
          animation: pulse 1s infinite;

          svg {
            width: 28px;
            min-width: 28px;
            height: 28px;
            margin-right: 4px;
            transform: scale(1);
            color: #f17a70;
          }
        }

        span.toolbar-audio-recorder-current-time {
          font-size: 26px;
          font-weight: 200;
          user-select: none;
        }
        
        @keyframes pulse {
          0% {
            transform: scale(1.05);
          }
        
          70% {
            transform: scale(0.95);
          }
        
          100% {
            transform: scale(1.05);
          }
        }
      }
    }

    div.toolbar-audio-recorder-player-container {
      display: flex;

      div.toolbar-audio-recorder-player-controller {
        border: 1px solid #ececec;
        border-radius: 5px;
        height: 90px;
        margin-right: 10px;
        width: 100%;
      }

      div.toolbar-audio-recorder-player-actions {
        display: flex;
        flex-direction: column;
        gap: 5px;

        button {
          border: none;
          border-radius: 5px;
          height: 100%;
          display: flex;
          align-items: center;
          padding-right: 10px;
          padding-left: 10px;
          transition: 0.3s;

          svg {
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 5px;
            stroke-width: 1.5px;
          }
        }

        button.toolbar-audio-recorder-player-attach {
          background-color: #cfdcff;
          color: #4a69bd;
        }

        button.toolbar-audio-recorder-player-attach:hover {
          background-color: #b2c7ff;
        }

        button.toolbar-audio-recorder-player-remove {
          color: #f17a70;

          svg {
            color: #f17a70
          }
        }

        button.toolbar-audio-recorder-player-remove:hover {
          background-color: #dedede;
        }
      }
    }
  }
}
