.icon-img-editor {
  position: relative;

  .input-url {
    background: #fff;
    position: absolute;
    bottom: 25px;
    border: 1px solid #dee2e6;
    padding: 10px;
    border-radius: 7px;
    transform: translateX(-47%);
    z-index: 99999;
    width: 300px;

    display: flex;
    flex-direction: row;
    justify-content: left;

    &::before {
      content: '';
      position: absolute;
      height: 8px;
      width: 8px;
      background: #fff;
      border-left-color: #fff;
      border-top-color: #fff;
      border-right-color: #dee2e6;
      border-bottom-color: #dee2e6;
      border-style: solid;
      border-bottom-width: 1px;
      border-right-width: 1px;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);

    }
    input{
      width: 100%;
      padding: 8px 12px;
      border: 1px solid gainsboro;
      background-color: transparent;
      border-radius: 5px;
      font-family: "Quicksand";
      outline: none;
      transition: 0.3s;
      font-size: 12px;
    }

    input:disabled {
      background-color: #f3f3f3;
    }

    input:focus {
      border: 1px solid #4a69bd;
    }

    button.add-img {
      background: #fff;
      cursor: pointer;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      margin-left: 4px;
      font-size: 16px;
      padding: 5px 15px;
    }
  }
}