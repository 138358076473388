div.list-message-triggers-component {
  width: 100%;
  display: grid;
  min-height: 0;
  min-width: 0;
  grid-template-rows: auto auto 1fr;

  div.list-message-triggers-tabs {
    display: flex;
    gap: 10px;
    padding: 0px 14px 14px 14px;
    user-select: none;

    span.list-message-triggers-tab-item {
      font-size: 14px;
      background-color: #ececec;
      padding: 3px 6px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
    }

    span.list-message-triggers-tab-item:not(.list-message-triggers-tab-item-active):hover {
      background-color: #d7d7d7;
    }

    span.list-message-triggers-tab-item-active {
      background-color: #4a69bd;
      color: #fff;
    }

    span.list-message-triggers-tab-item-active:hover {
      background-color: #2e488f;
    }
  }

  div.list-message-triggers-page-content {
    display: grid;
    min-height: 0;
    min-width: 0;
    padding: 0px 14px 14px 14px;
  }
}
