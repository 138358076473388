div.modal-list-tasks-component {
  div.modal-content {
    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 8px;

        span {
          font-weight: bold;
        }
      }

      div.modal-list-tasks-header-actions {
        display: flex;
        gap: 6px;

        span.modal-list-tasks-header-actions-create-btn {
          display: flex;
          align-items: center;
          font-size: 15px;
          background-color: #707070;
          color: #fff;
          border-radius: 5px;
          padding: 0px 8px 0px 5px;
          cursor: pointer;
          transition: 0.3s;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        span.modal-list-tasks-header-actions-create-btn:hover {
          background-color: #606060;
        }

        svg.modal-list-tasks-header-actions-close {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }

        svg.modal-list-tasks-header-actions-close:hover {
          color: #606060;
        }
      }
    }

    div.modal-body {
      user-select: none;
      min-height: 300px;
      max-height: 550px;
      overflow-y: auto;
      
      div.modal-list-tasks-content {
        display: flex;
        flex-direction: column;
        position: relative;

        div.modal-list-tasks-form {
          display: flex;
          flex-direction: column;
          gap: 10px;

          div.modal-list-tasks-form-item {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
  
            span.modal-list-tasks-form-item-label {
              font-size: 15px;
              color: #717171;
              font-weight: 500;
              -webkit-user-select: none;
              user-select: none;
              
              span {
                font-weight: 600;
                color: #000;
              }
            }

            span.modal-list-tasks-form-item-fake {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: #ececec;
              border: 1px solid #ced4da;
              border-radius: 5px;
              padding: 5px 4px 5px 8px;
              min-height: 40px;
              width: 100%;
            }

            span.modal-list-tasks-form-item-check {
              display: flex;
              align-items: center;
              margin-top: 5px;
              margin-bottom: 5px;

              span {
                cursor: pointer;
              }

              svg {
                color: #4a69bd;
              }

              svg:not(.modal-list-tasks-form-item-check-info) {
                margin-right: 5px;
              }

              svg.modal-list-tasks-form-item-check-info {
                margin-left: 5px;
                width: 20px;
                height: 20px;
              }
            }

            span.modal-list-tasks-form-item-info {
              font-size: 14px;
              font-style: italic;
              color: #707070;
              text-align: end;
            }
          }

          div.modal-list-tasks-form-item.tasks-form-item-responsive {
            flex-grow: 1;
            flex-basis: 249px;
            max-width: unset !important;
          }

          div.modal-list-tasks-form-items-row {
            display: flex;
            gap: 10px;

            div.modal-list-tasks-form-item {
              max-width: calc((100% / 2) - 5px);
            }
          }
        }

        div.modal-list-tasks-delete-confirmation {
          position: absolute;
          background-color: #fff;
          width: 100%;
          height: 100%;
          border: 1px solid #ececec;
          border-radius: 5px;
          display: grid;
          grid-template-rows: auto 1fr auto;
          padding: 10px;

          span.modal-list-tasks-delete-title {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 10px;
          }
          div.modal-list-tasks-delete-info {
            display: flex;
            flex-direction: column;

            span.modal-list-tasks-delete-info-message {
              font-weight: 500;
              margin-bottom: 10px;
            }

            div.modal-list-tasks-delete-info-fields {
              display: flex;
              flex-direction: column;
              gap: 5px;
              overflow: auto;
              scrollbar-width: thin;
              padding: 5px;

              div.modal-list-tasks-delete-info-fields-row {
                display: flex;
                gap: 10px;

                div.modal-list-tasks-delete-info-fields-item {
                  text-overflow: ellipsis;
                  text-wrap: nowrap;
                  max-width: calc(100% / 2);
                  overflow: hidden;

                  span.modal-list-tasks-delete-info-fields-item-value {
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                    overflow: hidden;
                  }
                }

              }

              div.modal-list-tasks-delete-info-fields-item {
                display: flex;
                flex-direction: column;
                width: 100%;

                span.modal-list-tasks-delete-info-fields-item-label {
                  font-size: 15px;
                  font-weight: 500;
                  color: #707070;
                }

                span.modal-list-tasks-delete-info-fields-item-value {
                  font-size: 15px;
                  background-color: #ececec;
                  padding: 10px 10px;
                  border-radius: 5px;
                }

                span.modal-list-tasks-delete-info-fields-item-value.info-fields-item-overflow {
                  max-height: 90px;
                  overflow: auto;
                  scrollbar-width: thin;
                }
              }
            }
          }

          div.modal-list-task-delete-footer {
            display: flex;
            gap: 5px;
            justify-content: flex-end;

            span.modal-list-tasks-delete-action {
              border-radius: 5px;
              cursor: pointer;
              transition: 0.3s;
              font-size: 16px;
              padding: 2px 8px;
            }

            span.modal-list-tasks-delete-action.tasks-delete-action-cancel {
              background-color: #909090;
              color: #fff;
            }

            span.modal-list-tasks-delete-action.tasks-delete-action-cancel:hover {
              background-color: #808080;
            }

            span.modal-list-tasks-delete-action.tasks-delete-action-confirm {
              background-color: #d24646;
              color: #fff;
            }

            span.modal-list-tasks-delete-action.tasks-delete-action-confirm:hover {
              background-color: #be3636;
            }
          }
        }

      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 1;
      flex-wrap: nowrap;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-list-tasks-btn-save {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-list-tasks-btn-save:not(:disabled):hover {
        background-color: #4a69bd;
      }

      button.modal-list-tasks-btn-save:disabled {
        opacity: 0.4;
        cursor: default;
      }

      button.modal-list-tasks-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-list-tasks-btn-cancel:hover {
        background-color: #e8e8e8;
      }

      span.modal-list-tasks-error-msg {
        font-size: 14px;
        font-weight: 500;
        color: #f36a6b;
        cursor: default;
        text-align: center;
      }
    }

    div.modal-footer.modal-footer-only-edit {
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: 560px) {
  div.modal-list-tasks-component {
    div.modal-content {
      div.modal-body {
        div.modal-list-tasks-content {
          div.modal-list-tasks-form {
            div.modal-list-tasks-form-items-row {
              flex-direction: column;
            }

            div.modal-list-tasks-form-item {
              width: 100%;
              max-width: unset !important;
            }

            div.modal-list-tasks-form-item.tasks-form-item-responsive {
              flex-grow: unset;
              flex-basis: unset;
            }
          }

          div.modal-list-tasks-delete-confirmation {
            div.modal-list-tasks-delete-info {
              div.modal-list-tasks-delete-info-fields {
                div.modal-list-tasks-delete-info-fields-row {
                  flex-direction: column;
                }
              }
            }
          }
        }
      }
    }
  }
}