.empty-collected {
    height: 64px;
    background: #F1F1F1;
    color: #707274;
    border-radius: 3px;
    font-family: 'Montserrat';
    font-weight: 400;
}

.title-collected {
    color: #4A69BD;
    font-family: 'Montserrat';
    font-weight: 600;
    line-height: 19.5px;
    margin-top: 1.1em;
    padding: 0;
}

.subtitle-collected {
    color: #707070;
    font-family: 'Montserrat';
    font-weight: 400;
    padding: 0;
    margin: 1px 0;
}

.label-title {
    color: #0C2461;
    font-family: 'Montserrat';
    font-weight: 700;
    line-height: 19px;
    font-size: 16px;
}

.button-export {
    width: 50%;
    font-family: 'Montserrat';
    font-weight: 800;
}

.export-date-calendar {
    position: relative;
    display: flex;
    align-items: center;
}

.input-calendar {
    width: 100%;
    font-size: 14px;
    border: 1px solid #D9D9D9;
    border-radius: 0.3rem;
    outline: none;
    box-sizing: border-box;
    transition: 0.3s;
    height: 5.6vh;
    padding-left: 40px;
    font-family: 'Montserrat';
    align-content: center;
}

.input-calendar::placeholder {
    color: #919191;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
}

.tabler-icon-calendar {
    position: absolute;
    left: 10px;
    transition: 0.3s;
    color: #707070;
    align-self: center;
}

.error-selected {
    border: 1px solid red;
}

.selected-fields {
    font-family: 'Montserrat' !important;
    font-size: 14px !important;
}