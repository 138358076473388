.activityContainer {
    .titleModal {
        font-family: 'Montserrat';
        font-size: 18px;
        font-weight: 700;
        line-height: 21.94px;
        text-align: left;
    }

    .cardInfo {
        margin-bottom: 1.4vh;
        top: 258px;
        left: 425px;
        gap: 0px;
        border-radius: 5px;
        background-color: #F6F6F6;
        padding: 14px 12px;

        label {
            font-family: 'Montserrat';
            font-size: 16px;
            font-weight: 700;
            line-height: 19.5px;
            text-align: left;
            color: #585858;
        }

        span {
            font-family: 'Montserrat';
            font-size: 15px;
            font-weight: 400;
            line-height: 18.29px;
            text-align: left;
            color: #000000;
        }

        .cardHistoric {
            background-color: white;
            padding: 14px 10px;
            margin-bottom: 10px;
            border-radius: 5px;

            .titleHistoric{
                font-family: 'Montserrat';
                font-size: 16px;
                font-weight: 600;
                line-height: 18.29px;
                text-align: left;
            }
        }
    }
}