.phoneReceivingCall {
  z-index: 20;
  color: #000;
  background: #fff;
  border: 1px solid #01963833;
  height: fit-content;
  min-width: 235px;
  position: absolute;
  // right: 75px;
  // bottom: -4px;
  right: 0px;
  // bottom: 30px;
  top: 45px;
  border-radius: 5px;
  box-shadow: 0px 1px 12px 0px rgb(0, 0, 0, 0.44);
  font-family: 'Montserrat';
  overflow: hidden;
  padding: 15px;
  animation: live-in-call 1.5s ease infinite backwards;

  display: flex;

  div.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-right: 20px;

    span.number {
      text-wrap: nowrap;
      font-size: 18px;
      font-weight: 600;
    }

    span.number.in-call-progress {
      font-size: 16px;
    }

    span.duration {
      font-size: 12px;
      margin-top: -7px;
    }
  }

  div.action {
    display: flex;
    align-items: center;
    justify-content: center;

    span.btnAction {
      border-radius: 50%;
      padding: 5px;
      cursor: pointer;
      transition: 0.3s;
    }

    span.answer {
      background-color: #17A589;
    }

    span.hangup {
      background-color: #f36a6b;
      margin-left: 10px;
    }

    span.answer:hover {
      background-color: #129077;
    }

    span.reject:hover {
      background-color: #d45757;
    }
  }

  @keyframes live-in-call {
    0% {
      box-shadow: 0px 0px 0px 0px #8ca79600;
    }
    80% {
      box-shadow: 0px 0px 0px 4px #01963833;
    }
    100% {
      box-shadow: 0px 0px 0px 6px transparent;
    }
  }
}