div.modal-active-trigger-component {
  div.modal-content {

    div.modal-header {
      user-select: none;
      z-index: 11;
      box-shadow: 0px 0px 12px -8px;

      div.modal-active-trigger-header-actions {
        display: flex;
        align-items: center;
        gap: 6px;

        svg.modal-active-trigger-header-actions-close {
          cursor: pointer;
          color: #707070;
        }
      }
    }

    div.modal-body {
      min-height: 200px;
      overflow: auto;

      div.modal-active-trigger-section.section-margin-top {
        margin-top: 15px;
      }

      div.modal-active-trigger-section {
        display: flex;
        flex-direction: column;
        gap: 5px;

        div.modal-active-trigger-items-group {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }

        div.modal-active-trigger-item {
          width: 100%;
          position: relative;

          div.modal-active-trigger-item-header {
            user-select: none;
            display: flex;
            align-items: center;

            span.modal-active-trigger-item-label {
              font-size: 15px;
              font-weight: 600;
              color: #717171;
            }

            span.modal-active-trigger-item-label-required {
              color: #f36a6b;
              font-weight: 500;
            }

            svg.modal-active-trigger-item-action {
              color: #4a69bd;
              cursor: pointer;
              width: 20px;
              height: 20px;
              margin-left: 5px;
            }
          }

          span.modal-active-trigger-item-invalid-message {
            position: absolute;
            font-size: 12px;
            color: #f36a6b;
            right: 0;
            font-weight: 600;
          }

          div.modal-active-trigger-import-area {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 85px;
            margin-top: 5px;
            width: 100%;
            border: 2px dashed #ced4da;
            border-radius: 5px;
            cursor: pointer;
            color: #A5A5A5;

            span.modal-active-trigger-import-area-message {
              display: flex;
              align-items: center;
              gap: 5px;

              svg {
                color: #4a69bd;
              }
            }

            span.modal-active-trigger-import-area-selected-file {
              display: flex;
              align-items: center;
              background-color: #ececec;
              border-radius: 5px;
              padding: 3px 6px;
              color: #707070;
              gap: 5px;
              line-break: anywhere;

              svg.modal-active-trigger-import-delete-file {
                color: #f36a6b;
                width: 20px;
                height: 20px;
                cursor: pointer;
                transition: 0.3s;
              }

              svg.modal-active-trigger-import-delete-file:hover {
                color: #db5151;
              }
            }
          }

          div.modal-active-trigger-import-area.modal-active-trigger-import-area-invalid {
            cursor: default;
            border: 2px dashed #f36a6b;
            color: #f36a6b;
          }

          div.modal-active-trigger-import-area.modal-active-trigger-import-selected {
            cursor: default;
            padding: 5px;
          }

          div.modal-active-trigger-message-area {
            border: 1px solid #ced4da;
            border-radius: 5px;

            div.chat-editor-component {
              div.chat-editor-container {
                div.chat-editor-container-column {
                  min-height: 110px;
                  max-height: 200px;
                }
              }
            }

          }
          
          div.modal-active-trigger-message-area.modal-active-trigger-message-area-disabled {
            display: flex;
            padding: 2px;
            cursor: default;
            min-height: 130px;

            span.modal-active-trigger-message-area-disabled-alert {
              background-color: #f8f8f8;
              padding: 8px 8px;
              border-radius: 5px;
              display: flex;
              width: 100%;
              margin: 5px;
              align-items: center;
              justify-content: center;
              color: #bebebe;
            }
          }
        }

        span.modal-active-trigger-section-title {
          display: flex;
          align-items: center;
          font-weight: 600;
          user-select: none;
          margin-bottom: 5px;
          width: fit-content;

          svg {
            margin-left: 5px;
            transition: 0.3s;
          }

          svg.modal-active-trigger-import-download-icon {
            color: #4a69bd;
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }

        div.modal-active-trigger-section-check {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: #f1f1f1;
          border-radius: 5px;
          padding: 5px 10px;

          div.modal-active-trigger-section-check-info {
            display: flex;
            flex-direction: column;
            cursor: default;

            span.modal-active-trigger-section-check-title {
              font-weight: 600;
            }
            span.modal-active-trigger-section-check-message {
              font-size: 14px;
              color: #707070;
            }
          }

          div.modal-active-trigger-section-check-switch {
            display: flex;
            align-items: center;
            min-width: 50px;
            justify-content: flex-end;
          }
        }
      }
    }

    div.modal-body.modal-body-loading {
      display: flex;
      align-items: center;
      justify-content: center;

      div.modal-body-loading-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          color: #4a69bd;
          stroke: #4a69bd;
          stroke-width: 1;
          height: 80px;
          width: 80px;
        }
      
        span.modal-body-loading-info-message {
          color: #4a69bd;
          font-family: Montserrat;
          text-align: center;
          cursor: default;
        }
      }

      div.modal-body-loading-info.modal-body-loading-error {
        svg {
          color: #f36a6b;
          stroke: #f36a6b;
        }

        span.modal-body-loading-info-message {
          color: #f36a6b;
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 11;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-active-trigger-btn-save {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-active-trigger-btn-save:not(:disabled):hover {
        background-color: #334f9d;
      }

      button.modal-active-trigger-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-active-trigger-btn-cancel:hover {
        background-color: #ebf1ff;
      }

      button:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }

    div.modal-footer.modal-footer-only-close {
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: 575px) {
  div.modal-active-trigger-component {
    max-width: unset !important;

    div.modal-content {
      div.modal-body {
        max-height: unset;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  div.modal-active-trigger-component {

    div.modal-content {
      div.modal-body {
        div.modal-active-trigger-section {
          div.modal-active-trigger-items-group {
            flex-direction: column;
            gap: 5px;
          }
        }
      }
    }
  }
}