div.modal-send-ticket-copy-component {
  div.modal-content {
    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      div.modal-send-ticket-copy-header-actions {
        display: flex;
        gap: 6px;

        svg {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }

        svg:hover {
          color: #606060;
        }
      }
    }

    div.modal-body {
      user-select: none;
      
      div.modal-send-ticket-copy-form {
        display: flex;
        flex-direction: column;

        div.modal-send-ticket-copy-form-header {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          border-bottom: 1px solid #ececec;
          padding-bottom: 16px;
          margin-bottom: 16px;

          span.modal-send-ticket-copy-form-header-title {
            font-weight: 600;
          }
        }

        div.modal-send-ticket-copy-form-item {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          span.modal-send-ticket-copy-form-item-label {
            font-size: 15px;
            color: #717171;
            font-weight: 600;
            -webkit-user-select: none;
            user-select: none;
            
            span {
              font-weight: bold;
              color: #000;
            }
          }
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 1;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-send-ticket-copy-btn-save {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-send-ticket-copy-btn-save:not(:disabled):hover {
        background-color: #4a69bd;
      }

      button.modal-send-ticket-copy-btn-save:disabled {
        opacity: 0.4;
        cursor: default;
      }

      button.modal-send-ticket-copy-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-send-ticket-copy-btn-cancel:hover {
        background-color: #e8e8e8;
      }
    }
  }
}
