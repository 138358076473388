.modal-ticket-export {    
  .modal-body::-webkit-scrollbar {
    display: none;
  }
  
  .modal-body {
    overflow-y: auto;
    -ms-overflow-style: none;  
    
    .custom-calendar {
      transform: none !important;
      top: -250px !important;
    }
  }
  
  .lista {
    margin-bottom: 20px;
    max-height: 210px;
    overflow-y: auto;
    
    p {
      text-decoration: none;
      color: hsl(100, 1%, 49%);
    }

    .tabler-icon {
      color: hsl(211, 13%, 42%);
    }
  }
  
  .item {
    display: flex;
    padding: 16px;
    background-color:hsl(0, 0%, 96%);
    border-bottom: 1px solid hsl(210, 14%, 89%);
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 1.2rem;
    }

    button {
      display: flex;
      align-items: center;
      display: block;
      border-radius: 16px;
      text-align: center;
      padding: 4px 10px 4px 2px;
      border: none;
      background-color:hsl(0, 0%, 90%);
    }
    .tabler-icon {
      height: 15px;
    }

    a {
      text-decoration: none;
      color: hsl(211, 13%, 42%);
      font-size: 13px;
    }
    
    button:hover {
      border: 1px solid hsl(0, 0%, 80%);
    }
  }

  .item:last-of-type {
    border-width: 0px;
  }
  
  .react-datepicker-wrapper {
    width: inherit;
  }

  .ticket_export-date-input {
    position: relative;
    width: 100%;
  
    input[type="text"] {
      width: 100%;
      font-size: 15px;
      border: 2px solid hsl(0,0,85);
      border-style: solid;
      border-width: 1px;
      border-radius: 0.3rem;
      outline: none;
      box-sizing: border-box;
      transition: 0.3s;
      height: 5.6vh;
      padding-left: 10px;
    }
  
    input[type="text"]::placeholder {
      color: hsl(0, 0, 73);
    }
  
    .tabler-icon {
      position: absolute;
      right: 10px;
      top: 30%;
      transition: 0.3s;
      color: hsl(0, 0, 43);
    }
  
  }
  
  .filter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    gap: 16px 16px;
  
    .item-wrapper {
      margin-top: 10px;
      min-width: 100%;
      label {
        display: block;
      }
    }
    
    .date-wrapper {
      position: relative;
      width: 100%;

      .invalid {
        color: hsl(354, 70%, 54%);
      }
    }
  }

  p {
    margin-top: 16px;
    color: hsl(0, 0%, 40%);
    font-size: small;
  }

  .error-message {
    color: hsl(354, 70%, 54%);
    margin-top: 16px;
    font-size: small;
  }
  
  .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    width: 100%;
  }

  .noTicketFound {
    font-size: 1.5rem;
  } 

  .modal-footer {
    border-top: unset;
  }

  .rmsc .options {
    max-height: 150px;
  }

  .rmsc .dropdown-content .panel-content {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 13%;
  }

}

@media only screen and (max-height: 1015px) {
  .modal-content {
    
    .modal-body {
      .custom-calendar {
        top: 0 !important;
        right: 20px !important;
      }
    }

    .filter {
      .date-wrapper {
        position: static;
      }
    }
    
  }

}

@media only screen and (max-width: 575px) {
  div.modal-ticket-export {
    max-width: unset !important;
    
    .lista {
      max-height: 90%;
    }
  }

  div.modal-content {
    div.modal-body {
      max-height: unset;

      .filter {
        grid-template-columns: 1fr;
        gap: 0;
      }

      .date-wrapper { 
        grid-column: span 1;
      }

      h6 {
        margin-bottom: 0;
      }

    }
  }

}
