.phoneCallcenterDialpad {
  div.header {
    display: flex;
    flex-direction: row;
    box-shadow: -1px 0px 10px 0px #00000094;
    z-index: 16;
    position: relative;
    user-select: none;

    div.info {
      background-color: #0c2461;
      color: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      
      span.text {
        width: 100%;
        padding: 10px 15px;
        font-weight: 600;
      }

      span.minimize {
        cursor: pointer;
        margin-right: 10px;
      }
    }

    div.header-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      
      width: fit-content;

      span {
        cursor: pointer;
        height: 100%;
        display: flex;
        width: 50px;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
      }

      span.logout {
        background-color: #f36a6b;
      }

      span.logout:hover {
        background-color: #dd5d5d;
        transition: 0.2s;
      }

    }
  }

  div.main-actions {
    background-color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 5px;

    span.action-icon:not(:last-child) {
      margin-right: 4px;
    }

    span.action-icon {
      border: 1px solid #ffffff0f;
      border-radius: 4px;
      padding: 4px;
      cursor: pointer;
      transition: 0.3s;
    }

    span.action-icon.active {
      background: #9b9b9b;
    }

    span.action-icon:hover {
      background: #9b9b9b;
      transition: 0.3s;
    }
  }

  div.selected-action {
    min-height: 333px;
  }

  div.dialpad-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    span.btn-action {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px;
      font-weight: 600;
      color: #fff;
      transition: 0.2s;
    }
    span.call {
      background-color: #0d9d9a;
    }

    span.hangup {
      background-color: #c01616;
    }

    span.call:hover {
      background-color: #098d8b;
      transition: 0.2s;
    }
  }
}