.nav-link.active {
  background-color: transparent !important;
  /* color: #1E1E1E !important; */
  font: normal normal normal calc(0.2em + 0.7vw) 'Roboto' !important;
  width: 25vh !important;
  height: min(max(45px, 4.5vh), 70px) !important;
  text-align: left !important;
  border-bottom: 4px solid !important;
  margin-left: 0.8rem;
}
  
.nav-link {
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 1px !important;
  color: #a0a0a0 !important;
  font: normal normal normal calc(0.2em + 0.7vw) 'Roboto' !important;
  width: 25vh !important;
  height: min(max(45px, 4.5vh), 70px) !important;
  text-align: left !important;
  border-bottom: 4px solid transparent !important;
  margin-left: 0.8rem;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav {
  --bs-nav-link-padding-x: 0rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}