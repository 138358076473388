svg.svg-status-online path {
  fill: #1e8e3e;
}

svg.svg-status-offline path {
  fill: #ed1c24;
}

svg.svg-status-ausente path {
  fill: #fff200;
}

#popOverStatusAgent {
  left: -25px !important;
}