.editor {
  border: none;
  cursor: text;
  overflow-y: auto;
  text-align: left;
  font: var(--px15_19-font-Roboto);
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  max-height: 9vh;
  width: 100%;
  word-break: break-all; 
  display: block;
  padding-left: 12px !important;
  min-height: 100%;
}

.editorMessageTrigger {
  border: none;
  cursor: text;
  overflow-y: auto;
  text-align: left;
  font: var(--px15_19-font-Roboto);
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  width: 100%;
  height: 160px;
  word-break: break-all; 
  display: block;
  padding-left: 12px !important;
  min-height: 100%;
}

.buttonBlueSendMessage {
  border-radius: 4px!important;
  background: var(--main-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(30.4px, 3.8vh), 41px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonBlueSendMessage:hover {
  background: var(--blue2-color) 0% 0% no-repeat padding-box !important;
}


.buttonAttachment {
  border: 'blue'; 
  background: 'transparent';
}