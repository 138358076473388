div.popup-search-filter-component {
  display: flex;
  flex-direction: column;

  position: absolute;
  background-color: #fff;
  min-height: 60px;
  min-width: 350px;
  width: 100%;
  padding: 14px;
  top: 45px;
  left: 0;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  z-index: 100;
  user-select: none;

  div.popup-search-filter-tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 14px;

    span.popup-search-filter-tabs-item {
      font-size: 12px;
      background-color: #ececec;
      padding: 3px 6px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
    }

    span.popup-search-filter-tabs-item:not(.filter-tabs-active):hover {
      background-color: #d8d8d8;
    }

    span.popup-search-filter-tabs-item.filter-tabs-active {
      background-color: #4a69bd;
      color: #fff;
    }

    span.filter-tab-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  div.popup-search-filter-form-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    position: relative;

    p {
      font-size: 14px;
      margin-bottom: 0;
      color: #707070;
    }
  }

  div.popup-search-filter-form {
    display: flex;
    // flex-direction: column;
    row-gap: 5px;
    column-gap: 10px;
    flex-wrap: wrap;
    overflow: auto;
    padding-bottom: 8px;
    max-height: 350px;
    scrollbar-width: thin;

    div.popup-search-filter-form-row {
      display: flex;
      flex-direction: row;
      gap: 10px;

      div.popup-search-filter-form-item {
        max-width: calc((100% / 2) - 5px);
      }
    }

    div.popup-search-filter-form-custom-field-header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 10px;
      width: 100%;

      div.popup-search-filter-form-item:nth-child(1) {
        max-width: 170px;
        min-width: 100px;
      }

      div.popup-search-filter-form-item:nth-child(2) {
        max-width: calc(100% - 230px);
        min-width: 170px;
      }

      div.popup-search-filter-form-button-add {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #8B8B8B;
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;

        svg {
          color: #fff;
        }
      }

      div.popup-search-filter-form-button-add.popup-search-filter-form-button-add-disabled {
        opacity: 0.6;
        cursor: default;
      }

      div.popup-search-filter-form-button-add:not(.popup-search-filter-form-button-add-disabled):hover {
        background-color: #707070;
      }
    }

    div.popup-search-filter-form-custom-field-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      background-color: #F3F7FF;
      min-height: 40px;
      width: 100%;
      margin-top: 10px;
      border-radius: 5px;
      max-height: 255px;
      overflow: auto;
      scrollbar-width: thin;

      span.popup-search-filter-form-no-items {
        text-align: center;
        font-size: 15px;
        color: #717171;
        padding: 5px;
      }

      div.popup-search-filter-form-custom-field-item {
        div.popup-search-filter-form-custom-field-item-header {
          display: flex;
          align-items: center;
          gap: 5px;

          span.popup-search-filter-form-custom-field-item-label {
            font-size: 15px;
          }

          svg {
            width: 18px;
            min-width: 18px;
            color: #707070;
          }
        }

        div.popup-search-filter-form-custom-field-item-input {
          display: flex;
          align-items: center;
          gap: 10px;

          span.popup-search-filter-form-custom-field-item-delete {
            background-color: #fff;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            border: 1px solid #ced4da;
            cursor: pointer;
            transition: 0.3s;
            
            svg {
              color: #de2450;
              width: 16px;
              min-width: 16px;
            }
          }

          span.popup-search-filter-form-custom-field-item-delete:hover {
            background-color: #efefef;
          }
        }
      }
    }

    div.popup-search-filter-form-item {
      display: flex;
      flex-direction: column;
      width: 100%;

      span.popup-search-filter-form-item-label {
        color: #707070;
        font-size: 15px;
      }
    }

    div.popup-search-filter-form-item.filter-form-item-responsive {
      flex-grow: 1;
      flex-basis: 225px;
    }

  }

  div.popup-search-filter-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 10px;

    button {
      display: flex;
      align-items: center;
      background-color: #4a69bd;
      border: none;
      color: #fff;
      height: 35px;
      border-radius: 5px;
      font-size: 15px;
      padding-left: 10px;
      padding-right: 10px;
      transition: 0.3s;
      cursor: pointer;
    }

    button.popup-search-filter-button-clear {
      background-color: #d8d8d8;
      color: #000;
    }

    button:not(:disabled):hover {
      background-color: #2c4ba1;
    }

    button.popup-search-filter-button-clear:hover {
      background-color: #b3b3b3;
    }

    button:disabled {
      opacity: 0.5;
    }
  }
}
