div.search-component-2 {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 350px;
  // max-width: 480px;
  max-width: 510px;

  div.search-component-input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    svg.search-component-icon-label,
    svg.search-component-icon-clear,
    svg.search-component-icon-popup {
      position: absolute;
      flex-shrink: 0;
    }

    svg.search-component-icon-label {
      left: 5px;
      color: #c2c2c2;
    }

    svg.search-component-icon-clear {
      right: 5px;
      width: 18px;
      height: 24px;
      color: #DE2450;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 5px;
      background-color: #f5f5f5;
    }

    svg.search-component-icon-clear.search-component-icon-clear-with-filter {
      right: 34px;
    }

    svg.search-component-icon-clear:hover {
      color: #bc0e36;
    }

    svg.search-component-icon-popup {
      right: 5px;
      color: #707070;
      cursor: pointer;
      border-radius: 5px;
      padding: 2px;
      width: 26px;
      height: 26px;
      transition: 0.3s;
    }

    svg.search-component-icon-popup.search-component-icon-popup-active {
      background-color: #d7d7d7;
    }

    svg.search-component-icon-popup.search-component-filtered {
      background-color: #4a69bd;
      color: #fff;
    }

    svg.search-component-icon-popup:not(.search-component-icon-popup.search-component-filtered):hover {
      background-color: #d7d7d7;
    }

    svg.search-component-icon-popup.search-component-filtered:hover {
      background-color: #3754a7;
    }

    input.search-component-input-item,
    div.search-component-input-fake-div {
      flex: 1;
      width: 100%;
      min-width: 0;
      box-sizing: border-box;
      height: 35px;
      background-color: #f5f5f5;
      outline: none;
      border: none;
      border-radius: 5px;
      padding: 3px 25px 3px 35px;
      color: #707070;
    }

    input.search-component-input-item.search-component-input-item-with-filter {
      padding: 3px 35px 3px 35px;
    }

    div.search-component-input-fake-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;

      span.search-component-input-fake-placeholder {
        color: #707070;
      }
    }

    div.search-component-input-fake-div:hover,
    div.search-component-input-fake-div.search-component-icon-popup-active {
      background-color: #e6e6e6;
    }
  }

  div.search-component-input.search-component-input-fake {
    svg.search-component-icon-clear {
      background-color: unset;
    }
  }
}