div.toolbar-file-uploader-component {
  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: 1fr auto;

  height: 100%;

  div.toolbar-file-uploader-drop-area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 2px dashed #4a69bd;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 4px;
    color: #4a69bd;

    span.toolbar-file-uploader-drop-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      
      span.toolbar-file-uploader-limit {
        font-size: 14px;
        background-color: #ececec;
        padding: 2px 4px;
        border-radius: 5px;
        color: #818181;
      }
    }
  }

  div.toolbar-file-uploader-list-files {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-right: 4px;
    background-color: #ececec;
    border-radius: 5px;
    padding: 6px;
    padding-right: 8px;
    width: 205px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

    div.toolbar-file-uploader-list-item-error {
      color: #f36a6b;

      svg.toolbar-file-uploader-icon-status {
        color: #f36a6b !important;
        stroke: #f36a6b !important;
      }

      span.toolbar-file-uploader-list-item-extension {
        background-color: #ffcdcd !important;
        color: #f36a6b !important;
      }
    }

    div.toolbar-file-uploader-list-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 5px;
      padding: 4px 2px;
      font-size: 14px;
      width: fit-content;

      svg.toolbar-file-uploader-icon-type {
        height: 20px;
        width: 20px;
        min-width: 20px;
      }

      svg.toolbar-file-uploader-icon-upload,
      svg.toolbar-file-uploader-icon-status {
        height: 18px;
        width: 18px;
        min-width: 18px;
        color: #4a69bd;
        stroke: #4a69bd;
        margin-left: 3px;
      }

      svg.toolbar-file-uploader-icon-delete {
        height: 18px;
        width: 18px;
        min-width: 18px;
        color: #f36a6b;
      }

      span.toolbar-file-uploader-list-item-name {
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        max-width: 85px;
      }

      span.toolbar-file-uploader-list-item-extension {
        background-color: #cfdcff;
        color: #4a69bd;
        padding: 0px 3px;
        font-size: 11px;
        border-radius: 5px;
        margin-left: 2px;
      }

      .customIconUploadArrow {
        animation: moveArrow 1.5s infinite;
      }

      @keyframes moveArrow {
        0% { transform: translateY(0); }
        50% { transform: translateY(-3px); }
        100% { transform: translateY(0); }
      }
    }

    div.toolbar-file-uploader-list-item:hover {
      background-color: #dedede;
    }

    div.toolbar-file-uploader-list-item:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

div#popover-toolbar-file-uploader-details {
  div.popover-body {
    display: flex;
    width: 100%;
    padding: 0;

    div.popover-toolbar-file-uploader-preview {
      svg {
        height: 40px;
        width: 40px;
        min-width: 40px;
      }
    }

    div.popover-toolbar-file-uploader-info {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      width: 100%;
      
      span.popover-toolbar-file-uploader-info-item {
        display: flex;
        flex-direction: column;
        padding: 5px 10px;

        span.popover-toolbar-file-uploader-info-label {
          font-weight: 500;
        }

        span.popover-toolbar-file-uploader-info-value {
          line-height: 18px;
          font-size: 14px;
        }
      }

      span.popover-toolbar-file-uploader-info-item.popover-toolbar-image-preview {
        margin-bottom: 5px;
        
        span.popover-toolbar-file-uploader-info-value {
          width: 100px;
          height: 100px;
          min-width: 100%;
          max-width: 100px;
          overflow: hidden;
          border-radius: 5px;
          margin-top: 5px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      > span:not(:last-child) {
        border-bottom: 1px solid #ececec;
      }
    }
  }
}