.dashboardButton { 
  background-color: #FFFFFF; 
  border-radius: 10px; 
  height: 10.6vh;
  cursor: pointer; 
  transition: 0.3s;
}

.dashboardButton:hover {
  background: rgb(206, 226, 255);
  transition: 0.3s;
}
