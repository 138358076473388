.TicketsRowCollumsReport {
  font: normal bold normal min(max(10px, calc(0.2em + 0.7vw)), 19px) 'Montserrat' !important;
  color: #000 !important;
}

.TicketsRowHeightReport {
  /* height: 4.5vh !important; */
}

div.logoMessageTrigger2 {
  overflow: hidden;

  img {
    width: 220px;
    transition: 0.3s;
    position: absolute;
    margin-left: 50vh;
    margin-top: -4vh;
  }
}