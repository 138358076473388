.modal-register-summary {

  .textFontGray {
    text-align: left;
    font: normal normal normal calc(0.2em + 0.7vw) Montserrat;
    letter-spacing: 0px;
    color: #1E1E1E;
    opacity: 1;
    margin-bottom: 1.2vh;
  }

  .textBlack {
    text-align: left;
    font: normal normal bold calc(0.5em + 0.7vw) Montserrat;
    letter-spacing: 0px;
    color: #1E1E1E;
    opacity: 1;
  }

  .react-datepicker-popper {
    position: fixed !important;

  }

  .calendar {
    margin-right: calc(var(--bs-gutter-x)* .5);
    width: 100% !important;
    height: 5.6vh !important;
  }

  .rmsc .dropdown-content .panel-content .options{ /* ajustando tamanho do componente de seleção */
    max-height: 120px;
  }

  .filter {
    /* .react-datepicker-wrapper, */
    .date-wrapper {
      grid-column: span 2;
      width: 100%;

      .invalid {
        color: #dc3848;
      }
    }

    .infoMessage {
      margin-top: 40px;
      font: normal normal normal calc(0.2em + 0.7vw) Montserrat;
      color:#707070;
    }

    .react-datepicker-wrapper {
      width: inherit;
    }

    .react-datepicker__triangle {
      left: -70px !important;
    }

  }

  .modal-footer {
    border-top: unset;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    width: 100%;
  }

  .ticket_export-date-input {
    //position: relative;
    width: 100%;

    input[type="text"] {
      width: 100%;
      //font-size: 12px;
      font: var(--px15_19-font-Roboto) !important;
      border: 2px solid #d9d9d9;
      border-style: solid;
      border-width: 1px;
      border-radius: 0.3rem;
      outline: none;
      box-sizing: border-box;
      transition: 0.3s;
      height: 5.6vh;
      padding-left: 10px;
    }

    input[type="text"]::placeholder {
      color: #bababa;
    }

    .tabler-icon {
      position: absolute;
      right: 10px;
      top: 1.2vh;
      transition: 0.3s;
      color: #6e6e6e;
    }
  }

  .react-datepicker__day--outside-month {
    color: #707070; /* Cor mais clara para os dias do mês anterior */
  }  
}

@media only screen and (max-width: 550px) {
  div.modal-ticket-export {
    max-width: unset !important;
    
    .lista {
      max-height: 90%;
    }
  }

  div.modal-content {
    div.modal-body {
      max-height: unset;
    }
  }

}