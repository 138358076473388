.homeControlPanel {
  div.switch-input {
    margin-bottom: 5px;

    span {
      padding-right: 10px;
      font-size: 18px;
      font-weight: 500;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
      margin-bottom: 0;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    .switch.disabled {
      opacity: 0.6;
    }

    .switch:hover {
      cursor: pointer;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #4a69bd;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #4a69bd;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
