.phoneCallcenter {
  z-index: 20;
  color: #000;
  background: #fff;
  height: fit-content;
  min-width: 310px;
  position: absolute;
  right: 0px;
  // bottom: 30px;
  top: 45px;
  border-radius: 5px;
  box-shadow: 0px 1px 8px -2px rgb(0, 0, 0, 0.44);
  font-family: 'Montserrat';
  overflow: hidden;
}

.phoneContainer {
  animation: fade-in 0.2s ease backwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
