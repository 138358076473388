.automaticDistributionTab {
  div.radio-list {
    display: flex;
    flex-direction: column;

    label.radio-item:first-child {
      margin-bottom: 10px;
    }

    label.radio-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg.radio-icon {
        margin-right: 6px;
        color: #4a69bd;
      }
      input[type="radio"] {
        display: none;
      }
    }   
  }
}