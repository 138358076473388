div.details-ticket-edit-component {
  display: grid;
  min-height: 0;
  min-width: 0;
  height: calc(100vh - 132px - 53px);// calcular dinamicamente: altura do mainHEader e altura do header de detalhes
  grid-template-rows: auto 1fr auto;
  padding: 15px;
  position: relative;

  div.details-ticket-edit-component-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    user-select: none;

    div.details-ticket-edit-component-header-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      span.details-ticket-edit-component-header-option {
        font-size: 12px;
        background-color: #ececec;
        padding: 3px 6px;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
        text-transform: lowercase;
  
        span.details-ticket-mandatory-field {
          margin-left: 2px;
          color: #f36a6b;
          font-weight: bold;
        }
      }
  
      span.details-ticket-edit-component-header-option:not(.details-ticket-edit-component-header-active):hover {
        background-color: #d8d8d8;
      }
  
      span.details-ticket-edit-component-header-option.details-ticket-edit-component-header-active {
        background-color: #4a69bd;
        color: #fff;
  
        span.details-ticket-mandatory-field {
          color: #fff ;
        }
      }
  
      span.details-ticket-edit-component-header-option.details-ticket-edit-component-header-active.details-ticket-edit-header-invalid {
        background-color: #f36a6b;
        color: #fff;
      }
  
      span.details-ticket-edit-component-header-option.details-ticket-edit-header-invalid:not(.details-ticket-edit-component-header-active) {
        color: #f36a6b;
      }
    }

    span.details-ticket-edit-component-invalid {
      margin-top: 10px;
      font-size: 12px;
      color: #f36a6b;
      cursor: default;
      font-weight: 600;
      text-align: center;
    }
  }

  div.details-ticket-edit-form-items {
    display: flex;
    flex-direction: column;
    overflow: auto;

    span.details-ticket-edit-form-empty {
      background-color: #eef3ff;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px 5px;
      border-radius: 5px;
    }

    div.details-ticket-edit-item:not(:last-child) {
      margin-bottom: 8px;
    }
  
    div.details-ticket-edit-item {
  
      div.details-ticket-edit-item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        margin-bottom: 2px;
        user-select: none;

        div.details-ticket-edit-label {
          display: flex;
          align-items: center;

          span.details-ticket-edit-item-label-value {
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            font-weight: 500;
            color: #717171;
          }

          span.details-ticket-edit-item-label-required {
            font-weight: 600;
            color: #f36a6b;
          }
        }

        span.details-ticket-edit-item-label {
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          font-weight: 500;
          color: #616161;
        }
  
        span.details-ticket-edit-item-action {
          font-size: 13px;
          background: #eef3ff;
          color: #4a69bd;
          font-weight: 500;
          border-radius: 5px;
          padding: 0 6px;
          cursor: pointer;
          transition: 0.3s;
        }
  
        span.details-ticket-edit-item-action:hover {
          background: #d2e0fe;
          transition: 0.3s;
        }
      }
  
    }
  }

  div.details-ticket-edit-form-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;

    button {
      background-color: #4a69bd;
      color: #fff;
      outline: none;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
    }

    button:disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

}

div.details-ticket-edit-component.details-ticket-edit-scroll {
  padding: 15px 5px 15px 15px;

  div.details-ticket-edit-component-header {
    margin-right: 10px;
  }

  div.details-ticket-edit-form-items {
    padding-right: 10px;
    height: unset;
  }

  div.details-ticket-edit-form-action {
    margin-right: 10px;
  }
}