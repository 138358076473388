.body-phoneDialer {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  width: min(max(240px, 36vw), 690px);
  height: min(max(240px, 50vh), 506px);
}

@media only screen and (max-height: 770px) {
  .body-phoneDialer {
    height: min(max(240px, 65vh), 506px);
  }
}