div.status-agent-component {
  display: flex;
  margin-left: 5px;

  svg.status-agent-online {
    color: #11c544;
  }

  svg.status-agent-away {
    color: #ffc755;
  }

  svg.status-agent-offline {
    color: #d7252b;
  }

  span.status-agent-component-trigger {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    height: 35px;
    padding-right: 10px;
    padding-left: 3px;
    font-size: 15px;
    max-width: 160px;
    white-space: nowrap;

    svg {
      padding: 5px;
      stroke-width: 1.5;
      height: 22px;
      width: 22px;
      min-width: 22px;
    }

    span.status-agent-component-value {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  span.status-agent-component-trigger.status-agent-component-master {
    background-color: #e1eaff;
    color: #4a69bd;
    font-weight: 600;
    border: 2px solid #4a69bd;
  }

  span.status-agent-component-trigger:hover,
  span.status-agent-component-trigger.status-agent-component-popup-opened {
    background-color: #dddddd;
    transition: 0.3s;
  }

  div.status-agent-component-popup {
    position: absolute;
    right: 0;
    top: 45px;
    opacity: 1;
    background: #fff;
    box-shadow: 0px 1px 8px -2px rgb(0, 0, 0, 0.44);
    border-radius: 5px;
    padding: 10px;
    width: 225px;
    min-width: 225px;
    cursor: default;

    display: flex;
    flex-direction: column;
    z-index: 10;

    div.status-agent-component-popup-header {
      display: flex;
      align-items: center;

      svg {
        color: #4a69bd;
        min-width: 40px;
        width: 40px;
        height: 40px;
        stroke-width: 1.3;
        margin-right: 3px;
        user-select: none;
      }

      div.status-agent-component-popup-header-info {
        display: flex;
        flex-direction: column;
        line-height: 1.3;
        overflow: hidden;

        span.status-agent-component-popup-header-info-name {
          font-size: 14px;
          font-weight: 500;
          color: #0C2461;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span.status-agent-component-popup-header-info-email {
          font-size: 12px;
          font-weight: 400;
          color: #919191;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    div.status-agent-component-popup-action {
      display: flex;
      flex-direction: column;
      background-color: #f6f6f6;
      border-radius: 5px;
      margin-top: 5px;
      padding: 5px;

      svg {
        padding: 5px;
        stroke-width: 1.5;
        height: 22px;
        width: 22px;
        min-width: 22px;
      }

      span.status-agent-component-popup-option:not(:last-child) {
        margin-bottom: 4px;
      }

      span.status-agent-component-popup-option {
        display: flex;
        align-items: center;
        padding: 3px;
        cursor: pointer;
        border-radius: 5px;
        transition: 0.3s;
        font-size: 15px;
      }

      span.status-agent-component-popup-option:hover,
      span.status-agent-option-active {
        background-color: #dddddd;
      }
    }

    div.status-agent-component-popup-action-flag {
      display: flex;
      flex-direction: column;
      background-color: #EEF3FF;
      border-radius: 5px;
      margin-top: 8px;

      img {
        padding: 5px;
        stroke-width: 1.5;
        height: 31px;
        width: 31px;
        min-width: 31px;
      }

      span.status-agent-component-popup-option {
        display: flex;
        align-items: center;
        padding: 3px;
        cursor: pointer;
        border-radius: 5px;
        transition: 0.3s;
        font-size: 15px;
      }

      span.status-agent-component-popup-option:hover,
      span.status-agent-option-active {
        background-color: #dddddd;
      }

      span.status-agent-flag-language {
        font-weight: 600;
        color: #4a69bd;
        padding: 3px;
      }
      span.status-agent-component-popup-action-text {
        margin-left: 5px;
      }

      &:hover {
        background-color: #D6E1FD;
        cursor: pointer;
      }
    }
  }
}