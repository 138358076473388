div.modal-create-ticket-component {
  div.modal-content {
    overflow: unset;

    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 6px;

        span.modal-create-ticket-header-unsaved-changes {
          font-size: 12px;
          font-weight: 400;
          color: #4a69bd;
          cursor: default;
        }
      }
      button.btn-close {
        margin-top: -15px;
        transition: 0.3s;
      }

      div.modal-create-ticket-header-actions {
        display: flex;
        gap: 6px;

        svg {
          cursor: pointer;
          color: #707070;
        }

        svg:not(.modal-create-ticket-header-actions-close) {
          background-color: #f3f3f3;
          border-radius: 5px;
          padding: 2px;
          transition: 0.3s;
        }

        svg:not(.modal-create-ticket-header-actions-close):hover {
          background-color: #e8e8e8;
        }
      }

      div.modal-create-ticket-header-actions.modal-create-ticket-header-disabled {
        svg.modal-create-ticket-header-actions-clear,
        svg.modal-create-ticket-header-actions-visibility {
          opacity: 0.6;
          cursor: default;
        }
      }
    }

    div.modal-body.modal-create-ticket-overflow {
      overflow: auto;
    }

    div.modal-body.modal-create-ticket-overflow-hidden {
      overflow: hidden;
    }

    div.modal-body {
      max-height: 70vh; // calc(100vh / 2);
      min-height: 100px;

      div.modal-create-ticket-wizard {
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        div.modal-wizard-step {
          background-color: #f3f3f3;
          border-radius: 5px;
          padding: 10px;
          cursor: pointer;
          transition: background-color 0.3s;

          div.modal-wizard-step-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            span.modal-wizard-step-header-title {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 6px;
              color: #4a69bd;
              font-weight: 600;
              user-select: none;

              span.modal-wizard-step-header-title-action {
                font-size: 14px;
                font-weight: 400;
                background: #4a69bd;
                color: #fff;
                border-radius: 5px;
                padding: 2px 6px;
                transition: 0.3s;
                cursor: pointer;
              }

              span.modal-wizard-step-header-title-action:hover {
                background: #314b93;
              }
            }

            svg.icon-wizard-step-ok {
              color: #4a69bd;
            }

            svg.icon-wizard-step-required {
              color: #d65c5c;
            }
          }

          div.modal-wizard-step-section {
            max-height: 0;
            overflow: hidden;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            div.modal-wizard-step-section-items-row {
              display: flex;
              gap: 10px;
            }

            div.modal-wizard-step-section-item {
              display: flex;
              flex-direction: column;
              flex: 1;

              div.modal-wizard-step-section-item-label,
              span.modal-wizard-step-section-item-label {
                font-size: 15px;
                color: #717171;
                font-weight: 600;
                user-select: none;
              }

              div.modal-wizard-step-section-item-label {
                display: flex;
                align-items: center;

                span.modal-wizard-step-section-item-label-info {
                  svg {
                    margin-left: 2px;
                    width: 18px;
                    height: 18px;
                    color: #3958ab;
                    margin-top: -1px;
                  }
                }

                span.modal-wizard-step-section-item-label-required {
                  svg {
                    margin-left: 2px;
                    width: 18px;
                    height: 18px;
                    color: #d65c5c;
                    margin-top: -1px;
                  }
                }
              }

              div.modal-wizard-step-section-item-label-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                span.modal-wizard-step-section-item-label-header-title {
                  display: flex;
                  align-items: center;
                  font-size: 15px;
                  color: #717171;
                  font-weight: 600;
                  user-select: none;

                  span.modal-wizard-step-section-item-label-info {
                    svg {
                      margin-left: 2px;
                      width: 18px;
                      height: 18px;
                      color: #3958ab;
                      margin-top: -1px;
                    }
                  }
                }

                span.modal-wizard-step-section-item-label-header-action {
                  display: flex;
                  font-size: 12px;
                  background-color: #707070;
                  color: #fff;
                  border-radius: 5px;
                  padding: 0px 4px;
                  font-weight: 600;
                  cursor: pointer;
                  transition: 0.3s;
                }

                span.modal-wizard-step-section-item-label-header-action:hover {
                  background-color: #5b5b5b;
                }
              }
            }
          }
        }

        div.modal-wizard-step:hover {
          background-color: #e8e8e8;
          transition: background-color 0.3s;
        }

        div.modal-wizard-step.modal-wizard-step-section-opened {
          background-color: #eef3ff;
          cursor: default;

          div.modal-wizard-step-section {
            max-height: 500px;
            // transition: max-height 1.5s;
            margin-top: 10px;
            overflow: unset;

            padding: 8px;
            background-color: #e6ecfb;
          }
        }

        div.modal-wizard-step.modal-wizard-step-section-filled {
          background-color: #4a69bd;

          div.modal-wizard-step-header {
            span.modal-wizard-step-header-title {
              color: #fff;

              span.modal-wizard-step-header-title-action {
                background: #fff;
                color: #4a69bd;
              }
            }
  
            svg.icon-wizard-step-ok {
              color: #fff;
            }
          }

          div.modal-wizard-step-section {
            background-color: #3958ab;

            div.modal-wizard-step-section-item {
              span.modal-wizard-step-section-item-label {
                color: #fff !important;
              }

              div.modal-wizard-step-section-item-label {

                span.modal-wizard-step-section-item-label-value,
                span.modal-wizard-step-section-item-label-required,
                span.modal-wizard-step-section-item-label-info {
                  color: #fff;
                  svg {
                    color: #fff;
                  }
                }
              }

              div.modal-wizard-step-section-item-label-header {
                span.modal-wizard-step-section-item-label-header-title {
                  color: #fff;

                  span.modal-wizard-step-section-item-label-info {
                    svg {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }

        div.modal-wizard-step.modal-wizard-step2 {
          div.modal-wizard-step-section {
            display: flex;
            gap: 10px;
          }
        }

        div.modal-wizard-step.modal-wizard-step4.modal-wizard-step-section-opened {
          div.modal-wizard-step-section {
            // height: 200px;
            max-height: 200px;
            overflow: auto;
            scrollbar-width: thin;
            padding-right: 5px;
          }
        }

        div.modal-wizard-step.modal-wizard-step5 {
          div.modal-wizard-step-section {
            height: 200px;
          }
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 1;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-create-ticket-btn-save {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-create-ticket-btn-save:not(:disabled):hover {
        background-color: #334f9d;
      }

      button.modal-create-ticket-btn-save:disabled {
        opacity: 0.4;
        cursor: default;
      }

      button.modal-create-ticket-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-create-ticket-btn-cancel:hover {
        background-color: #e8e8e8;
      }
    }
  }
}

div.modal-create-ticket-component.modal-create-ticket-fullscreen {
  max-width: unset !important;
  
  div.modal-content {
    div.modal-body {
      max-height: unset;
    }
  }
}

div.modal-create-ticket-component.modal-create-ticket-component-loading {
  div.modal-content {
    div.modal-header,
    div.modal-footer {
      z-index: 15;
    }
  }
}

@media only screen and (min-width: 992px) {
  div.modal-create-ticket-component {
    max-width: 55vw !important;
  }
}

@media only screen and (max-width: 1030px) {
  div.modal-create-ticket-component {

    div.modal-content {
      div.modal-body {
        div.modal-create-ticket-wizard {
          div.modal-wizard-step.modal-wizard-step3 {
            div.modal-wizard-step-section {
              div.modal-wizard-step-section-items-row {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  div.modal-create-ticket-component {
    max-width: unset !important;

    div.modal-content {
      div.modal-header {
        div.modal-create-ticket-header-actions {
          svg.modal-create-ticket-header-actions-fullscreen {
            display: none;
          }
        }
      }
      div.modal-body {
        max-height: unset;

        div.modal-create-ticket-wizard {
          div.modal-wizard-step.modal-wizard-step3 {
            div.modal-wizard-step-section {
              div.modal-wizard-step-section-items-row {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}