div.modal-ticket-logs-component {
  div.modal-content {

    div.modal-header {

      div.modal-title {
        span {
          margin-left: 6px;
          font-weight: 300;
        }
      }
      
      button.btn-close {
        margin-top: -15px;
        transition: 0.3s;
      }
    }

    div.modal-body {
      padding-top: 0;
      max-height: calc(100vh / 2);
      overflow: auto;

      div.modal-ticket-logs-loader {
        min-height: 85px;
      }

      div.modal-ticket-logs-list {
        display: flex;
        flex-direction: column;

        span.modal-ticket-logs-list-empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          color: #4a69bd;
          margin-top: 5px;
          width: fit-content;
          padding: 4px 10px;
          padding-left: 6px;
          border-radius: 5px;
          cursor: default;
          width: 100%;
          margin-top: 7px;
          margin-bottom: 6px;

          svg {
            height: 40px;
            width: 40px;
            stroke-width: 1.3;
          }
        }

        div.modal-ticket-logs-list-item:not(:last-child) {
          // margin-bottom: 10px;
          border-bottom: 1px solid #ececec;
        }

        div.modal-ticket-logs-list-item {
          display: flex;
          cursor: default;
          padding: 5px;
          transition: 0.3s;

          span.modal-ticket-logs-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #4a69bd;
            height: 30px;
            width: 30px;
            min-width: 30px;
            border-radius: 5px;
            margin-right: 10px;

            svg {
              width: 25px;
              height: 25px;
              min-width: 25px;
              color: #fff;
              stroke-width: 1.3;
            }
          }

          span.modal-ticket-logs-icon.modal-ticket-logs-icon-add {
            background-color: #4a69bd;
          }
          span.modal-ticket-logs-icon.modal-ticket-logs-icon-remove {
            background-color: #bd4a4a;
          }
          span.modal-ticket-logs-icon.modal-ticket-logs-icon-replace {
            background-color: #898989;
          }

          div.modal-ticket-logs-list-item-content {
            display: flex;
            flex-direction: column;
            margin-top: -1px;

            span.modal-ticket-logs-list-item-content-header {
              display: flex;
  
              span.modal-ticket-logs-list-agent {
                color: #0C2461;
                font-size: 15px;
                font-weight: 600;
              }
            }

            span.modal-ticket-logs-list-item-content-footer {
              display: flex;
              flex-direction: column;

              span.modal-ticket-logs-list-item-message {
                font-size: 14px;
  
                span.info-history-message-value-old,
                span.info-history-message-value-new {
                  border-radius: 5px;
                  padding: 2px 4px;
                  font-size: 13px;
                  margin-left: 4px;
                  margin-right: 4px;
                }
  
                span.info-history-message-field {
                  font-weight: 600;
                }
  
                span.info-history-message-value-old {
                  background-color: #dbdbdb;
                  font-weight: 500;
                }
  
                span.info-history-message-value-new {
                  background: #d3ddf6;
                  color: #0C2461;
                  font-weight: 500;
                }
              }

              span.modal-ticket-logs-list-item-date {
                font-size: 12px;
                color: #919191;
                padding-left: 2px;
              }
            }
          }
        }

        div.modal-ticket-logs-list-item:hover {
          background-color: #fafafa;
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: flex-end;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 1;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-ticket-logs-btn-save {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-ticket-logs-btn-save:hover {
        background-color: #334f9d;
      }

      button.modal-ticket-logs-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-ticket-logs-btn-cancel:hover {
        background-color: #e8e8e8;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  div.modal-ticket-logs-component {
    max-width: unset !important;

    div.modal-content {
      div.modal-body {
        max-height: unset;
      }
    }
  }
}