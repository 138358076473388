.modalSelectLanguage {
  div.btnLanguage {

    span.btnFlag {
      img {
        cursor: pointer;
        width: 1.5em;
        height: 1.5em;
      }
    }

    span.btnFlagLogged {
      img {
        cursor: pointer;
        width: 1.8em;
        height: 1.8em;
      }
    }
  }
}

div.bs-modalSelectLanguage {
  form.setLanguageForm {
    position: relative;

    div.language-list {
      display: flex;
      flex-wrap: wrap;

      div.language-item {
        display: flex;
        align-items: center;
        width: calc((100%/2) - 10px);

        padding: 10px 10px;
        margin-bottom: 5px;
        margin-right: 10px;
        border-radius: 5px;
        transition: 0.3s;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }

        svg.icon-check {
          margin-left: 5px;
          width: 20px;
        }
      }

      div.language-item:hover {
        background-color: #ededee;
        cursor: pointer;
        transition: 0.3s;
      }

      div.language-item.active {
        background-color: #e5ecff;
        color: #1b7ce7;
      }
    }

    @media only screen and (max-width: 450px) {
      div.language-list {
        flex-direction: column;

        div.language-item {
          width: 100%;
        }
      }
    }
  }
}