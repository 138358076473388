.departmentDropdownMultiSelect {
    cursor: pointer !important;
    background: var(--white-color) 0% 0% no-repeat padding-box !important;
    color: #6A6A6A !important;
    width: 100% !important;
    height: min(max(35px, 4.7vh), 70px) !important;
    text-align: left !important;
    border-radius: 0.3rem !important;
}

.rmsc {
    font: var(--px15_19-font-Roboto) !important;
    color: #1E1E1E !important;
    --rmsc-main: #86b7fe !important;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #ced4da !important;
    --rmsc-gray: #BCBCBC !important;
    --rmsc-bg: var(--white-color) 0% 0% no-repeat padding-box !important;
    --rmsc-p: 10px;
    --rmsc-radius: 0.3rem !important;
    --rmsc-h: 5.6vh !important;
    --rmsc-w: 100% !important;
}

.dropdown-heading-dropdown-arrow {
    color:#4a69bd !important;
}

.departmentDropdownMultiSelect .dropdown-container, .departmentDropdownMultiSelect .dropdown-container .dropdown-heading {
    height: 5.6vh !important;
}

.departmentDropdownMultiSelect:focus {
    box-shadow: none !important;
}

.dateInputCalendar {
    background: var(--white-color) 0% 0% no-repeat padding-box !important;
    border: none !important;
    font: var(--px15_19-font-Roboto) !important;
    color: #BCBCBC !important;
    width: 100% !important;
    /* height: min(max(35px, 4.7vh), 70px); */
    text-align: left !important;
    /* border-radius: 0.3rem !important; */
    text-indent: 3%;
}

.dateInputCalendarActive {
    background: var(--white-color) 0% 0% no-repeat padding-box !important;
    font: var(--px15_19-font-Roboto) !important;
    color: #6A6A6A !important;
    border: none !important;
    width: 100% !important;
    /* height: min(max(35px, 4.7vh), 70px); */
    text-align: left !important;
    /* border-radius: 0.3rem !important; */
    text-indent: 3%;
}

.dateInputCalendar::placeholder {
    color: #BCBCBC !important;
}

.none {
    display: none;
}