div.list-sectors-component {
  width: 100%;
  display: grid;
  min-height: 0;
  min-width: 0;
  grid-template-rows: auto 1fr;

  div.list-sectors-page-content {
    display: grid;
    min-height: 0;
    min-width: 0;
    padding: 0px 14px 14px 14px;
  }
}