.controlPanelAccounts {
  * {
    font-family: Montserrat;
  }
  div.listEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 56vh;

    span {
      color: #0C2461;
      font: normal normal bold calc(0.2em + 0.7vw) Montserrat;
    }
  }

  .listItem {
    transition: 0.3s;

    span.itemValue {
      cursor: default;
    }

    .permissionsIcons {
      svg:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .listItem:hover {
    background-color: #fafafa;
    transition: 0.3s;
  }
}