.phoneCallcenterLogin {
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;

  span.phoneCallcenterLogin-close {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
  }

  span.loginInfo {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  div.formInfo {
    div.item {
      margin-bottom: 20px;

      div.input {
        position: relative;
        span.inputIconEye {
          position: absolute;
          right: 10px;
          bottom: 10px;
          cursor: pointer;
        }
      }

    }
  }
}