.scene-instructions {
  height: 361px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .scene-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .scene-list-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    color: #0c2461;

    span.empty-text {
      margin-top: 10px;
    }
  }

  div.search-instruction {
    margin-bottom: 10px;
  }

  .topics {
    display: flex;
    flex-direction: column;
    padding: 2px 5px;
    overflow: auto;

    div.topic-item:not(:last-child) {
      margin-bottom: 10px;
    }

    div.topic-item {
      background: #f9f9f9;
      border-radius: 4px;
      padding: 7px;
      cursor: default;

      div.topic-header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ececec;
        padding-bottom: 4px;
        margin-bottom: 5px;
  
        span.topic-title {
          font-size: 15px;
          margin-left: 5px;
          font-weight: 600;
        }
      }

      div.topic-content {
        p {
          font-size: 13px;
          margin: 0;
          line-height: 16px;

          strong {
            font-weight: 600;
            color: #4a69bd;
          }
        }
      }
    }

  }
}