.control-panel-sidebar {
  background-color: #19243E;
  height: 100vh;
  width: 290px;
  transition: 0.3s;
  user-select: none;

  .header {
    display: flex;
    position: relative;
    padding: 20px 10px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 10px -5px #000000b3;

    div.logo {
      overflow: hidden;

      img {
        width: 140px;
        transition: 0.3s;
      }
    }

    svg.showHideIcon {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  ul.menu {
    list-style: none;
    padding: 0px;
    margin: 0px;
    max-height: calc(100% - 240px);
    transition: 0.3s;

    li {
      cursor: pointer;
      transition: 0.3s;
      margin: 5px;
      padding: 8px;
      border-radius: 5px;

      div.menu-item {
        display: flex;
        align-items: center;
        color: #859ABB;
        transition: 0.3s;

        svg.menu-icon {
          margin-left: 3px;
          margin-right: 10px;
          transition: 0.3s;
        }

        span.menu-name {
          transition: 0.3s;
        }
      }
    }

    li:hover {
      background-color: #1C2D54;
      transition: 0.3s;
    }

    li.active {
      background-color: #1C2D54;

      div.menu-item {
        font-weight: 500;
        color: #6D8DDF;

        svg {
          stroke-width: 2;
        }
      }
    }
  }
}

.closed {
  width: 65px;
  transition: 0.3s;

  .header {
    div.logo {
      img {
        width: 0px;
        transition: 0.3s;
      }
    }

    svg.showHideIcon {
      margin-left: 10px;
    }
  }

  ul.menu {
    li {
      div.menu-item {
        // justify-content: center;
        transition: 0.3s;

        svg.menu-icon {
          margin: 0;
          transition: 0.3s;
          display: flex;
          justify-content: center;
          width: 100%;
        }

        span.menu-name {
          display: none;
          transition: 0.3s;
        }
      }
    }
  }
}
