div.dash-counters-component {
  display: flex;
  align-items: center;
  padding: 0px 14px 0px 14px;
  font-family: Montserrat, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  div.dash-counter-item:not(:last-child) {
    margin-right: 10px;
  }

  div.dash-counter-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 180px;
    height: 70px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 8px 10px;
    transition: 0.3s;

    div.dash-counter-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span.dash-counter-value {
        color: #4a69bd;
        font-weight: bold;
        font-size: 20px;
      }

      svg {
        color: #4a69bd;
      }
    }

    div.dash-counter-body {
      display: flex;

      span.dash-counter-label {
        color: #0C2461;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  div.dash-counter-item-disabled {
    opacity: 0.5;
    cursor: default;
  }

  div.dash-counter-item-active {
    background-color: #eef3ff;
    cursor: pointer;
  }

  div.dash-counter-item:not(.dash-counter-item-disabled):hover {
    cursor: pointer;
    // box-shadow: 0px 1px 6px -3px rgba(0, 0, 0, 0.7);
    background-color: #eef3ff;
    transition: 0.3s;
  }
}

div.dash-counters-component.dash-counters-disabled {
  div.dash-counter-item {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 655px) {
  div.dash-counters-component {
    flex-direction: column;
    width: 100%;

    div.dash-counter-item {
      width: 100%;
      margin-right: unset !important;
      min-width: unset;
    }

    div.dash-counter-item:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}