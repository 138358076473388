div.modal-active-trigger-report-component {
  div.modal-content {
    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 8px;

        span {
          font-weight: bold;
        }
      }

      div.modal-active-trigger-report-header-actions {
        display: flex;
        gap: 6px;

        span.modal-active-trigger-report-header-generating {
          white-space: nowrap;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #4a69bd;
          font-weight: 600;
          gap: 5px;
          background-color: aliceblue;
          border-radius: 5px;
          padding-left: 5px;
          padding-right: 5px;
        }

        span.modal-active-trigger-report-header-actions-download-btn {
          display: flex;
          align-items: center;
          font-size: 15px;
          background-color: #707070;
          color: #fff;
          border-radius: 5px;
          padding: 0px 8px;
          cursor: pointer;
          transition: 0.3s;
          text-wrap: nowrap;
          gap: 5px;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        span.modal-active-trigger-report-header-actions-download-btn:hover {
          background-color: #606060;
        }

        span.span-btn-disabled {
          opacity: 0.6;
        }

        svg.modal-active-trigger-report-header-actions-close {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }

        svg.modal-active-trigger-report-header-actions-close:hover {
          color: #606060;
        }
      }
    }

    div.modal-body {
      user-select: none;
      min-height: 300px;
      max-height: 580px;
      overflow-y: auto;

      div.modal-active-trigger-report-info {
        display: flex;
        flex-direction: column;
        gap: 15px;

        div.modal-active-trigger-report-info-fields {
          display: flex;
          flex-direction: column;
          gap: 5px;
          overflow: auto;
          scrollbar-width: thin;

          div.modal-active-trigger-report-info-fields-row {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            div.modal-active-trigger-report-info-fields-item {
              text-overflow: ellipsis;
              text-wrap: nowrap;
              // max-width: calc(100% / 2);
              overflow: hidden;
              flex-grow: 1;
              flex-basis: 200px;

              span.modal-active-trigger-report-info-fields-item-value {
                text-overflow: ellipsis;
                text-wrap: nowrap;
                overflow: hidden;
              }
            }
          }

          div.modal-active-trigger-report-info-fields-item {
            display: flex;
            flex-direction: column;
            width: 100%;

            span.modal-active-trigger-report-info-fields-item-label {
              font-size: 15px;
              font-weight: 500;
              color: #707070;
            }

            span.modal-active-trigger-report-info-fields-item-value {
              font-size: 15px;
              background-color: #ececec;
              padding: 10px 10px;
              border-radius: 5px;

              svg {
                margin-right: 5px;
              }
            }

            span.modal-active-trigger-report-info-fields-item-value.info-fields-item-overflow {
              max-height: 90px;
              overflow: auto;
              scrollbar-width: thin;
            }
          }
        }

        div.modal-active-trigger-report-info-counters {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          flex-wrap: wrap;
          padding: 10px;
          background-color: #ececec;
          border-radius: 5px;

          div.modal-active-trigger-report-info-counters-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 70px;
            border: 1px solid #f4f4f4;
            background-color: #fff;
            border-radius: 5px;
            padding: 8px 10px;
            box-shadow: 2px 2px 3px -2px rgb(0 0 0 / 30%);
            flex-grow: 1;
            flex-basis: 130px;

            div.modal-active-trigger-report-info-counters-item-header {
              display: flex;
              align-items: center;
              justify-content: space-between;

              span.modal-active-trigger-report-info-counters-item-value {
                color: #4a69bd;
                font-weight: bold;
                font-size: 20px;
              }

              svg {
                color: #4a69bd;
              }
            }

            div.modal-active-trigger-report-info-counters-item-body {
              display: flex;

              span.modal-active-trigger-report-info-counters-item-label {
                color: #0C2461;
                font-weight: 600;
                font-size: 14px;
              }
            }
          }
        }
      }

      div.modal-body-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 265px;

        div.modal-body-loading-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          svg {
            height: 55px;
            width: 55px;
          }
        }
  
        div.modal-body-loading-info.modal-body-loading-error {
          svg {
            color: #f36a6b;
            stroke: #f36a6b;
          }
  
          span.modal-body-loading-info-message {
            color: #f36a6b;
          }
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 1;
      flex-wrap: nowrap;
      justify-content: flex-end;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-active-trigger-report-btn-close {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-active-trigger-report-btn-close:hover {
        background-color: #e8e8e8;
      }
    }
  }
}
