.modalEditUserPermissions {
  .modal-header {
    span {
      text-align: left;
      font: normal normal bold calc(0.5em + 0.7vw) Montserrat;
      letter-spacing: 0px;
      color: #1E1E1E;
      opacity: 1;
    }
  }

  .modal-body {
    span.userInfo {
      width: 100%;
      font-weight: 500;
      display: flex;
      border-bottom: 1px solid #ececec;
      padding-bottom: 10px;
    }

    div.state-base {
      padding-bottom: 5px !important;

      div.check-item {
        margin-bottom: 0px;
      }
    }

    div.state-permissions {
      border-top: 1px solid #ececec;
      padding-top: 10px !important;
    }

    form {
      margin-top: 10px;
      div.check-item {
        display: flex;
        margin-bottom: 10px;

        div.check-item-icon {
          margin-right: 10px;
        }

        div.switch-input {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          span.check-item-label {
            font-weight: 400;
          }
        }

      }
    }
  }
}