div.modal-tag-component {
  div.modal-content {
    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      button.btn-close {
        margin-top: -15px;
        transition: 0.3s;
      }

      div.modal-tag-header-actions {
        display: flex;
        gap: 6px;

        svg {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }

        svg:hover {
          color: #606060;
        }
      }
    }

    div.modal-body {
      user-select: none;
      min-height: 86px;

      div.modal-tag-section.section-margin-top {
        margin-top: 20px;
      }

      div.modal-tag-section {
        display: flex;
        flex-direction: column;
        gap: 5px;

        div.modal-tag-item {
          width: 100%;
          position: relative;

          div.modal-tag-item-header {
            user-select: none;

            span.modal-tag-item-label {
              font-size: 15px;
              font-weight: 600;
              color: #717171;
            }

            span.modal-tag-item-label-required {
              color: #f36a6b;
              font-weight: 500;
            }
          }

          span.modal-tag-item-invalid-message {
            position: absolute;
            font-size: 12px;
            color: #f36a6b;
            right: 0;
            font-weight: 600;
          }
        }

        div.modal-tag-section-status {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: #f1f1f1;
          border-radius: 5px;
          padding: 5px 10px;

          div.modal-tag-section-status-info {
            display: flex;
            flex-direction: column;
            cursor: default;

            span.modal-tag-section-status-title {
              font-weight: 600;
            }
            span.modal-tag-section-status-message {
              font-size: 14px;
              color: #707070;
            }
          }

          div.modal-tag-section-status-switch {
            display: flex;
            align-items: center;
            min-width: 50px;
            justify-content: flex-end;
          }
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 1;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-tag-btn-save {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-tag-btn-save:not(:disabled):hover {
        background-color: #3856a7;
      }

      button.modal-tag-btn-save:disabled {
        opacity: 0.4;
        cursor: default;
      }

      button.modal-tag-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-tag-btn-cancel:hover {
        background-color: #e8e8e8;
      }
    }
  }
}
