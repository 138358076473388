div.modal-consumer-component {
  div.modal-content {

    div.modal-header {
      user-select: none;
      z-index: 11;
      box-shadow: 0px 0px 12px -8px;

      div.modal-consumer-header-actions {
        display: flex;
        align-items: center;
        gap: 6px;

        div.modal-consumer-header-actions-tabs-select {
          display: flex;
          align-items: center;
          gap: 5px;

          span.modal-consumer-tab-item {
            background-color: #ECECEC;
            border-radius: 5px;
            padding: 0px 6px;
            font-size: 15px;
            cursor: pointer;
            transition: 0.3s;
          }

          span.modal-consumer-tab-item:not(.modal-consumer-tab-item-selected):hover {
            background-color: #dedede;
          }

          span.modal-consumer-tab-item-selected {
            background-color: #4A69BD;
            color: #fff;
            cursor: default;
          }
        }

        svg.modal-consumer-header-actions-close {
          cursor: pointer;
          color: #707070;
        }
      }
    }

    div.modal-body {
      display: flex;
      flex-direction: column;
      max-height: calc(75vh);
      min-height: 200px;
      overflow: auto;

      div.modal-consumer-section-margin-top-10 {
        margin-top: 10px;
      }

      div.modal-consumer-section:not(:last-child) {
        border-bottom: 1px solid #ececec;
        padding-bottom: 15px;
      }

      div.modal-consumer-section-custom-fields {
        span.modal-consumer-section-title {
          cursor: pointer;
        }
      }

      div.modal-consumer-section {

        div.modal-consumer-items-group:not(:last-child) {
          margin-bottom: 5px;

          div.modal-consumer-item {
            margin-bottom: 0;
          }
        }

        div.modal-consumer-items-group {
          display: flex;
          justify-content: space-between;

          div.modal-consumer-item:first-child {
            margin-right: 5px;
          }

          div.modal-consumer-item:last-child {
            margin-left: 5px;
          }
        }

        div.modal-consumer-item {
          margin-bottom: 5px;
        }

        div.modal-consumer-item {
          width: 100%;
          position: relative;

          div.modal-consumer-item-header {
            user-select: none;

            span.modal-consumer-item-label {
              font-size: 15px;
              font-weight: 600;
              color: #717171;
            }

            span.modal-consumer-item-label-required {
              color: #f36a6b;
              font-weight: 500;
            }
          }

          span.modal-consumer-item-invalid-message {
            position: absolute;
            font-size: 12px;
            color: #f36a6b;
            right: 0;
            font-weight: 600;
          }
        }

        span.modal-consumer-section-title {
          display: flex;
          align-items: center;
          font-weight: 600;
          user-select: none;
          margin-bottom: 5px;
          width: fit-content;

          svg {
            margin-left: 5px;
            transition: 0.3s;
          }

          svg.modal-consumer-import-download-icon {
            color: #4a69bd;
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }

        span.modal-consumer-section-title.modal-consumer-show-custom-fields {
          svg {
            transform: scaleY(-1);
          }
        }

        div.modal-consumer-custom-fields {
          display: flex;
          flex-direction: column;
          background-color: #f3f7ff;
          margin-top: 10px;
          padding: 2px 8px;
          border-radius: 5px;

          span.modal-consumer-custom-fields-empty {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #f36a6b;
            margin-top: 5px;
            background-color: #f1f1f1;
            width: fit-content;
            padding: 4px 10px;
            padding-left: 6px;
            border-radius: 5px;
            cursor: default;

            svg {
              height: 20px;
              width: 20px;
              margin-right: 5px;
            }
          }
        }

        div.modal-consumer-import-area {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 150px;
          margin-top: 15px;
          width: 100%;
          border: 2px dashed #A5A5A5;
          border-radius: 5px;
          cursor: pointer;
          color: #A5A5A5;

          span.modal-consumer-import-area-message {
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
              color: #4a69bd;
            }
          }
        }

        div.modal-consumer-import-area.modal-consumer-import-selected {
          cursor: default;
          user-select: none;

          span.modal-consumer-import-area-selected-file {
            display: flex;
            align-items: center;
            background-color: #ececec;
            border-radius: 5px;
            padding: 3px 6px;
            color: #707070;
            gap: 5px;
            line-break: anywhere;
            transition: 0.3s;

            svg.modal-consumer-import-delete-file {
              color: #f36a6b;
              width: 20px;
              height: 20px;
              cursor: pointer;
              transition: 0.3s;
            }

            svg.modal-consumer-import-delete-file:hover {
              color: #db5151;
            }
          }

          span.modal-consumer-import-area-selected-file:hover {
            background-color: #dbdbdb;
          }
        }

        div.modal-consumer-section-duplicated {
          display: flex;
          flex-direction: column;

          span.modal-consumer-section-message-title {
            font-size: 17px;
            font-weight: 600;
            user-select: none;
          }

          span.modal-consumer-section-message {
            font-size: 15px;
            font-weight: 600;
            user-select: none;
          }

          span.modal-consumer-section-message-alert {
            font-size: 14px;
            background-color: #ffe6cca6;
            border-radius: 5px;
            padding: 6px 10px;
            font-weight: 500;
            user-select: none;
            margin-top: 10px;
          }

          div.modal-consumer-section-duplicated-item {
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            padding: 8px 10px;
            color: #4a69bd;

            span.modal-consumer-section-duplicated-item-label {
              font-weight: 600;
              font-size: 15px;
            }

            div.modal-consumer-section-duplicated-item-info {
              display: flex;
              flex-direction: column;
              background: #eef3ff;
              border-radius: 5px;
              cursor: pointer;
              transition: 0.3s;

              div.modal-consumer-section-duplicated-item-info-main {
                display: flex;
                align-items: center;
                padding: 5px 5px;

                svg {
                  stroke-width: 1.3;
                  height: 40px;
                  margin-right: 3px;
                  min-width: 40px;
                  -webkit-user-select: none;
                  user-select: none;
                  width: 40px;
                }

                div.modal-consumer-section-duplicated-item-info-main-details {
                  display: flex;
                  flex-direction: column;
                  line-height: 1.3;

                  span.modal-consumer-section-duplicated-item-info-main-details-name {
                    font-weight: 500;
                    font-size: 14px;
                  }

                  span.modal-consumer-section-duplicated-item-info-main-details-email {
                    font-size: 12px;
                    font-weight: 400;
                  }
                }
              }
              
              div.modal-consumer-section-duplicated-item-info-secondary {
                display: flex;
                gap: 5px;
                justify-content: space-between;

                div.duplicated-info-secondary-item {
                  display: flex;
                  flex-direction: column;
                  width: calc(100% / 4);
                  
                  span.duplicated-info-secondary-item-label {
                    font-size: 14px;
                    font-weight: 500;
                  }

                  span.duplicated-info-secondary-item-value {
                    background-color: #ececec;
                    color: #717171;
                    width: 100%;
                    border-radius: 5px;
                    padding: 6px 10px;
                    font-size: 15px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
              }

              div.modal-consumer-section-duplicated-item-info-secondary.show-info-secondary {
                display: flex;
                height: fit-content;
                background-color: #fff;
                margin: 0px 8px 8px 8px;
                padding: 8px;
                border-radius: 5px;
              }

              div.modal-consumer-section-duplicated-item-info-secondary.hidden-info-secondary {
                height: 0px;
                overflow: hidden;
              }
            }

            div.modal-consumer-section-duplicated-item-info:hover {
              background: #e2e8f6;
            }
          }

          div.modal-consumer-section-duplicated-list {
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            padding: 8px 10px;

            span.modal-consumer-section-duplicated-list-label {
              font-weight: 600;
              font-size: 15px;
              color: #919191;
            }

            div.modal-consumer-section-duplicated-list-container {
              display: flex;
              flex-direction: column;
              gap: 5px;
              // max-height: 121px;
              // overflow-y: auto;
              // scrollbar-width: thin;

              div.modal-consumer-section-duplicated-item {
                padding: 0;

                div.modal-consumer-section-duplicated-item-info {
                  background: #f8f8f8;
                  color: #919191;
                }

                div.modal-consumer-section-duplicated-item-info:hover {
                  background: #e7e7e7;
                }
              }
            }
          }
        }

        div.modal-consumer-section-final-unification {
          display: flex;
          flex-direction: column;
          
          div.modal-consumer-section-final-unification-form {
            display: flex;
            flex-direction: column;
            gap: 10px;

            div.modal-consumer-section-final-unification-form-section {
              display: flex;
              flex-direction: column;
              gap: 10px;

              span.modal-consumer-section-final-unification-form-section-label {
                font-weight: 600;
                font-size: 15px;
                color: #4a69bd;
                user-select: none;
              }

              div.modal-consumer-section-final-unification-form-group {
                display: flex;
                gap: 10px;

                div.modal-consumer-section-final-unification-form-item {
                  max-width: calc(100% / 2);
                }
              }

              div.modal-consumer-section-final-unification-form-item {
                display: flex;
                flex-direction: column;
                cursor: default;
                width: 100%;

                span.modal-consumer-section-final-unification-form-item-label {
                  font-size: 14px;
                  font-weight: 500;
                }

                span.modal-consumer-section-final-unification-form-item-value {
                  display: flex;
                  align-items: center;
                  padding: 8px;
                  font-size: 14px;
                  background-color: #ececec;
                  border-radius: 5px;
                }
              }
            }

            div.modal-consumer-section-final-unification-form-section:not(:last-child) {
              border-bottom: 1px solid #ececec;
              padding-bottom: 15px;
            }
          }
        }
      }

      div.modal-consumer-unification-analysis-header {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        span.modal-consumer-section-message {
          font-size: 15px;
          font-weight: 600;
          user-select: none;
        }
      }

      div.modal-consumer-unification-analysis {
        display: flex;
        height: 100%;

        div.modal-consumer-unification-analysis-fields {
          display: flex;
          flex-direction: column;
          user-select: none;
          min-width: 230px;
          box-shadow: 9px 0px 12px -12px #0000004a;
          z-index: 1;

          span.modal-consumer-unification-analysis-fields-label {
            font-weight: 600;
            margin-bottom: 5px;
            height: 30px;
          }

          div.modal-consumer-unification-analysis-fields-values {
            display: flex;
            flex-direction: column;
            background-color: #f2f5ff;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 6px 12px;
            padding-right: 10px;
            height: 100%;

            span.modal-consumer-unification-analysis-fields-values-separator {
              color: #4a69bd;
              border-bottom: 1px solid #d1d1d1;
              margin-top: 6px;
              margin-bottom: 5px;
              font-size: 14px;
            }

            span.modal-consumer-unification-analysis-fields-values-item {
              // width: fit-content;
              font-size: 15px;
              font-weight: 500;
              color: #0c2461;
              padding: 2px 8px;
              margin-bottom: 3px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              transition: 0.3s;
            }

            span.modal-consumer-unification-analysis-fields-values-item.fields-values-item-hovered {
              background-color: #fff;
              border-radius: 5px;
            }
          }
        }

        div.modal-consumer-unification-analysis-data {
          display: flex;
          border: 1px solid #e5ecff;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          gap: 15px;
          padding: 0px 10px 10px 10px;
          margin-top: -1px;
          width: 100%;
          overflow-x: auto;
          box-shadow: inset 5px -3px 7px -7px #0000009e;

          div.modal-consumer-unification-analysis-data-item {
            display: flex;
            flex-direction: column;
            min-width: 250px;
            // margin-bottom: 5px;

            span.modal-consumer-unification-analysis-data-item-name {
              font-weight: 600;
              margin-top: 5px;
              margin-bottom: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              user-select: none;

              span {
                padding: 0px 8px;
                transition: 0.3s;
                border-radius: 5px;
              }

              span:hover {
                background-color: #f3f6ff;
                cursor: pointer;
              }
            }

            div.modal-consumer-unification-analysis-data-item-values {
              display: flex;
              flex-direction: column;
              padding: 6px 12px;
              padding-left: 6px;
              padding-bottom: 0;
              background-color: #e5ecff;
              border-radius: 5px;

              span.modal-consumer-unification-analysis-data-item-values-separator {
                margin-top: 6px;
                margin-bottom: 5px;
                font-size: 14px;
                height: 22px;
              }

              span.modal-consumer-unification-analysis-data-item-values-field-value {
                font-size: 15px;
                font-weight: 500;
                padding: 2px 8px;
                margin-bottom: 3px;
                overflow: hidden;
                text-overflow: ellipsis;
                background-color: #fff;
                border-radius: 5px;
              }

              div.modal-consumer-unification-analysis-data-item-values-field-value {
                display: flex;
                align-items: center;
                gap: 3px;
                margin-bottom: 3px;
                cursor: pointer;

                svg {
                  min-width: 22px;
                  width: 22px;
                  height: 22px;
                  color: #4a69bd;
                }

                span.modal-consumer-unification-analysis-data-item-values-field-value-content {
                  width: 100%;
                  font-size: 15px;
                  font-weight: 500;
                  background-color: #fff;
                  border-radius: 5px;
                  padding: 2px 8px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              div.modal-consumer-unification-analysis-data-item-values-field-value:last-child {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    div.modal-body.modal-body-loading {
      display: flex;
      align-items: center;
      justify-content: center;

      div.modal-body-loading-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          color: #4a69bd;
          stroke: #4a69bd;
          stroke-width: 1;
          height: 80px;
          width: 80px;
        }
      
        span.modal-body-loading-info-message {
          color: #4a69bd;
          font-family: Montserrat;
          text-align: center;
          cursor: default;
        }
      }
    }

    div.modal-footer {
      border-top: unset;
      justify-content: space-between;
      box-shadow: 0px -3px 17px -13px #0000006e;
      z-index: 11;

      button {
        border-radius: 5px;
        padding: 5px 10px;
        transition: 0.3s;
        cursor: pointer;
        min-width: 100px;
      }

      button.modal-consumer-btn-save {
        background-color: #4a69bd;
        color: #fff;
        border: none;
      }

      button.modal-consumer-btn-save:not(:disabled):hover {
        background-color: #334f9d;
      }

      button.modal-consumer-btn-cancel {
        background-color: #fff;
        color: #4a69bd;
        border: 1px solid #4a69bd;
      }

      button.modal-consumer-btn-cancel:hover {
        background-color: #e8e8e8;
      }

      button:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
  }
}

div.popover-custom-action-menu.popover-file-model-file-consumers {
  margin-left: 10px;
}

@media only screen and (max-width: 575px) {
  div.modal-consumer-component {
    max-width: unset !important;

    div.modal-content {
      div.modal-body {
        max-height: unset;
      }
    }
  }
}