div.modal-agent-consumers-component {
  div.modal-content {
    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 8px;

        span {
          font-weight: bold;
        }
      }

      div.modal-agent-consumers-header-actions {
        display: flex;
        gap: 6px;

        svg.modal-agent-consumers-header-actions-close {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }

        svg.modal-agent-consumers-header-actions-close:hover {
          color: #606060;
        }
      }
    }

    div.modal-body {
      user-select: none;
      min-height: 300px;
      max-height: 550px;
      overflow-y: auto;
      
      div.modal-agent-consumers-content {
        display: flex;
        flex-direction: column;
        position: relative;

        div.modal-agent-consumers-unlink-confirmation {
          position: absolute;
          background-color: #fff;
          width: 100%;
          height: 100%;
          border: 1px solid #ececec;
          border-radius: 5px;
          display: grid;
          grid-template-rows: auto 1fr auto;
          padding: 10px;

          span.modal-agent-consumers-unlink-title {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 10px;
          }
          div.modal-agent-consumers-unlink-info {
            display: flex;
            flex-direction: column;

            span.modal-agent-consumers-unlink-info-message {
              font-weight: 500;
              margin-bottom: 10px;

              span {
                font-weight: bold;
              }
            }

            div.modal-agent-consumers-unlink-info-fields {
              display: flex;
              flex-direction: column;
              gap: 5px;
              overflow: auto;
              scrollbar-width: thin;
              padding: 5px;

              div.modal-agent-consumers-unlink-info-fields-row {
                display: flex;
                gap: 10px;

                div.modal-agent-consumers-unlink-info-fields-item {
                  text-overflow: ellipsis;
                  text-wrap: nowrap;
                  max-width: calc(100% / 2);
                  overflow: hidden;

                  span.modal-agent-consumers-unlink-info-fields-item-value {
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                    overflow: hidden;
                  }
                }

              }

              div.modal-agent-consumers-unlink-info-fields-item {
                display: flex;
                flex-direction: column;
                width: 100%;

                span.modal-agent-consumers-unlink-info-fields-item-label {
                  font-size: 15px;
                  font-weight: 500;
                  color: #707070;
                }

                span.modal-agent-consumers-unlink-info-fields-item-value {
                  font-size: 15px;
                  background-color: #ececec;
                  padding: 10px 10px;
                  border-radius: 5px;
                  height: 40px;
                }

                span.modal-agent-consumers-unlink-info-fields-item-value.info-fields-item-overflow {
                  max-height: 90px;
                  overflow: auto;
                  scrollbar-width: thin;
                }
              }
            }
          }

          div.modal-list-task-unlink-footer {
            display: flex;
            gap: 5px;
            justify-content: flex-end;

            span.modal-agent-consumers-unlink-action {
              border-radius: 5px;
              cursor: pointer;
              transition: 0.3s;
              font-size: 16px;
              padding: 2px 8px;
            }

            span.modal-agent-consumers-unlink-action.tasks-unlink-action-cancel {
              background-color: #909090;
              color: #fff;
            }

            span.modal-agent-consumers-unlink-action.tasks-unlink-action-cancel:hover {
              background-color: #808080;
            }

            span.modal-agent-consumers-unlink-action.tasks-unlink-action-confirm {
              background-color: #d24646;
              color: #fff;
            }

            span.modal-agent-consumers-unlink-action.tasks-unlink-action-confirm:hover {
              background-color: #be3636;
            }
          }
        }
      }
    }
  }
}
