div.view-ticket-content {
  // background: #ececec;
  display: flex;
  flex-direction: row;
  width: 100%;
  // margin-right: 10px;
  position: relative;

  border: 1px solid #ececec;
  border-radius: 5px;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  overflow: hidden;

  div.ticket-chat {
    // background: #8fd4ff;
    display: flex;
    flex-direction: column;
    // height: 100%;
    min-width: 72%;
    transition: 0.3s;
    box-shadow: 3px -1px 10px -8px rgba(0, 0, 0, 0.7);

    div.ticket-chat-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #eef3ff;
      padding: 5px 5px 5px 5px;
      box-shadow: 0px 3px 9px -5px rgba(0, 0, 0, 0.4);
      height: 40px;
      z-index: 1;

      div.ticket-chat-header-info {
        display: flex;
        align-items: center;

        span:not(:last-child) {
          margin-right: 10px;
        }

        span.ticket-chat-header-reference-id {
          color: #0C2461;
          font-weight: 600;
          font-size: 15px;
        }

        span.ticket-chat-header-requester {
          font-size: 13px;
          background: #707070;
          color: #fff;
          padding: 0px 5px;
          border-radius: 5px;
          font-weight: 500;
          transition: 0.3s;

          user-select: none;
        }

        span.ticket-chat-header-requester.ticket-chat-header-requester-deleted {
          background-color: #d24646;
          cursor: default;
        }

        span.ticket-chat-header-requester:not(.ticket-chat-header-requester-deleted):not(.ticket-chat-header-requester-webchat):hover {
          background: #3b3b3b;
          transition: 0.3s;
          cursor: pointer;
        }

        span.ticket-chat-header-badge-status {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          cursor: default;
  
          color: #fff;
          border-radius: 5px;
          font-size: 13px;
          padding: 0px 5px;

          user-select: none;
  
          span {
            font-weight: bold;
          }
        }

        span.ticket-chat-header-channel {
          display: flex;
          align-items: center;
        }
      }

      span.ticket-chat-header-action {
        display: flex;
        background: #cfdcff;
        border-radius: 4px;
        cursor: pointer;
        padding: 3px;
        transition: 0.3s;

        svg {
          height: 22px;
          width: 22px;
          stroke-width: 1.5;
          color: #4a69bd;
        }
      }

      span.ticket-chat-header-action:hover {
        background: #bcccf7;
        transition: 0.3s;
      }

      div.ticket-chat-header-actions {
        display: flex;

        span.ticket-chat-header-action:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    div.ticket-chat-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      // max-height: calc(100vh - 230px); // Tornar isso aqui dinâmico, pegando a altura do header da página
      //min-height: calc(100vh - 230px); // Tornar isso aqui dinâmico, pegando a altura do header da página

      div.ticket-chat-transition {
        transition: 0.3s;
      }

      div.ticket-chat-editor-container {
        // background: #a4dc9c;
        min-height: 40px;
        display: flex;
        flex-direction: column;
        position: relative;
        box-shadow: 0px -3px 9px -8px rgba(0, 0, 0, 0.7);

        div.ticket-chat-divider-cursor:not(.divider-no-resize) {
          cursor: row-resize;
        }

        div.ticket-chat-divider-resize.divider-no-resize {
          display: none;
        }

        div.ticket-chat-divider-resize {
          width: 100%;
          height: 6px;
          background: transparent;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;
          transition: 0.3s;
          position: absolute;
          top: -3px;
          z-index: 1;

          span.hide-chat-btn {
            display: none;
            position: absolute;
            background: #fff;
            border: 1px solid #ececec;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.3s;
            height: 20px;
            width: 20px;
            justify-content: center;
            align-items: center;
            
            svg {
              transition: 0.3s;
              color: #909090;
              stroke: #909090;
            }

            svg.icon-open-chat {
              transform: rotate(180deg);
              transition: 0.3s;
            }
          }

          span.hide-chat-btn:hover {
            box-shadow: 0px 0px 15px -4px;
            transition: 0.3s;
          }
        }

        div.ticket-chat-divider-resize:hover {
          background: #909090;
          transition: 0.3s;

          span.hide-chat-btn {
            display: flex;
            transition: 0.3s;
          }
        }

        div.ticket-chat-editor {
          width: 100%;
          transition: 0.3s;
          height: 100%;
        }

        div.ticket-chat-start {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          cursor: pointer;
          transition: 0.3s;
        }

        div.ticket-chat-start:hover {
          backdrop-filter: brightness(0.9);
        }

        div.ticket-chat-blocked {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          cursor: default;
          background-color: #919191;
          
          span {
            color: #fff;
            
            span {
              font-weight: 600;
            }

            span.ticket-chat-badge-status {
              text-transform: lowercase;
              border-radius: 5px;
              padding: 3px 6px;
              background-color: #707070;
              font-weight: 500;
            }
          }

          svg {
            color: #fff;
            margin-left: 5px;
            stroke-width: 1.5;
          }
        }

        div.ticket-chat-blocked.ticket-chat-deleted {
          background-color: #d36565;

          span {
            span.ticket-chat-badge-status {
              background-color: #b74545;
            }
          }
        }
      }
    }
  }

  div.ticket-chat.ticket-details-hide {
    width: 100%;
    transition: 0.3s;
  }
}

@media only screen and (max-width: 1000px) {
  div.view-ticket-content {
    flex-direction: column;

    div.ticket-chat {
      height: 70%;

      div.ticket-chat-container {
        div.ticket-chat-editor-container {
          div.ticket-chat-divider-resize {
            display: none;
          }
          div.ticket-chat-divider-resize.divider-no-resize {
            display: flex;
          } 
        }
      }
    }

    div.ticket-chat.ticket-details-hide {
      height: calc(100% - 40px);

      div.ticket-chat-container {
        height: calc(100% - 40px);
      }
    }
  }
}

@media only screen and (max-width: 870px) {
  div.view-ticket-content {
    border-top-right-radius: unset;
    height: 100%;
  }
}

div.popover-custom-action-menu.popover-custom-action-menu-view-ticket {
  right: 5px !important;
}