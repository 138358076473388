.newPasswordControlPanel {
  font-family: Montserrat;

  .body-content {
    background-color: #E5ECFF;
    height: 90.5vh;

    .box-content {
      background-color: white; 
      border-radius: 10px; 
      padding: 10px 20px;
      padding-bottom: 20px;

      div.validateLogo {
        width: fit-content; 
        padding: 0; 
        margin-left: -2vh;

        img {
          width: 55%;
          min-width: 100px;
        }
      }

      .box-title {
        text-align: left;
        font: normal normal bold 18px/22px Montserrat;
        letter-spacing: 0px;
        color: #0C2461;
        white-space: nowrap;
      }

      .newpasswordForm {
        .form-item {
          label {
            font: normal normal normal calc(0.2em + 0.7vw) Montserrat;
            text-align: left;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;
          }

          .input-group {
            position: relative;

            div.invalid-feedback {
              position: absolute;
              bottom: -18px;
              font-size: 13px;
              padding-left: 4px;
            }

          }
        }
      }

      div.info2fa {
        padding: 0px 4px;
      }

      div.changeMethod {
        span {
          padding-left: 0px;
          font: 14px Montserrat;
          color: #0b5ed7;
          cursor: pointer;
          width: fit-content;
        }
      }

      div.confirmed {
        span.title {
          display: flex;
          justify-content: center;
          text-align: center;
          font: normal normal bold 17px/20px Montserrat;
          letter-spacing: 0px;
          color: #0C2461;
        }
      
        p.description {
          color: #707070;
          font: Montserrat;
        }
  
        button:hover {
          background-color: #c1c1c1 !important;
          transition: 0.3s;
        }
      }
    }
    
  }
  div.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      height: 5vh;
      transition: 0.3s;
    }

    button:first-child {
      margin-right: 10px;
    }

    button:last-child {
      margin-left: 10px;
    }

    button.buttonBlue:hover {
      background-color: #355098;
      transition: 0.3s;
    }

    button.buttonFirstAccess:hover {
      background-color: #c1c1c1 !important;
      transition: 0.3s;
    }
  }

  span.showHidePassword {
    position: absolute;
    background-color: transparent;
    border: none;
    height: 100%;
    right: 15px;
    display: flex;
    align-items: center;
    z-index: 10;

    svg {
      cursor: pointer;
    }
  }

  .footer-content {
    background-color: #FFFFFF;
    height: 9.5vh;

    p.footer-text {
      span {
        text-align: left;
        font: normal normal normal 15px/19px Montserrat;
        letter-spacing: 0px;
        color: #707070;
      }

      span:first-child {
        font-weight: bold;
      }
    }
  }
}
