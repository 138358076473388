.custom-select {
  // --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.custom-span {
  display: block;
  // width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  appearance: none;
  background-color: #f8f9fa;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-top: 5px;
}

.custom-label {
  text-align: left;
  font: calc(0.2em + 0.7vw) Montserrat;
  letter-spacing: 0px;
  color: rgb(112, 112, 112);
  opacity: 1;
}