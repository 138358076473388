.container-modal {
    background-color: rgba(127,127,127,0.5);
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;

    div.container-body-modal {
        background-color: #f7f7f7;
        height: 470px;
        width: 545px;
        border-radius: 5px;
        padding-right: 10px;

        div.container-header-modal {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 20px;
            padding-right: 20px;
    
            div.container-header-title {
                font-family: 'Montserrat';
                font-size: 1.4rem;
                font-weight: 700;
                color: #1E1E1E;
                opacity: 1;

                div.container-header-subtitle {
                    color: #707070;
                    font-family: 'Roboto';
                    font-size: 0.8rem;
                    font-weight: 100;
                }
            }

            div.container-header-close-button {
                color: #909090;
                cursor: pointer;
            }

            div.container-header-close-button:hover {
                color: #404040;
                cursor: pointer;
            }
        }

        div.container-day-of-week-row {
            padding-left: 20px;
            height: 35px;
            display: flex;
            align-items: center;
            margin-top: 10px;

            div.container-day {
                background-color: #707070;
                width: 110px;
                height: 40px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fefefe;
                font-weight: 700;
                font-family: 'Montserrat';
            }
        }

        div.container-button-row {
            height: 45px;
            display: flex;
            align-items: center;
            padding-left: 20px;
            margin-top: 15px;

            div.container-button-row-title {
                font-family: "Montserrat";
                font-weight: 600;
                font-size: 1.2rem;
            }

            div.container-button-row-add {
                height: 6vh;
                width: 100%;
                // padding-left: 5px;
                display: flex;
                align-items: center;

                button.container-button-add {
                    background-color: #afd1ff;
                    height: 40px;
                    width: 40px;
                    border-radius: 5px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #1b3470;
                    border: none;
                }

                button.container-button-add:hover {
                    background-color: #84b3f2;
                    height: 40px;
                    width: 40px;
                    border-radius: 5px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #1b3470;
                    transition: 0.3s;
                    border: none;
                }

                button.container-button-add-block {
                    background-color: #A7C7E7;
                    height: 40px;
                    width: 40px;
                    border-radius: 5px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #1b3470;
                    border: none;
                }
            }
        }

        div.container-list-schedules {
            align-items: center;
            justify-content: center;
            padding-left: 20px;
            overflow-x: hidden;
            overflow-y: auto;
            // height: 173px;
            max-height: 173px;
            min-height: 173px;
            margin-top: 15px;
        }

        div.container-row-buttons-modal {
            margin-top: 15px;
            padding-left: 8px;
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            div.container-cancel {
                width: 100%;
            }

            div.container-save {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }

            button.container-button {
                border-radius: 4px;
                border: 1px solid var(--main-color);
                opacity: 1;
                width: 50%;
                text-decoration: none;
                height: min(max(35px, 4.7vh), 70px);
                font: var(--px15_19-font);
            }

            button.cancel {
                background-color: var(--white-color);
                color: var(--main-color);
            }

            button.cancel:hover {
                background-color: #d8e2ff;
                color: #637ec9;
                transition: 0.5s;
            }

            button.save {
                background-color: #4a69bd;
                color: #dce2f0;
            }

            button.save:hover {
                background-color: #2c4ca2;
                color: #dce2f0;
                transition: 0.5s;
            }
        }

        div.container-wanings{
            display: flex;
            justify-content: center;
            align-items: center;

            div.container-warning {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #eb5555;
                width: 100%;
                height: 23px;
                margin-top: 10px;
            }

            div.container-warning-none {
                color: transparent;
                height: 23px;
                width: 100%;
                margin-top: 10px;
            }
        }

    }
    

}