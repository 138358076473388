// @import url('https://fonts.googleapis.com/css?family=Montserrat:normal,bold');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto:normal,bold');

:root {
  --main-color: #4A69BD;
  --blue-color: #0C2461;
  --blue2-color: #2C4CA2;
  --blue3-color: #E5ECFF;
  --white-color: #FFFFFF;
  --black-color: #000000;
  --white2-color: #F6F7F8;
  --orange-color: #FF8119;
  --gray-color: #707070;
  --gray2-color: #E4E4E4;
  --green-color: #0D9D9A;
  --green2-color: #0B8583;
  --red-color: #e81818;

  --bs-modal-width: 600px !important;


  --px14_16-font: normal normal normal min(max(14px, calc(0.2em + 0.3vw)), 16px) 'Montserrat';
  --px10_13-font: normal normal normal min(max(10px, calc(0.2em + 0.3vw)), 13px) 'Montserrat';
  --px10_13_Bold-font: normal bold normal min(max(10px, calc(0.2em + 0.3vw)), 13px) 'Montserrat';
  --px10_13-font-Roboto: normal normal normal min(max(10px, calc(0.2em + 0.3vw)), 13px) 'Roboto';
  --px10_13-Bold-font-Roboto: normal bold normal min(max(10px, calc(0.2em + 0.3vw)), 13px) 'Roboto';
  --px12-font: normal normal normal calc(0.3em + 0.5vw) 'Montserrat';
  --px14-Bold-font: normal bold normal min(max(12px, calc(0.2em + 0.80vw)), 13px) 'Montserrat';
  --px15_19-font: normal normal normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Montserrat';
  --px15_19_Bold-font: normal bold normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Montserrat';
  --px16_19-font: normal normal normal min(max(10.5px, calc(0.2em + 0.7vw)), 15.5px) 'Montserrat';
  --px16_19_Bold-font: normal bold normal min(max(10.5px, calc(0.2em + 0.7vw)), 16.5px) 'Montserrat';
  --px16_19_600-Montserrat: normal normal 600 min(max(10.5px, calc(0.2em + 0.7vw)), 16.5px) 'Montserrat';
  --px15_19-font-Roboto: normal normal normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Roboto';
  --px15_19_Bold-font-Roboto: normal bold normal min(max(10px, calc(0.2em + 0.65vw)), 15px) 'Roboto';
  --px15_20_Bold-font: normal bold normal min(max(10.5px, calc(0.2em + 0.9vw)), 20px) 'Montserrat';
  --px15_20-font: normal normal normal min(max(10.5px, calc(0.2em + 0.9vw)), 20px) 'Montserrat';
  --px15_20_Bold-font-Roboto: normal bold normal min(max(10.5px, calc(0.2em + 0.9vw)), 20px) 'Roboto';
  --px15_20-Roboto: normal normal normal min(max(10.5px, calc(0.2em + 0.9vw)), 20px) 'Roboto';
  --px20_24_Bold-font: normal bold normal min(max(15px, calc(0.2em + 1.3vw)), 30px) 'Montserrat';
  --px17_24_Bold-font: normal bold normal min(max(17px, calc(0.2em + 1.3vw)), 24px) 'Montserrat';
  --px17_24-font: normal normal normal min(max(15px, calc(0.2em + 1vw)), 23px) 'Montserrat';
  --px17_24-Roboto: normal normal normal min(max(15px, calc(0.2em + 1vw)), 23px) 'Roboto';
  --px20_24_600-font: normal normal 600 min(max(15px, calc(0.2em + 1.3vw)), 30px) 'Montserrat';
  --px20_24-font: normal normal normal min(max(15px, calc(0.2em + 1.3vw)), 30px) 'Montserrat';
  --px21_33_Bold-Montserrat: normal normal bold min(max(20px, calc(0.2em + 5vw)), 38px) 'Montserrat';
  --px21_33-Montserrat: normal normal normal min(max(18px, calc(0.2em + 4vw)), 38px) 'Montserrat';
  --px21_67_Bold-Montserrat: normal normal bold min(max(23px, calc(0.2em + 2.4vw)), 48px) 'Montserrat';
  --px14_22-Montserrat: normal normal normal min(max(14px, calc(0.2em + 1.3vw)), 22px) 'Montserrat';
  --px14_22_Bold-Montserrat: normal bold normal min(max(14px, calc(0.2em + 1.3vw)), 22px) 'Montserrat';

  --px17_32_Bold-Montserrat: normal normal bold min(max(17px, calc(0.2em + 2.9vw)), 32px) 'Montserrat';
  --px17_32-Montserrat: normal normal normal min(max(17px, calc(0.2em + 2.9vw)), 32px) 'Montserrat';
  --px16_24-font: normal normal normal min(max(17px, calc(0.2em + 1.5vw)), 27px) 'Montserrat';
  --px16_24_Bold-font: normal normal bold min(max(17px, calc(0.2em + 1.5vw)), 27px) 'Montserrat';

  --px15_20-Roboto-nav: normal normal normal min(max(10.5px, calc(0.2em + 0.6vw)), 20px) 'Roboto';
}

input:focus,
select:focus,
textarea:focus,
button:focus,
a:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;

}

.boxInputsStyles {
  background-color: var(--white-color);
  border-radius: 10px;
}

.buttonBlue {
  border-radius: 4px !important;
  background: var(--main-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(35px, 4.7vh), 70px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonRed {
  border-radius: 4px !important;
  background: var(--red-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(35px, 4.7vh), 70px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonRedOutline {
  border-radius: 4px !important;
  background: #fff;
  color: var(--red-color);
  border: 1px solid var(--red-color);
  opacity: 1;
  width: 100%;
  height: min(max(35px, 4.7vh), 70px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonFirstAccess {
  border-radius: 4px !important;
  color: #000 !important;
  background: var(--gray2-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(35px, 4.7vh), 70px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonBlueRegisterMobile {
  border-radius: 4px !important;
  background: var(--main-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(35px, 4.7vh), 70px) !important;
  border: none !important;
  font: var(--px14_22-Montserrat) !important;
}

.buttonBlue:hover,
.buttonBlueRegisterMobile:hover {
  background: var(--blue2-color) 0% 0% no-repeat padding-box !important;
}

.buttonBlue2 {
  border-radius: 4px !important;
  background: var(--main-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(20px, 2.5vw), 41px) !important;
  border: none !important;
  font: var(--px15_19_Bold-font-Roboto) !important;
}

.buttonBlue2:hover {
  background: var(--blue2-color) 0% 0% no-repeat padding-box !important;
}

.buttonBlue3 {
  border-radius: 4px !important;
  background: var(--main-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(15px, 1.8vw), 35px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonBlue3:hover {
  background: var(--blue2-color) 0% 0% no-repeat padding-box !important;
}

.buttonBlue2WithoutBold {
  border-radius: 4px !important;
  background: var(--main-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(20px, 2.5vw), 41px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonBlue2WithoutBold:hover {
  background: var(--blue2-color) 0% 0% no-repeat padding-box !important;
}

.buttonGreen {
  border-radius: 4px !important;
  background: var(--green-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: 100%;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonGreen:hover {
  background: var(--green2-color) 0% 0% no-repeat padding-box !important;
}

.buttonWhite {
  border-radius: 4px !important;
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--main-color) !important;
  opacity: 1 !important;
  width: 100%;
  text-decoration: none;
  height: min(max(35px, 4.7vh), 70px) !important;
  color: var(--main-color) !important;
  font: var(--px15_19-font) !important;
}

.buttonWhiteRed {
  border-radius: 4px !important;
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--red-color) !important;
  opacity: 1 !important;
  width: 100%;
  text-decoration: none;
  height: min(max(35px, 4.7vh), 70px) !important;
  color: var(--red-color) !important;
  font: var(--px15_19-font) !important;
}

.buttonWhiteRed:hover {
  background: #f5e1e4 0% 0% no-repeat padding-box !important;
}
.buttonWhiteRegisterMobile {
  border-radius: 4px !important;
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--main-color) !important;
  opacity: 1 !important;
  width: 100%;
  text-decoration: none;
  height: min(max(35px, 4.7vh), 70px) !important;
  color: var(--main-color) !important;
  font: var(--px14_22-Montserrat) !important;
}

.buttonWhite:hover,
.buttonWhiteRegisterMobile:hover {
  background: #D8E2FF 0% 0% no-repeat padding-box !important;
}

.buttonWhite2 {
  border-radius: 4px !important;
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--main-color) !important;
  opacity: 1 !important;
  width: 100%;
  text-decoration: none;
  height: min(max(20px, 2.5vw), 41px) !important;
  color: var(--main-color) !important;
  font: var(--px15_19-font) !important;
}

.buttonWhite2:hover {
  background: #D8E2FF 0% 0% no-repeat padding-box !important;
}

.buttonWhite3 {
  border-radius: 4px !important;
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--main-color) !important;
  opacity: 1 !important;
  width: 100%;
  text-decoration: none;
  height: min(max(15px, 1.8vw), 35px) !important;
  color: var(--main-color) !important;
  font: var(--px15_19-font) !important;
}

.buttonWhite3:hover {
  background: #D8E2FF 0% 0% no-repeat padding-box !important;
}

.buttonGrayChatbot {
  border-radius: 4px !important;
  background: var(--gray-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(35px, 4.7vh), 70px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonWhiteChatbot {
  border-radius: 4px !important;
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--main-color) !important;
  opacity: 1 !important;
  width: 100%;
  text-decoration: none;
  height: min(max(35px, 4.7vh), 70px) !important;
  color: var(--main-color) !important;
  font: var(--px15_19-font) !important;
}

.buttonGrayChatbotCopy {
  background: var(--gray-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  border: none !important;
  font: var(--px15_19-font) !important;
}

.buttonDigitPhoneNumber {
  width: 100%;
  height: 100%;
  border: none !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}

.buttonDigitPhoneNumber:hover {
  background: #F9F9F9 0% 0% no-repeat padding-box !important;
  width: 100%;
  border: none !important;
}

.form-control-Default,
.form-control-Default2 {
  height: 5vh !important;
  border-radius: 0.3rem !important;
}

.form-control-Default:not(:placeholder-shown) {
  font: var(--px15_19-font);
  color: #1E1E1E;
}

.form-control-Default2:not(:placeholder-shown) {
  font: var(--px14_16-font);
  color: #1E1E1E;
}

.form-control-Default:placeholder-shown {
  font: var(--px10_13-font);
}

.form-control-Default2:placeholder-shown {
  font: var(--px14_16-font);
}

.form-control-Default-text-area,
.form-control-Default-text-area2 {
  border-radius: 0.3rem !important;
  width: 100%;
  resize: none;
  overflow-x: hidden;

  --tag-bg: var(--blue3-color) !important;
  --tag-hover: var(--blue3-color) !important;
  --tag-pad: .50em 1em !important;
  --tag-inset-shadow-size: 10em !important;
  --placeholder-color-focus: var(--black-color) !important;
}

.form-control-Default-text-area:not(:placeholder-shown) {
  font: var(--px15_19-font);
  color: #1E1E1E;
}

.form-control-Default-text-area:placeholder-shown {
  font: var(--px10_13-font);
}

.form-control-Default-text-area2:not(:placeholder-shown) {
  font: var(--px14_16-font);
  color: #1E1E1E;
}

.form-control-Default-text-area2:placeholder-shown {
  font: var(--px14_16-font);
}

.tag-min-height {
  min-height: 10vh !important;
}

.form-control-Default-text-area2 .tagify__input {
  padding-left: 8px !important;
}


/*Só usando no registo*/

.dropdownButton {
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid #ced4da !important;
  font: normal normal normal calc(0.2em + 0.5vw) 'Montserrat' !important;
  color: rgb(112, 112, 112) !important;
  width: 100% !important;
  height: 5vh;
  text-align: left !important;
  border-radius: 0.3rem !important;
}

.dropdownButton::after {
  color: #6A6A6A !important;
  margin-left: 55% !important;
  position: absolute !important;
  right: 5% !important;
  top: 45% !important;
  border-top: 0.6em solid !important;
  border-left: 0.4em solid transparent !important;
  border-right: 0.4em solid transparent !important;
  vertical-align: 0.0em !important;
}

//dropdown do registro quando está com a borda vermelha (campo obrigatório não preenchido / erro)
.styles_dropdownButtonRedBorder__KjLaW::after {
  color: #6A6A6A !important;
  margin-left: 55% !important;
  position: absolute !important;
  right: 5% !important;
  top: 45% !important;
  border-top: 0.6em solid !important;
  border-left: 0.4em solid transparent !important;
  border-right: 0.4em solid transparent !important;
  vertical-align: 0.0em !important;
}

/*
Usando em HOME, FORGOTPASSWORD, REGISTER
*/

.buttonBlueBold {
  background: var(--main-color) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(35px, 4.7vh), 70px) !important;
  text-decoration-line: none;
  border: none !important;
  font: normal bold normal calc(0.2em + 1.5vh) 'Montserrat' !important;
}

.buttonBlueBold:hover {
  background: #2C4CA2 0% 0% no-repeat padding-box !important;
  font: normal bold normal calc(0.2em + 1.5vh) 'Montserrat' !important;
  text-decoration: none;
  border: none !important;
}

.buttonGray {
  background: #F9F9F9 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 100%;
  height: min(max(20px, 2.5vw), 41px) !important;
  font: var(--px15_19_Bold-font-Roboto) !important;
  text-decoration-line: none;
  border: 1px solid transparent !important;
  color: #AFAFAF !important;
  border-radius: 0.25rem !important;
}

.buttonGray:hover {
  background: var(--main-color) 0% 0% no-repeat padding-box !important;
  color: var(--white-color) !important;
}

.buttonWhiteBold {
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--main-color) !important;
  opacity: 1 !important;
  width: 100%;
  text-decoration: none;
  color: var(--main-color) !important;
  font: normal bold normal calc(0.2em + 1.5vh) 'Montserrat' !important;
}

.buttonWhiteBold:hover {
  background: #D8E2FF 0% 0% no-repeat padding-box !important;
  font-family: 'Montserrat';
  text-decoration: none;
}

.buttonTicketsHome {
  height: 70px;
  width: 100%;
  border-radius: 9px !important;
  background-color: var(--white-color) !important;
  border-color: #E8E8E8 !important;
}

.buttonTicketsHome:hover {
  background-color: #EEF3FF !important;
  border-color: transparent !important;
}

.buttonTicketsActive {
  height: 70px;
  width: 100%;
  border-radius: 9px !important;
  background-color: #EEF3FF !important;
  border-color: transparent !important;
}

.buttonTicketsSec {
  border-radius: 4px !important;
  background-color: #AFD1FF !important;
  border-color: transparent !important;
  color: #AFD1FF !important;
  height: min(max(20px, 2.5vw), 41px) !important;
  width: min(max(20px, 2.5vw), 41px) !important;
}

.buttonTicketsSec:hover {
  background-color: #84B3F2 !important;
  border-color: transparent !important;
}

.buttonTicketsSec2 {
  border-radius: 4px !important;
  background-color: #AFD1FF !important;
  border-color: transparent !important;
  color: var(--main-color) !important;
  font: normal bold normal min(max(10px, calc(0.2em + 1vw)), 17px) 'Montserrat' !important;
  height: min(max(35px, 5.6vh), 70px) !important;
  width: min(max(103px, 6vw), 103px) !important;
}

.buttonTicketsSec2:hover {
  background-color: #84B3F2 !important;
  border-color: transparent !important;
}

.buttonTicketsSec3 {
  border-radius: 4px !important;
  background-color: #E8E8E8 !important;
  border-color: transparent !important;
  color: var(--main-color) !important;
  font: normal bold normal calc(0.2em + 1vh) 'Montserrat' !important;
  height: min(max(20px, 2.5vw), 41px) !important;
  width: min(max(20px, 2.5vw), 41px) !important;
}

.buttonMeetCall {
  background-color: #0D9D9A !important;
  border-color: transparent !important;
  color: var(--main-color) !important;
  font: normal bold normal calc(0.2em + 1vh) 'Montserrat' !important;
  height: min(max(20px, 2.5vw), 41px) !important;
  width: min(max(20px, 2.5vw), 41px) !important;
}

.buttonTicketsSec4 {
  background-color: #E5ECFF !important;
  border-color: transparent !important;
  color: var(--main-color) !important;
  font: normal bold normal calc(0.2em + 0.8vw) 'Montserrat' !important;
  width: min(max(35px, 4.7vh), 70px) !important;
  height: min(max(35px, 4.7vh), 70px) !important;
}

.buttonTicketsSec4:hover {
  background-color: #AFD1FF !important;
  border-color: transparent !important;
}

.buttonAsText {
  text-decoration: underline !important;
  background-color: transparent !important;
  border: none !important;
  color: var(--main-color) !important;
  font: var(--px15_19-font) !important;
}

.buttonAsText:hover {
  text-decoration: underline !important;
  background-color: transparent !important;
  border: none !important;
  color: var(--main-color) !important;
  font: var(--px15_19-font) !important;
}

.buttonAsText2 {
  background-color: transparent !important;
  border: none !important;
  color: #707070 !important;
  font: var(--px15_19-font) !important;
}

.buttonAsText2:hover {
  border: none !important;
  color: #000000 !important;
}

.buttonAsText3 {
  text-decoration: underline !important;
  background-color: transparent !important;
  border: none !important;
  color: #0C2461 !important;
  font: normal bold calc(0.2em + 0.7vw) 'Montserrat' !important;
}

.buttonAsText3:hover {
  text-decoration: underline !important;
  background-color: transparent !important;
  border: none !important;
  color: #0C2461 !important;
  font: normal bold calc(0.2em + 0.7vw) 'Montserrat' !important;
}

.form-control-Sec1 {
  height: min(max(20px, 2.5vw), 41px) !important;
  color: #AFAFAF !important;
  background-color: #F9F9F9 !important;
  background-clip: padding-box;
  border: 1px solid transparent !important;
  text-indent: 30px !important;
  font: var(--px15_19-font-Roboto) !important;
  border-radius: 0.3vw !important;
}

.form-control-Sec2 {
  height: 5vh !important;
  color: #1E1E1E !important;
  background-color: #F9F9F9 !important;
  background-clip: padding-box;
  border: 1px solid #AFAFAF !important;
  font: normal normal normal calc(0.2em + 0.5vw) 'Montserrat' !important;
}

.form-select {
  height: 5vh !important;
  font-size: calc(0.2em + 0.5vw) !important;
  border: 1px solid #AFAFAF !important;
}

.input-group-text {
  font-size: calc(0.2em + 1vh) !important;
  font-family: 'Montserrat' !important;
}

.input-group>.form-control:not(:focus).is-invalid, .input-group>.form-floating:not(:focus-within).is-invalid, .input-group>.form-select:not(:focus).is-invalid, .was-validated .input-group>.form-control:not(:focus):invalid, .was-validated .input-group>.form-floating:not(:focus-within):invalid, .was-validated .input-group>.form-select:not(:focus):invalid {
  z-index: 0 !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.nopaddingWithMarginTop {
  padding: 0px !important;
  margin: -18.4vw -0.65vw 0px -0.75vw !important;
}

.nopaddingWithMarginRight {
  padding: 0px !important;
  margin: 0px 0px 0px 0.6vw !important;
}

.progress {
  background-color: #D8E2FF !important;
  width: 105%;
}

.progress-bar {
  background-color: var(--main-color) !important;
}


.dropdownButtonTickets,
.dropdownButtonTickets2 {
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid #ced4da !important;
  font: var(--px15_19-font-Roboto) !important;
  color: #BCBCBC !important;
  width: 100% !important;
  height: min(max(35px, 4.7vh), 70px);
  text-align: left !important;
  border-radius: 0.3rem !important;
}

.dropdownButtonTickets:focus,
.dropdownButtonTickets2:focus {
  box-shadow: none !important;
}

.dropdownButtonTickets::after {
  border: none !important;
  content: url(../../../public/dropdownIcon.svg) !important;
  width: 20px !important;
  position: relative !important;
  top: calc(50% - 10px);
  left: calc(93% - 10px);
}

.dropdownButtonTickets2::after {
  border: none !important;
  content: url(../../../public/dropdownIcon.svg) !important;
  width: 20px !important;
  position: relative !important;
  margin-left: 80% !important;
  margin-top: 0.5vh !important;
}


.dropdownButtonTicketsActive,
.dropdownButtonTicketsActive2 {
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid #ced4da !important;
  font: var(--px15_19-font-Roboto) !important;
  color: #6A6A6A !important;
  width: 100% !important;
  height: min(max(35px, 4.7vh), 70px);
  text-align: left !important;
  border-radius: 0.3rem !important;
  display: flex;
  align-items: center;
}

.dropdownButtonTicketsActive::after {
  content: url(../../../public/dropdownIcon.svg) !important;
  border: none !important;
  position: absolute;
  width: 20px;
  top: calc(50% - 9px);
  right: 9px;
  text-align: center;
}

.dropdownButtonTicketsActive2::after {
  border: none !important;
  content: url(../../../public/dropdownIcon.svg) !important;
  width: 20px !important;
  position: relative !important;
  margin-left: 80% !important;
  margin-top: 0.5vh !important;
  width: fit-content;
}

.dropdownButtonTicketsComunication {
  background: #E5ECFF 0% 0% no-repeat padding-box !important;
  border: none !important;
  font: var(--px15_19-font-Roboto) !important;
  color: var(--main-color) !important;
  width: 100% !important;
  text-align: left !important;
  border-radius: 0.3rem !important;
}

.dropdownButtonTicketsComunication::after {
  border: none !important;
  content: url(../../../public/dropdownIcon.svg) !important;
  width: 20px !important;
  position: relative !important;
  margin-left: 93% !important;
  transform: rotate(180deg);
}

.dropdownButtonTicketsComunicationActive {
  background: #E8E8E8 0% 0% no-repeat padding-box !important;
  border: 1px solid #ced4da !important;
  font: var(--px15_19-font-Roboto) !important;
  color: #6A6A6A !important;
  width: 15vw !important;
  text-align: left !important;
  border-radius: 0.3rem !important;
}

.dropdownButtonTicketsComunicationActive:after {
  border: none !important;
  content: url(../../../public/dropdownIcon2.svg) !important;
  width: 20px !important;
  position: relative !important;
  margin-left: 93% !important;
}

/**************************************     MULTISELECT        *********************************/
.rmsc {
  cursor: pointer !important;
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  color: #6A6A6A !important;
  width: 100% !important;
  height: 5.6vh !important;
  text-align: left !important;
  border-radius: 0.3rem !important;
  font: var(--px15_19-font-Roboto) !important;
  color: #1E1E1E !important;
  --rmsc-main: #86b7fe !important;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ced4da !important;
  --rmsc-gray: #BCBCBC !important;
  --rmsc-bg: var(--white-color) 0% 0% no-repeat padding-box !important;
  --rmsc-p: 10px;
  --rmsc-radius: 0.3rem !important;
  --rmsc-h: 5.6vh !important;
  --rmsc-w: 100% !important;
}

.rmsc .dropdown-heading {
  cursor: pointer !important;
}

.rmsc .dropdown-container,
.rmsc .dropdown-heading {
  height: 5.6vh !important;
}


/* static border in dropdown button*/
.rmsc .dropdown-container[aria-disabled="true"]:focus-within,
.rmsc .dropdown-container:focus-within {
  border: 1px solid var(--rmsc-border) !important;
  border-radius: var(--rmsc-radius) !important;
  box-shadow: none !important;
}

/* static border in dropdown button*/

.rmsc .select-item {
  /* itens padding */

  box-sizing: none !important;
  padding: 11px 16px 12px 16px !important;

  /*propriedades para fazer os 3 pontinhos funcionar nos dropdowns*/
  /*oneLineClampEllipsis*/

  text-align: left;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: clip !important;
  overflow: hidden !important;
  white-space: normal !important;
  word-break: break-word !important;

  /* disable item selected color */
  /* background-color: var(--rmsc-bg) !important; */
}

/* disable item selected color */
.rmsc .select-item:hover {
  background-color: var(--rmsc-gray) !important;
  /* background-color: var(--rmsc-selected) !important; */
}

.rmsc .item-renderer {
  padding: 0 !important;
  margin: 0 !important;
  font: var(--px15_19-font-Roboto);
  color: rgb(112, 112, 112) !important;
  display: inline-block !important;
}

.rmsc .item-renderer span {
  /* inherit oneLineClampEllipsis  */
  text-align: left !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: clip !important;
  overflow: hidden !important;
  white-space: normal !important;
}

/* hidden select box marker */
.rmsc .item-renderer input {
  display: none !important;
}

/* hidden select box */

/* border in bottom in search input */
.rmsc .search {
  border-bottom: 2px solid var(--blue3-color) !important;
}

.rmsc .search input {
  background-color: var(--white-color) 0% 0% no-repeat padding-box !important;
}

/*https://github.com/hc-oss/react-multi-select-component/blob/master/src/multi-select/arrow.tsx*/
.rmsc .dropdown-heading-dropdown-arrow {
  color: #4a69bd !important;
}

/* hidden close button (X) */
.rmsc .search-clear-button,
.rmsc .clear-selected-button {
  display: none !important;
}

/* hidden close button (X) */


/********** multiselect form inner modal *************/
.multiselectForm .dropdown-content>* {
  position: fixed !important;
  display: block !important;
  width: 449px !important;
}

.multiselectForm .dropdown-content {
  z-index: 1000 !important;
}

.multiselectForm.disabled {
  background-color: #e9ecef !important;
}

.multiselectFormStatic .dropdown-content>* {
  display: block !important;
  width: 449px !important;
}

.multiselectFormStatic .dropdown-content {
  z-index: 1000 !important;
}

.multiselectFormStatic.disabled {
  background-color: #e9ecef !important;
}

.multiselectFormError{
  border: solid red 1px;
}

.multiselectFormViewTrigger .dropdown-content .panel-content .options {
  max-height: 13vh;
}
/********** multiselect form inner modal *************/

/********** multiselect form inner modal *************/
.multiselectForm2 .dropdown-content>* {
  position: fixed !important;
  display: block !important;
  width: 23% !important;
}

.multiselectForm2 .dropdown-content {
  z-index: 1000 !important;
}

.multiselectForm2.disabled {
  background-color: #e9ecef !important;
}

/********** multiselect form inner modal *************/

/**************************************     MULTISELECT        *********************************/


.dateInputTickets {
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid #ced4da !important;
  font: var(--px15_19-font-Roboto) !important;
  color: #BCBCBC !important;
  // width: 100% !important;
  height: min(max(35px, 4.7vh), 70px);
  text-align: left !important;
  border-radius: 0.3rem !important;
  text-indent: 3%;
}

.dateInputTickets::placeholder {
  color: #BCBCBC !important;
}

.dateInputTicketsActive {
  background: var(--white-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid #ced4da !important;
  font: var(--px15_19-font-Roboto) !important;
  color: #6A6A6A !important;
  // width: 100% !important;
  height: min(max(35px, 4.7vh), 70px);
  text-align: left !important;
  border-radius: 0.3rem !important;
  text-indent: 3%;
}

.calendaryStyle {
  font: normal normal normal calc(0.2em + 0.5vw) 'Roboto' !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--main-color) !important;
}

.react-datepicker__header {
  background-color: var(--white-color) !important;
}

.dropdown-item {
  font: var(--px15_19-font-Roboto);
  color: rgb(112, 112, 112) !important;
  display: inline-block !important;
}

.dropdown-item:active {
  background-color: #6a6a6a35 !important;
}

.dropdown-item.active {
  background-color: transparent !important;
}

.TicketsRow {
  font: normal normal normal min(max(10px, calc(0.2em + 0.7vw)), 18px) 'Roboto' !important;
}

.TicketsRowHeight {
  height: 4.5vh !important;
}

.TicketsRowCollums {
  font: normal bold normal min(max(10px, calc(0.2em + 0.7vw)), 19px) 'Montserrat' !important;
  color: #0C2461 !important;
}

.LargerHeadListRowColumns {
  padding-right: 10px !important;
}

.FilterTicketsRow {
  font: normal bold normal min(max(10px, calc(0.2em + 0.7vw)), 15px) 'Montserrat' !important;
}

.iconSidebar {
  opacity: 0;
  transition: ease-in 0.3s;
}

.iconSidebarActive {
  opacity: 1;
  transition: ease-in 0.3s;
}

.MarkSidebar {
  opacity: 0;
  transition: ease-in 0.3s;
}

.MarkSidebarActive {
  opacity: 1;
  transition: ease-in 0.3s;
}

.buttonSidebarLanguage{
  margin-bottom: 1rem;
}
.buttonSidebar {
  color: black !important;
  border: none !important;
  font: var(--px15_19_Bold-font) !important;
  background-color: transparent !important;
  transition: ease-in color 0.3s;
  margin-bottom: -0.35rem;
}

.buttonSidebarActive {
  color: transparent !important;
  border: none !important;
  font: var(--px15_19_Bold-font) !important;
  background-color: transparent !important;
  transition: color 0.3s;
}

/*estilo nav-link dos canais*/
.nav-link.active {
  background-color: #E5ECFF !important;
  border-color: transparent !important;
  color: var(--main-color) !important;
  font: var(--px15_20-Roboto-nav) !important;
  width: 190px;
  height: min(max(35px, 4.7vh), 70px) !important;
  text-align: left !important;
  min-width: 10vw !important;
}

.nav-link.disabled {
  font: var(--px15_20-Roboto-nav) !important;
  color: #E8E8E8 !important;
  background-color: transparent !important;
  width: 190px;
  height: min(max(35px, 4.7vh), 70px) !important;
  text-align: left !important;
  min-width: 10vw !important;
}

.nav-link {
  background-color: transparent !important;
  border-color: #E5ECFF !important;
  border-radius: 1px !important;
  color: var(--main-color) !important;
  font: var(--px15_20-Roboto-nav) !important;
  width: 190px;
  height: min(max(35px, 4.7vh), 70px) !important;
  text-align: left !important;
  min-width: 10vw !important;
}

.--tag--max-width {
  width: 100%;
}

.--tag--min-width {
  width: 100%;
}


.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  border-color: #ced4da !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  border-radius:  var(--bs-border-radius) !important;
}

.tooltip-inner {
  background-color: #5A5A5A !important;
  // font: normal normal normal calc(0.2em + 0.5vw) 'Montserrat' !important;
  font-size: 13px !important;
  font-family: Montserrat !important;
  padding: 5px 8px !important;
}

.tooltip-arrow::before {
  --bs-tooltip-bg: #5A5A5A !important
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #5A5A5A !important;
  border-bottom-color: #5A5A5A !important;
}

.bs-tooltip-start .tooltip-arrow::before {
  border-right-color: #5A5A5A !important;
  border-left-color: #5A5A5A !important;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #5A5A5A !important;
  border-left-color: #5A5A5A !important;
}

.oneLineClampEllipsis {
  text-align: left;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: clip !important;
  overflow: hidden !important;
  white-space: normal !important;
  word-break: break-word !important;
}

.oneLineClampEllipsisNoDisplay {
  text-align: left;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: clip !important;
  overflow: hidden !important;
  white-space: normal !important;
  word-break: break-word !important;
}






/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.modal-dialog:not(.modal-list-tasks-component):not(.modal-create-ticket-component):not(.modal-consumer-component):not(.modal-agent-consumers-component):not(.modal-active-trigger-report-component) {
  max-width: 550px !important;
}

@media (min-width: 992px) {
  .modal-lg:not(.modal-list-tasks-component):not(.modal-create-ticket-component):not(.modal-consumer-component):not(.modal-agent-consumers-component):not(.modal-active-trigger-report-component),
  .modal-xl {
    max-width: 40vw !important;
  }
}

.modal {
  padding-left: 0 !important;
}

.modal-content {
  border: none !important;
  border-radius: 5px !important;
  overflow-y: hidden;
}

.modal-header {
  border: none !important;
  /*padding: 1rem 1.5rem !important;*/
}

.popover-arrow {
  display: none !important;
}

.form-check-input {
  width: 1.2em !important;
  height: 1.2em !important;
  margin-top: 0 !important;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.paddingDropdown {
  padding-top: 11px !important;
  padding-bottom: 12px !important;
}

.form-check-input:checked {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}


/*SCROLL*/


/* width */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: rgb(207, 227, 255);
  border-radius: 100rem;
}


/* Track */

::-webkit-scrollbar-track {
  box-shadow: none;
}


/* Handle */

::-webkit-scrollbar-thumb {
  background: #84B3F2;
  border-radius: 100rem;
  border-color: transparent !important;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  // background: #368dff;
  background: #3b57a3;
}


.list-unstyled {
  margin: 0 !important;
}

.bold-list-item {
  font-weight: bolder;
  color: #1E1E1E;
}

.dropdown-menu-Aux {
  inset: 0 !important;
}

/* Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.hideScrollBar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: ew-resize;
    background-color: var(--main-color);
  }

  input[type='range']::-webkit-slider-runnable-track {
    background-color: var(--blue3-color);
  }


}

/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: var(--main-color);
}

input[type="range"]::-moz-range-track {
  background-color: var(--main-color);
}

/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: var(--main-color);
}

input[type="range"]::-ms-fill-upper {
  background-color: var(--main-color);
}

p {
  margin: 0;
  padding: 0;
  margin-bottom: 0.7rem;
}

/* ajuste tabs */
.ContentTabs .tab-content {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

/* .popover-btn-option {
  text-align: start !important;
  width: 100% !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.popover-btn-option:hover {
  background-color: #dddddd !important;
} */

div.switch-input {
  // margin-bottom: 5px;

  span {
    padding-right: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-bottom: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .switch.disabled {
    opacity: 0.6;
  }

  .switch:hover {
    cursor: pointer;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #4a69bd;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #4a69bd;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  input:disabled {
    cursor: default;
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

div.switch-input.switch-input-disabled {
  label.switch {
    opacity: 0.6;
    
    span.slider {
      cursor: default;
    }
  }
}

input.form-control {
  min-height: 41px !important;
}

textarea {
  width: 100%;
  resize: none !important;
  height: 7vh;
  font: normal normal normal calc(0.2em + 0.6vw) Roboto;
  background-color: white;
  //border: 1px solid #BCBCBC;
  color: #707070;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  padding: 10px;
}
.modal-unlimited {
  max-width: 900px !important;
}

div.popover-custom-action-menu {
  display: flex;
  background-color: #fff;
  min-width: 190px;
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  z-index: 1055;

  div.popover-body {
    display: flex;
    flex-direction: column;
    width: 100%;

    span.popover-item {
      padding: 6px 9px;
      font-size: 15px;
      cursor: pointer;
      transition: 0.3s;
      font-weight: 500;
      color: #616161;
      display: flex;
      align-items: center;
      gap: 5px;

      svg {
        color: #616161;
        width: 18px;
        height: 18px;
      }
    }

    span.popover-item:hover {
      background-color: #ececec;
    }

    span.popover-item:not(:last-child) {
      border-bottom: 1px solid #ececec;
    }
  }
}

.circle-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #afd1ff #afd1ff transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.circle-loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #4a69bd #4a69bd;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
    
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}