.form-config-client {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;

  div.section {
    display: flex;
    flex-direction: column;

    div.header {
      margin-top: 10px;
      border-bottom: 1px solid #e0e0e0;

      span.title {
        font-size: 18px;
        font-weight: bold;
      }
    }

    div.section-content {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      div.item {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 15px;

        label {
          color: #707070;
        }

        input:disabled {
          background-color: #fff;
        }
      }

      div.item:first-child {
        margin-right: 25px;
      }

      div.line {
        display: flex;
        flex-direction: row;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    div.section {
      div.section-content {
        div.line {
          flex-direction: column;
        }
      }
    }
  }
}
