.phoneCallcenterHistory {
  height: 361px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  span.phoneCallcenterHistory-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    user-select: none;
  }

  div.phoneCallcenterHistory-emptyList {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    color: #0c2461;

    span.phoneCallcenterHistory-emptyList-text {
      margin-top: 10px;
    }
  }

  div.phoneCallcenterHistory-list {
    overflow: auto;

    div.phoneCallcenterHistory-item:not(:last-child) {
      margin-bottom: 5px;
    }

    div.item-scroll {
      margin-right: 5px;
    }

    div.phoneCallcenterHistory-item {
      display: flex;
      background: #f5f5f5;
      padding: 5px 5px;
      border-radius: 5px;

      span.phoneCallcenterHistory-item-icon {
        display: flex;
        align-items: center;
        padding: 8px;
      }

      span.phoneCallcenterHistory-item-icon.answered {
        color: #30c66e;
      }

      span.phoneCallcenterHistory-item-icon.not-answered {
        color: #d34126;
      }

      div.phoneCallcenterHistory-item-details {
        display: flex;
        flex-direction: column;
        width: 100%;

        span.phoneCallcenterHistory-item-number {
          font-weight: 600;
          font-size: 15px;
        }

        span.phoneCallcenterHistory-item-date {
          font-size: 13px;
        }
      }

      div.phoneCallcenterHistory-item-duration {
        font-size: 14px;
      }
    }
  }
}