div.home-app-component {
  display: grid;
  grid-template-columns: auto 1fr;
  min-width: 0;
  min-height: 0;
  height: 100vh;

  div.outlet-page {
    transition: 0.3s;
    // display: flex;
    // flex-direction: column;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    min-height: 0;
    // min-width: 0; // IMPORTANTE COM O DISPLAY GRID
    min-width: 399px;

    // display: grid;
    // min-height: 0;
    // min-width: 0;
    // grid-template-rows: auto 1fr;
    overflow: auto;

    // Novas alterações
  }
  
  div.outlet-page.sidebar-opened {
    transition: 0.3s;
    width: calc(100% - 260px);
  }
  
  div.outlet-page.sidebar-closed {
    transition: 0.3s;
    width: calc(100% - 65px);
  }
}