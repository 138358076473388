$attr_small_width: 156px;
$attr_medium_width: 185px;

div.custom-table-list-component {
  position: relative;
  transition: 0.3s;
  border: 1px solid #ececec;
  border-radius: 5px;

  display: grid;
  grid-template-rows: 1fr auto;
  min-width: 0;
  min-height: 0;
  user-select: none;

  div.table-list-component-content {
    overflow-y: auto;
    // max-height: 460px;
    min-height: 350px;
    
    .table-header.table-header-with-check {
      padding-left: 5px !important;

      span.table-header-item {
        svg.icon-check-btn {
          margin-right: 10px;
        }
      }
    }

    .table-header {
      display: flex;
      align-items: center;
      height: 50px;
      width: 100%;
      min-width: 760px;
      background: #efefef;
      color: #0C2461;
      font-weight: bold;
      position: sticky;
      top: 0;
      box-shadow: 1px 2px 9px -8px;
      padding-right: 5px;
      padding-left: 15px;

      span.table-header-item.table-header-item-info {
        min-width: 24px;
      }

      span.table-header-item.check-btn {
        margin-right: 5px;
      }
  
      span.table-header-item {
        margin-right: 10px;
        cursor: default;
        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
          cursor: pointer;
        }

        svg.icon-header-sort {
          transition: 0.3s;
          border-radius: 5px;
        }

        svg.icon-header-sort:hover {
          background-color: #fff;
        }

        svg.icon-check-btn {
          margin-left: 10px;
        }
      }
  
      span.table-header-item.small {
        min-width: $attr_small_width !important;
        width: $attr_small_width !important;
      }
  
      span.table-header-item.medium {
        min-width: $attr_medium_width !important;
        width: $attr_medium_width !important;
      }
  
      span.header-item-action {
        display: flex;
        margin-right: 0;
        justify-content: flex-end;

        span {
          display: flex;
          justify-content: center;
          min-width: 70px;
        }
      }
    }

    div.table-header.table-scroll {
      padding-right: 10px;
    }
  
    div.table-list-items {
      display: grid;
      padding-top: 4px;
      padding-bottom: 4px;
      min-width: 466px;

      div.table-list-item.table-list-item-with-check {
        div.table-list-item-align {
          padding-left: 0 !important;

          span.check-btn {
            margin-right: 15px !important;
          }
        }
      }

      div.table-list-item.table-list-item-wrap {
        div.table-list-item-align {
          white-space: normal !important;

          div.table-list-item-column {
            p.table-list-item-value-badges {
              flex-wrap: wrap;
            }
          }
        }
      }
  
      div.table-list-item {
        transition: 0.3s;
        border-bottom: 1px solid #f6f6f6;
        color: #707070;
        cursor: pointer;
        margin: 2px 4px;
        background-color: #f7f7f7;
        border-radius: 5px;
  
        div.table-list-item-align {
          display: flex;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          align-items: center;
          min-height: 45px;
          padding-left: 10px;
          // padding-left: 0;
        }

        span.check-btn {
          margin-right: 5px;
          // cursor: default;
          display: flex;
          align-items: center;
    
          svg {
            margin-left: 10px;
            // margin-right: 10px;
            transition: 0.3s;
          }
        }

        span.check-btn:not(.check-off):hover {
          svg {
            cursor: pointer;
          }
          svg:not(.icon-check-filled) {
            color: #5c5c5c;
            stroke: #5c5c5c;
          }
        }

        span.check-btn.check-off {
          svg {
            cursor: default;
            stroke-width: 2px;
            color: #dbdbdb;
            stroke: #dbdbdb;
          }
        }

        span.table-list-item-info {
          min-width: 24px;
          margin-right: 10px;

          svg {
            width: 24px;
            height: 24px;
            min-width: 24px;
            stroke-width: 2px;
            color: #707070;
          }
        }
  
        div.table-list-item-column {
          display: flex;
          align-items: flex-start;
          transition: 0.3s;
  
          p {
            margin: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;

            svg {
              margin-right: 3px;
            }
          }

          p.table-list-item-value-badges {
            display: flex;
            gap: 4px;
            padding: 5px 0px;

            span.table-list-item-value-badge {
              background-color: #e3e3e3;
              border-radius: 5px;
              padding: 0px 4px;
              font-size: 15px;
            }
          }

          p.table-list-item-value-invalid {
            color: #cf1212;
          }
        }

        div.table-list-item-column:not(.table-list-item-action) {
          svg {
            margin-right: 4px;
          }
        }

        div.table-list-item-column.table-list-item-agent-status {
          justify-content: center;

          svg.icon-info-status-agent {
            width: 20px;
            height: 20px;
          }

          svg.icon-info-status-agent.icon-info-status-agent-online {
            color: #11c544;
          }
        
          svg.icon-info-status-agent.icon-info-status-agent-away {
            color: #ffc755;
          }
        
          svg.icon-info-status-agent.icon-info-status-agent-offline {
            color: #d7252b;
          }
        }

        div.table-list-item-action {
          display: flex;
          margin-right: 0;
          justify-content: flex-end;
  
          span.action {
            display: flex;
            justify-content: center;
            width: 70px;
            margin-right: 1px;

            svg {
              cursor: pointer;
              transition: 0.3s;
              border-radius: 5px;
            }

            svg:hover {
              background-color: #d7d7d780;
            }
          }
        }

        span.badge-status {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
  
          height: 25px;
          width: 25px;
          display: flex;
          color: #fff;
          border-radius: 5px;
  
          span {
            font-weight: bold;
          }
        }

        span.custom-status {
          background-color: #ececec;
          padding: 2px 8px;
          border-radius: 5px;
          font-size: 15px;
          font-weight: 600;
        }

        span.custom-status.custom-status-blue {
          color: #0c2461;
          background-color: #dae3f9;
        }

        span.custom-status.custom-status-green {
          color: #2a9562;
          background-color: #d7f1e5;
        }

        span.custom-status.custom-status-orange {
          color: #ff8119;
          background-color: #ffe9d7;
        }
      }

      div.table-list-item.table-list-item-click-disabled {
        cursor: default;
      }
  
      div.table-list-item:not(.table-list-item-disabled):hover {
        background-color: #f0f0f0;
        transition: 0.3s;
      }

      div.table-list-item.table-list-item-disabled {
        cursor: default;
        background-color: #e9e9e9 !important;
      }

      div.table-list-item.table-list-item-emphasis {
        div.table-list-item-align {
          div.table-list-item-column {
            p {
              font-weight: bold;
              color: #0C2461;
            }
          }
        }
      }

      div.table-list-item.table-list-item-selected {
        background-color: rgb(207 227 255 / 25%);
        transition: 0.3s;
      }
    }

    div.table-list-empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      min-height: 350px;

      svg.tabler-icon-entity {
        color: #4a69bd;
        stroke: #4a69bd;
        stroke-width: 1;
        height: 80px;
        width: 80px;
      }
    
      span.table-list-info {
        color: #4a69bd;
        font-family: Montserrat;
        text-align: center;
        cursor: default;
      }
    }

    div.table-list-empty.table-list-error-loading {
      svg.tabler-icon-entity {
        color: #de2450;
        stroke: #de2450;
      }

      span.table-list-info {
        color: #de2450;
      }

      span.btn-reload-data {
        background-color: #ececec;
        font-size: 14px;
        border-radius: 5px;
        padding: 1px 6px;
        margin-top: 8px;
        cursor: pointer;
        transition: 0.3s;
      }

      span.btn-reload-data:hover {
        background-color: #dadada;
      }
    }
  }

  div.table-list-component-content.table-list-component-content-empty {
    overflow: hidden;

    div.table-list-items.table-list-empty {
      min-width: unset;
    }
  }

  div.table-list-footer {
    background-color: #efefef;
    box-shadow: 0px -5px 9px -8px #0000009c;
    padding: 2px 8px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    position: relative;

    div.table-list-footer-left {
      display: flex;

      div.table-list-actions {
        display: flex;
        align-items: center;
        
        button.table-list-action {
          display: flex;
          align-items: center;
          width: fit-content;
          color: #fff;
          transition: 0.3s;
          outline: none;
          border: none;
          padding: 1px 8px;
          padding-right: 5px;
          border-radius: 5px;
          font-size: 15px;
          transition: 0.3s;

          svg {
            width: 16px;
            height: 16px;
            min-height: 16px;
            margin-left: 4px;
          }
        }

        button.table-list-action:not(:last-child) {
          margin-right: 8px;
        }

        button.table-list-action:not(.table-list-action-clear):not(.table-list-action-delete):not(.table-list-action-inactivate) {
          background: #4a69bd;
        }

        button.table-list-action:not(.table-list-action-clear):not(.table-list-action-delete):not(.table-list-action-inactivate):hover {
          background: #2C4CA2;
        }

        button.table-list-action-clear {
          padding: 2px;
          display: flex;
          align-items: center;
          background-color: #8b8b8b;

          svg {
            height: 20px;
            width: 20px;
            margin-left: 0;
          }
        }

        button.table-list-action-clear:hover {
          background-color: #717171;
        }

        button.table-list-action-delete {
          background-color: #d12d2d;
        }

        button.table-list-action-delete:hover {
          background-color: #b21f1f;
        }

        button.table-list-action-inactivate {
          background-color: #505050;
        }

        button.table-list-action-inactivate:hover {
          background-color: #434343;
        }
      }
    }

    div.table-list-footer-right {
      display: flex;

      div.table-list-footer-pagination {
        display: flex;
        align-items: center;

        span.table-pagination-info {
          margin-right: 5px;
          cursor: default;
          span:not(:last-child) {
            margin-right: 5px;
          }
        }

        span.table-pagination-action {
          display: flex;
          align-items: center;

          svg {
            width: 24px;
            height: 24px;
            color: #5c5c5c;
            stroke-width: 2;
            cursor: pointer;
          }

          svg:not(.table-pagination-disabled):hover {
            color: #000;
          }

          svg.table-pagination-disabled {
            color: #c0c0c0 !important;
            cursor: default;
          }

          div.table-pagination-selected {
            position: relative;
            display: flex;
            align-items: center;

            span.table-pagination-value {
              padding: 0px 8px;
              border-radius: 5px;
              cursor: pointer;
              transition: 0.3s;
            }

            span.table-pagination-value:hover {
              background-color: #d4d4d4;
              transition: 0.3s;
            }

            div.table-pagination-popup-pages {
              position: absolute;
              display: flex;
              flex-direction: column;
              bottom: 30px;
              width: 45px;
              align-items: center;
              background: #d6d6d6;
              border-radius: 5px;
              left: -10px;
              max-height: 100px;
              overflow: auto;
              z-index: 10;

              span.table-pagination-popup-item {
                padding: 1px 2px;
                transition: 0.3s;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              span.table-pagination-popup-item:hover {
                background: #acacac;
                cursor: pointer;
                transition: 0.3s;
              }
            }
          }
        }
      }
    }
  }
}

div.custom-table-list-component.table-scrollbar-thin {
  div.table-list-component-content {
    scrollbar-width: thin;
  }
}

div.custom-table-list-component.custom-table-style-modal-tasks {
  div.table-list-component-content {
    max-height: 460px;

    div.table-header {
      min-width: 765px;
    }

    div.table-list-items {
      min-width: 765px;
    }
  }
}

div.custom-table-list-component.custom-table-style-modal-agent-consumers {
  div.table-list-component-content {
    max-height: 460px;

    div.table-header {
      min-width: 680px;
    }

    div.table-list-items {
      min-width: 680px;
    }
  }
}

div.custom-table-list-component.custom-table-style-consumers {
  div.table-list-component-content {
    div.table-header {
      min-width: 1145px;
    }

    div.table-list-items {
      min-width: 1145px;
    }
  }
}

div.custom-table-list-component.custom-table-style-tasks {
  div.table-list-component-content {
    div.table-header {
      min-width: 1155px;
    }

    div.table-list-items {
      min-width: 1155px;
    }
  }
}

div.custom-table-list-component.custom-table-style-agents {
  div.table-list-component-content {
    div.table-header {
      min-width: 1155px;
    }

    div.table-list-items {
      min-width: 1155px;
    }
  }
}

div.custom-table-list-component.custom-table-style-messages-trigger-schedules {
  div.table-list-component-content {
    div.table-header {
      min-width: 1390px;
    }

    div.table-list-items {
      min-width: 1390px;
    }
  }
}

div.custom-table-list-component.custom-table-style-modal-messages-trigger-report {
  div.table-list-component-content {
    min-height: 250px;
    max-height: 250px;

    div.table-header {
      min-width: 755px;
    }

    div.table-list-items {
      min-width: 755px;
    }

    div.table-list-items.table-list-empty {
      min-height: 250px;
    }
  }
}

div.custom-table-list-component.custom-table-style-sectors {
  div.table-list-component-content {
    div.table-header {
      min-width: 1210px;
    }

    div.table-list-items {
      min-width: 1210px;
    }
  }
}

div.custom-table-list-component.custom-table-style-tags-ticket,
div.custom-table-list-component.custom-table-style-tags-consumer {
  div.table-list-component-content {
    div.table-header {
      min-width: 860px;
    }

    div.table-list-items {
      min-width: 860px;
    }
  }
}