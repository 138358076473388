$attr_small_width: 156px;
$attr_medium_width: 185px;
$attr_min_width_history_chatbot: 955px;

.table-list-component {
  position: relative;
  // margin-left: 10px;
  // margin-right: 10px;
  transition: 0.3s;
  // overflow: hidden;
  border: 1px solid #ececec;
  border-radius: 5px;

  display: grid;
  grid-template-rows: 1fr auto;
  min-width: 0;
  min-height: 0;
  user-select: none;

  // div.table-list-component-content:hover {
  //   overflow-x: auto;
  // }

  div.table-list-component-content {
    // width: 100%;
    // overflow-x: auto;
    overflow-y: auto;
    // max-height: calc(100vh - 235px);
    // min-height: calc(100vh - 235px);

    .table-header.table-header-history-chatbot {
      min-width: $attr_min_width_history_chatbot !important;
    }

    .table-header {
      display: flex;
      align-items: center;
      height: 50px;
      // width: max-content;
      width: 100%;
      min-width: 1310px;
      background: #efefef;
      color: #0C2461;
      font-weight: bold;
      position: sticky;
      top: 0;
      box-shadow: 1px 2px 9px -8px;
      padding-right: 10px;
      padding-left: 4px;

      span.table-header-item.table-header-item-who-is-viewing {
        min-width: 24px;
      }

      span.table-header-item.check-btn {
        margin-right: 5px;
      }
  
      span.table-header-item {
        margin-right: 10px;
        cursor: default;
        display: flex;
        align-items: center;
  
        svg {
          margin-left: 5px;
          cursor: pointer;
        }

        svg.icon-header-sort {
          transition: 0.3s;
          border-radius: 5px;
        }

        svg.icon-header-sort:hover {
          background-color: #fff;
        }

        svg.icon-check-btn {
          margin-left: 10px;
        }
      }
  
      span.table-header-item.small {
        min-width: $attr_small_width !important;
        width: $attr_small_width !important;
      }
  
      span.table-header-item.medium {
        min-width: $attr_medium_width !important;
        width: $attr_medium_width !important;
      }
  
      span.header-item-action {
        display: flex;
        margin-right: 0;
        justify-content: flex-end;
      }

      span.table-header-item.show-column-selector span.btn-column-selector span.column-title-value,
      span.btn-column-selector span.column-title-value:hover {
        background-color: #fff;
        padding: 6px 6px 8px 8px;
        font-size: 15.6px;
        border-radius: 5px;
      }

      span.btn-column-selector {
        position: relative;
        transition: 0.3s;
        cursor: pointer;
        user-select: none;

        span.column-title-value {
          transition: 0.3s;
          text-wrap: nowrap;

          svg.icon-header-select {
            transition: 0.3s;
            margin-top: -1px;
          }

          svg.icon-header-select.icon-header-select-show {
            display: inline !important;
            transform: scale(-1);
          }
        }

        div.popover-table-list-column-type {
          position: absolute;
          display: flex;
          background-color: #fff;
          width: 100%;
          box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.7);
          border-radius: 5px;
          left: 0px;
          top: 35px;
        
          div.popover-table-list-column-type-body {
            display: flex;
            flex-direction: column;
            width: 100%;
        
            span.popover-item {
              padding: 6px 8px;
              font-size: 14.8px;
              cursor: pointer;
              transition: 0.3s;
              text-wrap: nowrap;
              font-weight: 500;
            }
        
            span.popover-item:hover {
              background-color: #ececec;
            }
        
            span.popover-item:not(:last-child) {
              border-bottom: 1px solid #ececec;
            }
          }
        }
      }
    }

    div.table-header.table-scroll {
      padding-right: 10px;
    }

    div.table-list-item.table-list-some-viewing {
      background-color: #e9e9e9 !important;
      cursor: default;

      span.check-btn {
  
        svg {
          cursor: default !important;
        }
      }

      span.check-btn:hover {
        svg:not(.icon-check-filled) {
          color: #919191 !important;
          stroke: #919191 !important;
        }
      }
    }

    div.table-list-item.table-list-item-processing {
      background-color: #ffdbdb !important;
      color: #2d2d2d !important;
      cursor: default !important;

      span.check-btn {
  
        svg {
          cursor: default !important;
        }
      }

      span.check-btn:hover {
        svg:not(.icon-check-filled) {
          color: #919191 !important;
          stroke: #919191 !important;
        }
      }
    }

    div.table-list-item.table-list-item-disabled {
      cursor: default !important;
    }

    .table-list-items.table-list-items-history-chatbot {
      min-width: $attr_min_width_history_chatbot !important;
    }
  
    div.table-list-items {
      // max-height: calc(100vh - 240px);
      // min-height: calc(100vh - 240px);
      display: grid;
      padding-top: 4px;
      padding-bottom: 4px;
      min-width: 1290px;
  
      div.table-list-item {
        // display: flex;
        // align-items: flex-start;
        transition: 0.3s;
        // flex-direction: column;
        // cursor: default;
        // border-bottom: 1px solid #f6f6f6;
        color: #707070;
        cursor: pointer;
        // width: calc(100% + 32px);
        margin: 2px 4px;
        background-color: #f7f7f7;
        border-radius: 5px;
  
        div.table-list-item-align {
          display: flex;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          align-items: center;
          min-height: 45px;
        }

        span.check-btn {
          margin-right: 5px;
          cursor: default;
          display: flex;
          align-items: center;
    
          svg {
            margin-left: 10px;
            cursor: pointer;
            transition: 0.3s;
          }
        }

        span.check-btn:hover {
          svg:not(.icon-check-filled) {
            color: #5c5c5c;
            stroke: #5c5c5c;
          }
        }

        span.table-list-item-who-is-viewing {
          min-width: 24px;
          margin-right: 10px;

          svg {
            width: 24px;
            height: 24px;
            min-width: 24px;
            stroke-width: 2px;
            color: #707070;
          }
        }
  
        div.table-list-item-column {
          display: flex;
          align-items: flex-start;
          transition: 0.3s;
  
          p {
            margin: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: flex;
            align-items: center;

            svg {
              margin-right: 3px;
            }
          }
        }

        div.table-list-item-column:not(.table-list-item-action) {
          svg {
            margin-right: 4px;
          }
        }

        div.table-list-item-action {
          display: flex;
          margin-right: 0;
          justify-content: flex-end;
  
          span.action {
            display: flex;
            justify-content: center;
            width: 50px;
            margin-right: 2px;

            svg {
              cursor: pointer;
              transition: 0.3s;
              border-radius: 5px;
            }

            svg:hover {
              background-color: #d7d7d780;
            }
          }
        }

        span.badge-status {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
  
          height: 25px;
          width: 25px;
          display: flex;
          color: #fff;
          border-radius: 5px;
  
          span {
            font-weight: bold;
          }
        }
      }

      div.table-list-item-updated {
        font-weight: bold !important;
        color: #000;
      }
  
      div.table-list-item:not(.table-list-some-viewing):not(.table-list-item-selected):not(.table-list-item-processing):hover {
        background-color: #f0f0f0;
        transition: 0.3s;
      }

      div.table-list-item-selected {
        background-color: rgb(207 227 255 / 25%);
        transition: 0.3s;
      }
  
      // div.table-list-item:not(:last-child) {
      //   border-bottom: 1px solid #f4f4f4;
      // }

      div.table-list-item-opened {
        background-color: #f0f8ff !important;
      }
    }

    div.table-list-empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // height: calc(100vh - 290px);
      height: calc(100%);

      svg.tabler-icon-ticket {
        color: #4a69bd;
        stroke: #4a69bd;
        stroke-width: 1;
        height: 80px;
        width: 80px;
      }
    
      span.table-list-info {
        color: #4a69bd;
        font-family: Montserrat;
        text-align: center;
        cursor: default;
      }
    }

    div.table-list-empty.table-list-error-loading {
      svg.tabler-icon-ticket {
        color: #de2450;
        stroke: #de2450;
      }

      span.table-list-info {
        color: #de2450;
      }

      span.btn-reload-tickets {
        background-color: #ececec;
        font-size: 14px;
        border-radius: 5px;
        padding: 1px 6px;
        margin-top: 8px;
        cursor: pointer;
        transition: 0.3s;
      }

      span.btn-reload-tickets:hover {
        background-color: #dadada;
      }
    }
  }

  div.table-list-component-content.table-list-component-content-empty {
    overflow: hidden;

    div.table-list-items.table-list-empty {
      min-width: unset;
    }
  }

  div.table-compact-component-content {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 260px;
    max-width: 290px;

    div.table-compact-header {
      display: flex;
      flex-direction: column;
      z-index: 1;
      
      div.table-compact-header-info {
        display: flex;
        padding: 5px 8px 5px 5px;
        background-color: #eef3ff;
        height: 40px;
        box-shadow: 0px 3px 9px -5px rgba(0, 0, 0, 0.4);

        span.table-compact-list-back {
          display: flex;
          background: #cfdcff;
          border-radius: 4px;
          cursor: pointer;
          padding: 3px;
          transition: 0.3s;
          margin-right: 5px;

          svg {
            height: 22px;
            width: 22px;
            stroke-width: 1.5;
            color: #4a69bd;
            transition: 0.3s;
          }
        }

        span.table-compact-list-back:hover {
          background: #bcccf7;
          transition: 0.3s;
        }

        div.table-compact-header-actions {
          display: flex;
        }
      }

      div.table-compact-list-filter {
        display: flex;
        flex-direction: column;
        padding: 10px 10px 0px 10px;

        div.search-input {
          display: flex;
          position: relative;
          margin-bottom: 10px;
          margin-top: 5px;
  
          input {
            width: 100%;
            background: #ededed;
            border: none;
            border-radius: 4px;
            margin-right: 4px;
            padding: 5px;
          }
  
          button {
            outline: none;
            border: none;
            border-radius: 5px;
            transition: 0.3s;
            color: #AFAFAF;
            background-color: #f9f9f9;
            padding: 2px 15px;
            font-weight: 500;
          }
  
          button:hover {
            background-color: #4a69bd;
            color: #fff;
            transition: 0.3s;
          }
        }
      }

      div.filter-action {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        svg {
          cursor: pointer;
        }

        svg.refresh-loading {
          animation: rotate-animation 3s infinite linear;
        }

        @keyframes rotate-animation {
          0% {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    div.table-compact-list-items {
      display: flex;
      flex-direction: column;

      // max-height: calc(100vh - 340px); // Pegar altura do header dinâmica
      // min-height: calc(100vh - 340px); // Pegar altura do header dinâmica
      overflow-y: auto;
      padding-top: 4px;
      padding-bottom: 4px;

      div.table-compact-list-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        // margin-right: 5px;
        min-height: 80px;
        justify-content: space-between;
        border-radius: 2px;
        cursor: pointer;
        border-radius: 5px;
        margin: 2px 4px;
        background-color: #f7f7f7;

        div.compact-item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          div.compact-item-info {
            display: flex;
            overflow: hidden;

            span.ticket-reference-id {
              margin-right: 5px;
              background: #eef3ff;
              color: #0C2461;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 600;
              padding: 0px 4px;
              display: flex;
              align-items: center;
            }

            p.ticket-requester {
              margin: 0;
              font-size: 14px;
              // font-weight: 500;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          div.compact-item-status {
            display: flex;
            align-items: center;

            span.icon-channel {
              margin-right: 5px;

              svg {
                stroke-width: 1;
                color: #707070;
                size: 30px;
              }
            }

            span.icon-activity {
              margin-left: 2px;
              margin-right: 5px;

              svg {
                size: 24px;
                min-width: 24px;
                color: #707070;
                stroke-width: 1.6px;
              }
            }

            span.badge-status {
              display: flex;
              justify-content: center;
              align-items: center;
              width: fit-content;
              padding: 2px;
              margin-right: 2px;
      
              height: 20px;
              width: 20px;
              display: flex;
              color: #fff;
              border-radius: 5px;
      
              span {
                font-weight: bold;
                font-size: 14px;
              }
            }
          }
        }

        div.compact-item-preview {
          display: flex;
          justify-content: space-between;

          p.ticket-preview-message {
            font-size: 14px;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #707070;
          }

          p.ticket-preview-no-message {
            font-size: 12px;
            font-style: italic;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #707070;
          }

          div.additional-content {
            display: flex;
            align-items: center;
            margin-left: 5px;

            span.updated-date {
              font-size: 12px;
              color: #909090;
              white-space: nowrap;
            }

            svg {
              size: 24px;
              min-width: 24px;
              color: #707070;
              stroke-width: 1.5px;
              margin-left: 5px;
            }
          }

        }
      }

      div.table-compact-list-item:not(.table-compact-list-item-opened):not(.table-compact-list-item-some-viewing):not(.table-compact-list-item-processing):hover {
        background-color: #f0f0f0;
        transition: 0.3s;
      }

      // div.table-compact-list-item:not(:last-child) {
      //   border-bottom: 1px solid #f2f2f2;
      // }

      div.table-compact-list-item-opened {
        background-color: #f0f8ff !important;

        div.compact-item-header {
          div.compact-item-info {
            span.ticket-reference-id {
              background: #d7e3ff !important;
              color: #0C2461 !important;
            }
          }
        }
      }

      div.table-compact-list-item-updated {
        font-weight: bold !important;
        color: #000 !important;
      }

      div.table-compact-list-item-some-viewing {
        background-color: #e9e9e9;

        div.compact-item-header {
          div.compact-item-info {
            span.ticket-reference-id {
              background: #d7d7d7;
              color: #636363;
            }
          }
        }
      }

      div.table-compact-list-item-processing {
        background-color: #ffdbdb;
        color: #2d2d2d !important;
        cursor: default !important;

        div.compact-item-header {
          div.compact-item-info {
            span.ticket-reference-id {
              background: #ffc1c1;
              color: #636363;
            }
          }
        }
  
        div.compact-item-preview {
          p {
            color: #2d2d2d !important;
          }
        }
      }

      div.table-compact-list-empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        svg.tabler-icon-ticket {
          color: #4a69bd;
          stroke: #4a69bd;
          stroke-width: 1;
          height: 50px;
          width: 50px;
        }
      
        span.table-list-info {
          color: #4a69bd;
          font-family: Montserrat;
          text-align: center;
          cursor: default;
          font-size: 14px;
        }
      }

      div.table-compact-list-empty.table-list-error-loading {
        svg.tabler-icon-ticket {
          color: #de2450;
          stroke: #de2450;
        }
  
        span.table-list-info {
          color: #de2450;
        }
  
        span.btn-reload-tickets {
          background-color: #ececec;
          font-size: 14px;
          border-radius: 5px;
          padding: 1px 6px;
          margin-top: 8px;
          cursor: pointer;
          transition: 0.3s;
        }
  
        span.btn-reload-tickets:hover {
          background-color: #dadada;
        }
      }
    }
  }

  div.table-list-footer {
    background-color: #efefef;
    box-shadow: 0px -5px 9px -8px #0000009c;
    padding: 2px 8px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    position: relative;

    div.table-list-footer-left {
      display: flex;

      div.table-list-actions {
        display: flex;
        align-items: center;
        
        button.table-list-action {
          display: flex;
          align-items: center;
          width: fit-content;
          background: #4a69bd;
          color: #fff;
          transition: 0.3s;
          outline: none;
          border: none;
          padding: 1px 8px;
          padding-right: 5px;
          border-radius: 5px;
          font-size: 15px;

          svg {
            width: 16px;
            height: 16px;
            min-height: 16px;
            margin-left: 4px;
          }
        }

        button.table-list-action:not(:last-child) {
          margin-right: 8px;
        }

        button.table-list-action:not(.table-list-action-clear):hover {
          background: #2C4CA2;
          transition: 0.3s;
        }

        button.table-list-action-clear {
          padding: 2px;
          display: flex;
          align-items: center;
          background-color: #8b8b8b;

          svg {
            height: 20px;
            width: 20px;
            margin-left: 0;
          }
        }

        button.table-list-action-clear:hover {
          background-color: #717171;
        }
      }
    }

    div.table-list-footer-right {
      display: flex;

      div.table-list-footer-pagination {
        display: flex;
        align-items: center;

        span.table-pagination-info {
          margin-right: 5px;
          cursor: default;
          span:not(:last-child) {
            margin-right: 5px;
          }
        }

        span.table-pagination-action {
          display: flex;
          align-items: center;

          svg {
            width: 24px;
            height: 24px;
            color: #5c5c5c;
            stroke-width: 2;
            cursor: pointer;
          }

          svg:not(.table-pagination-disabled):hover {
            color: #000;
          }

          svg.table-pagination-disabled {
            color: #c0c0c0 !important;
            cursor: default;
          }

          div.table-pagination-selected {
            position: relative;
            display: flex;
            align-items: center;

            span.table-pagination-value {
              padding: 0px 8px;
              border-radius: 5px;
              cursor: pointer;
              transition: 0.3s;
            }

            span.table-pagination-value:hover {
              background-color: #d4d4d4;
              transition: 0.3s;
            }

            div.table-pagination-popup-pages {
              position: absolute;
              display: flex;
              flex-direction: column;
              bottom: 30px;
              width: 45px;
              align-items: center;
              background: #d6d6d6;
              border-radius: 5px;
              left: -10px;
              max-height: 100px;
              overflow: auto;
              z-index: 10;

              span.table-pagination-popup-item {
                padding: 1px 2px;
                transition: 0.3s;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              span.table-pagination-popup-item:hover {
                background: #acacac;
                cursor: pointer;
                transition: 0.3s;
              }
            }
          }
        }
      }
    }
  }
}

div.table-list-component-compacted {
  min-width: 260px;
  // max-width: 25%;
  max-width: 290px;
  width: 100%;
  transition: 0.3s;
  margin-right: 0px;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-right: unset;
  display: unset;

  div.table-list-footer {
    justify-content: center;
  }
}

div.table-list-component-compacted.table-list-component-hide {
  min-width: unset;
  max-width: unset;
  width: unset;
  height: 100%;
  //min-height: calc(100vh - 271px); // Pegar altura do header dinâmica
  //max-height: calc(100vh - 271px); // Pegar altura do header dinâmica

  div.table-compact-component-content {
    height: 100%;
    min-width: auto;

    div.table-compact-header {
      height: 100%;

      div.table-compact-header-info {
        padding: 0px;
        height: 100%;
        box-shadow: unset;

        span.table-compact-list-back {
          margin-right: 0px;
          align-items: center;
          border-radius: unset;
          background-color: #eef3ff;
        }
      }
    }
  }
}

@media only screen and (max-width: 870px) {
  div.table-list-component.table-list-component-compacted {
    min-width: unset;
    max-width: unset;

    border-top-right-radius: 5px;
    border-right: 1px solid #ececec;
    border-bottom-left-radius: unset;

    div.table-compact-component-content {
      height: 290px;

      div.table-compact-list-items {
        height: 100px !important;
      }
    }
  }

  div.table-list-component.table-list-component-compacted.table-list-component-hide {
    height: unset;

    div.table-compact-component-content.table-list-component-hide {
      height: unset;

      div.table-compact-header {
        div.table-compact-header-info {
          height: 40px;
          justify-content: center;

          div.table-compact-header-actions {
            width: 100%;
            justify-content: center;

            span.table-compact-list-back {
              width: 100%;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}