
.registerMobileHeader {
    text-align: left;
    font: var(--px21_33-Montserrat);
    letter-spacing: 0px;
    color: #FFFFFF; 
}

.registerMobileOrangeLabel { 
    background: #EF863C 0% 0% no-repeat padding-box; 
    border-radius: 3px;
    opacity: 1;
    margin: 0;
    padding: 0.5px 5px 0.5px 5px; 
    font: var(--px21_67_Bold-Montserrat);
}

.backgroundRegisterMobile {
    background-color: #F9F9F9;
    border-radius: 24px 24px 0px 0px;
    font: normal normal normal calc(0.2em + 0.7vw) Montserrat;
    color: #707070;
    margin-top: auto;
}