div.list-agents-component {
  // display: flex;
  // flex-direction: column;
  width: 100%;
  display: grid;
  min-height: 0;
  min-width: 0;
  grid-template-rows: auto 1fr;

  div.page-header {
    // width: fit-content;
    display: flex;
    justify-content: space-between;

    div.header-info {
      // display: flex;
      // flex-direction: column;
      width: fit-content;
    }

    div.header-actions {
      // display: flex;
      width: fit-content;
    }
  }

  div.list-agents-page-content {
    // display: flex;
    // width: fit-content;
    display: grid;
    min-height: 0;
    min-width: 0;
    padding: 0px 14px 14px 14px;
    // height: 100%;
  }
}

div.list-agents-page.list-agents-sidebar-opened {
  transition: 0.3s;
  width: calc(100% - 247px);
}

div.list-agents-page.list-agents-sidebar-closed {
  transition: 0.3s;
  width: calc(100% - 52px);
}