.app-sidebar {
  background: linear-gradient(180deg, #f6f7f8, #fff);
  height: 100vh;
  width: 260px;
  min-width: 260px;
  transition: 0.3s;
  user-select: none;
  box-shadow: 0px -8px 10px -8px rgba(0, 0, 0, 0.7);
  z-index: 200;

  .header {
    display: flex;
    position: relative;
    padding: 20px 10px;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    box-shadow: -5px -2px 10px -3px rgba(0, 0, 0, 0.7);

    div.logo {
      overflow: hidden;
      cursor: pointer;

      img {
        width: 140px;
        transition: 0.3s;
      }
    }

    svg.showHideIcon {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  ul.menu {
    list-style: none;
    padding: 0px;
    margin: 0px;
    // max-height: calc(100% - 240px);
    transition: 0.3s;
    margin-top: 5px;
    font-family: Montserrat;
    font-weight: 600;
    scrollbar-width: thin;

    div.menu-item-container {
      display: flex;
      flex-direction: column;
      position: relative;

      li.menu-item-li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.3s;
        margin: 3px 10px;
        padding: 8px 8px;
        border-radius: 5px;
  
        div.menu-item {
          display: flex;
          align-items: center;
          color: #505050;
          transition: 0.3s;
  
          svg.menu-icon {
            margin-right: 8px;
            transition: 0.3s;
          }
  
          span.menu-name {
            transition: 0.3s;
            white-space: nowrap;
          }
        }

        span.chevron-icon {
          svg {
            transition: 0.3s;
          }
        }

        span.chevron-icon.rotate-icon {
          svg {
            transition: 0.3s;
            transform: rotate(180deg);
          }
        }
      }

      li.menu-item-li:hover,
      li.menu-item-li.subpage-opened {
        background-color: #e3e3e3;
        transition: 0.3s;
      }

      li.menu-item-li.active {
        background-color: #e3eafa;
  
        div.menu-item {
          font-weight: 600;
          color: #4a69bd;
  
          svg {
            stroke-width: 2;
          }
        }
      }
  
      ul.submenu-item-ul {
        padding-left: 0;
        margin-left: 40px;
        margin-top: 0px;
        background-color: #ededed;
        border-radius: 5px;
        margin-right: 10px;

        transition: max-height 0.3s ease-out;
        list-style: none;
        max-height: 0;
        overflow: hidden;
  
        li.sub-item-li {
          cursor: pointer;
          transition: 0.3s;
          margin: 5px 4px;
          padding: 2px 8px;
          border-radius: 5px;
          width: fit-content;
          font-size: 15px;
        }

        li.sub-item-li:hover {
          background-color: #fff;
          transition: 0.3s;
        }

        li.sub-item-li.sub-active {
          background-color: #fff;
          color: #4a69bd;
        }

        // li.sub-item-li::marker {
        //   content: '⤷   ';
        //   color: #cacaca;
        // }

        // li.sub-item-li.sub-active::marker {
        //   color: #4a69bd;
        // }
      }
  
      ul.submenu-item-ul.expand-subpages {
        margin-bottom: 3px;
        transition: max-height 0.3s ease-in;
        max-height: fit-content;
        overflow: unset;
      }
    }

    div.language-selector {
      margin: 1px 8px;
      margin-top: 10px;
      padding: 5px 3px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

.app-sidebar:not(.app-sidebar-closed) {
  ul.menu {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 85px);
  }
}

.app-sidebar-closed {
  width: 65px;
  min-width: 65px;
  transition: 0.3s;

  .header {
    div.logo {
      img {
        width: 0px;
        transition: 0.3s;
      }
    }

    svg.showHideIcon {
      margin-left: 10px;
    }
  }

  ul.menu {
    div.menu-item-container {
      li.menu-item-li {
        justify-content: center;
        
        div.menu-item {
          transition: 0.3s;
  
          svg.menu-icon {
            margin: 0;
            transition: 0.3s;
            display: flex;
            justify-content: center;
            width: 100%;
          }
  
          span.menu-name {
            display: none;
            transition: 0.3s;
          }
        }
      }

      ul.submenu-item-ul {
        list-style: none;
        height: 0;
      }

      ul.submenu-item-ul.expand-subpages {
        padding: 0px;
        margin-bottom: unset;
        margin-left: 10px;
        box-shadow: 0px 1px 8px -4px #000000b3;
        border-radius: 5px;
        background-color: #ededed;
        position: absolute;
        height: fit-content;
        overflow: unset;
        top: 3px;
        left: 65px;
        z-index: 100;

        span.subpage-title {
          background-color: #fff;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;

          display: flex;
          width: 100%;
          border-bottom: 1px solid #e0e0e0;
          align-items: center;
          justify-content: center;
          color: #707070;
          font-weight: 500;
          padding: 8px 14px 8px 14px;
          box-shadow: 0px 7px 10px -10px rgb(0 0 0 / 50%);
        }

        li.sub-item-li {
          cursor: pointer;
          transition: 0.3s;
          margin: 5px 4px;
          padding: 2px 8px;
          border-radius: 5px;
          width: fit-content;
          font-size: 15px;
          text-wrap: nowrap;
        }

        li.sub-item-li:hover {
          background-color: #fff;
          transition: 0.3s;
        }

        li.sub-item-li.sub-active {
          background-color: #fff;
          color: #4a69bd;
        }

        // li.sub-item-li::marker {
        //   content: '';
        // }
      }
    }
  }
}