.container-input-service {
    background-color: #f7f7f7;
    height: 40px;
    width: 63vw;
    border-radius: 5px;
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    div.container-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f7f7f7;
        height: 40px;
        width: 2.5vw;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;


        input.checkbox-schedules {
            width: 18px;
            height: 18px;
            padding: 10px;
            border-radius: 50%;
            background-color: #f7f7f7;
        }
    }

    div.container-day {
        background-color: #4a69bd;
        height: 40px;
        width: 6.5vw;
        display: flex;
        align-items: center;
        padding-left: 10px;
        justify-content: flex-start;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor: pointer;

        div.container-title {
            color: #f7f7f7;
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 0.8rem;
        }
    }

    div.container-day-disabled {
        background-color: #a4b4de;
        height: 40px;
        width: 6.5vw;
        display: flex;
        align-items: center;
        padding-left: 10px;
        justify-content: flex-start;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor: pointer;

        div.container-title-disabled {
            color: #f7f7f7;
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 0.8rem;
        }
    }

    div.container-title-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    div.container-schedules {
        width: 51.8vw;
        display: flex;
        height: 40px;
        align-items: center;

        div.subcontainer-schedules {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }

        div.container-hours {
            background-color: #d9d9d9;
            width: 110px;
            height: 25px;
            display: flex;
            justify-content: center;
            border-radius: 5px;
            font-family: 'Montserrat';
            margin: 2px;
            cursor: pointer;
        }

        div.container-not-schedules {
            background-color: #ededed;
            width: 110px;
            display: flex;
            justify-content: center;
            border-radius: 5px;
            font-style: italic;
            font-family: 'Montserrat';
            margin: 2px;
            cursor: pointer;
        }
    }

    div.container-icon {
        background-color: #717171;
        border-radius: 5px;
        color: #f7f7f7;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }
}

