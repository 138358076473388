div.scene-keyboard {
  div.extension-info {
    display: flex;
    align-items: center;

    color: #fff;
    background: linear-gradient(178deg, #284492, rgb(92 216 213) 200%);
    font-size: 12px;
    padding: 5px;
    border-bottom: #ffffff6b;

    div.extension-number {
      margin-left: 4px;
      span.extension {
        margin-right: 3px;
        font-weight: 600;
      }
    }
  }

  div.display-call-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 10px;
    transition: 0.3s;
    min-height: 75px;

    font-family: Montserrat;
    background: linear-gradient(178deg, #284492, rgb(92 216 213) 200%);

    div.display-left {
      display: flex;
      flex-direction: column;

      div.number {
        display: flex;
        align-items: center;
        font-size: 14px;
  
        svg {
          margin-right: 5px;
        }
        span.phoneNumber {
          font-weight: 600;
        }
      }
  
      span.duration {
        font-size: 20px;
        transition: 0.3s;
      }
    }

    div.two-options {
      justify-content: space-between !important;
    }

    div.display-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      height: 55px;

      span.display-action {
        font-size: 13px;
        border: 1px solid #ffffff3d;
        border-radius: 20px;
        padding: 1px 7px;
        cursor: pointer;
        transition: 0.3s;
      }

      span.display-action:hover,
      span.display-action.active {
        border: 1px solid #fff;;
        background-color: #ffffff3d;
        transition: 0.3s;
      }
    }
  }

  div.view-keyboard {
    display: flex;
    flex-direction: column;

    div.display {
      box-shadow: -1px 0px 8px -2px #0000007d;
      position: relative;
      display: flex;
      align-items: center;
      background: #ececec;
      user-select: none;
  
      input {
        padding: 15px;
        padding-right: 0;
        width: 100%;
        border: none;
        font-size: 18px;
        background-color: transparent;
      }
  
      span.clearDisplay {
        margin-right: 10px;
        
        svg {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }
  
        svg:hover {
          color:#444444;
          transition: 0.3s;
        }
      }
    }
  
    .board {
      display: flex;
      flex-wrap: wrap;
      user-select: none;
  
      div {
        width: calc(100% / 3);
        height: calc((276px) / 4);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Montserrat";
        background: #f9f9f9;
        border: 1px solid #ffffff80;
        transition: 0.2s;
        font-size: 24px;
      }
  
      div:hover {
        cursor: pointer;
        background: #0068b8;
        color: #fff;
        font-weight: bold;
      }
    }
  }

  div.view-transfer {
    height: 333px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    user-select: none;

    .subscene-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .subscene-title {
        font-weight: 600;
        font-size: 18px;
      }

      svg {
        cursor: pointer;
        transition: 0.3s;
      }

      svg:hover {
        stroke: #888888;
        transition: 0.3s;
      }
    }

    div.subscene-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      div.transfer-actions {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span.transfer-option {
          font-weight: 500;
          background-color: #0068b8;
          padding: 6px 12px;
          border-radius: 4px;
          color: #fff;
          cursor: pointer;
          transition: 0.3s;
        }

        span.transfer-option:not(.disabled):hover {
          background-color: #00518e;
          transition: 0.3s;
        }

        span.transfer-option.disabled {
          opacity: 0.6;
          cursor: default;
        }

        div.divider {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          margin-bottom: 10px;

          span.line {
            height: 1px;
            width: 40px;
            background: #bababa;
          }

          span.line-text {
            padding: 5px;
            color: #bababa;
          }
        }
      }
    }
  }
}