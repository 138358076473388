.scene-consumers {
  height: 361px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .scene-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .scene-list-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    color: #0c2461;

    span.empty-text {
      margin-top: 10px;
    }
  }

  div.search-agent {
    margin-bottom: 10px;
  }

  .scrollableDiv {
    max-height: 252px;
    height: 100%;
    overflow: auto;
    position: relative;
  }

  .scene-list {
    // overflow: auto;

    div.scene-list-item:not(:last-child) {
      margin-bottom: 5px;
    }

    div.item-scroll {
      margin-right: 5px;
    }

    div.self-extension {
      background: #d4f9dd !important;
    }

    div.scene-list-item {
      display: flex;
      background: #f5f5f5;
      padding: 5px 5px;
      border-radius: 5px;
      transition: 0.3s;

      span.list-item-action {
        display: flex;
        align-items: center;
        padding: 8px;
        color: #30c66e;
        svg {
          cursor: pointer;
        }
      }

      div.list-item-details {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 2px 5px;

        span.details-consumer {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: -5px;
        }

        span.details-name {
          font-size: 13px;
        }
      }
    }
  }
}