div.language-selector-component {
  div.modal-content {
    div.modal-header {
      gap: 10px;
      user-select: none;
      box-shadow: 0px 0px 12px -8px;
      z-index: 1;
      
      div.modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      button.btn-close {
        margin-top: -15px;
        transition: 0.3s;
      }

      div.language-selector-component-header-actions {
        display: flex;
        gap: 6px;

        svg {
          cursor: pointer;
          color: #707070;
          transition: 0.3s;
        }

        svg:hover {
          color: #606060;
        }
      }
    }
  }

  form.language-selector-set-language-form {
    position: relative;

    div.language-list {
      display: flex;
      flex-wrap: wrap;

      div.language-item {
        display: flex;
        align-items: center;
        width: calc((100%/2) - 10px);

        padding: 10px 10px;
        margin-bottom: 5px;
        margin-right: 10px;
        border-radius: 5px;
        transition: 0.3s;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }

        svg.icon-check {
          margin-left: 5px;
          width: 20px;
        }
      }

      div.language-item:hover {
        background-color: #ededee;
        cursor: pointer;
        transition: 0.3s;
      }

      div.language-item.active {
        background-color: #e5ecff;
        color: #1b7ce7;
      }
    }

    @media only screen and (max-width: 450px) {
      div.language-list {
        flex-direction: column;

        div.language-item {
          width: 100%;
        }
      }
    }
  }

  div.modal-footer {
    border-top: unset;
    justify-content: space-between;
    box-shadow: 0px -3px 17px -13px #0000006e;
    z-index: 1;

    .language-selector-max-width {
      width: 100%;
    }

    button {
      border-radius: 5px;
      padding: 5px 10px;
      transition: 0.3s;
      cursor: pointer;
      min-width: 100px;
    }

    button.language-selector-btn-save {
      background-color: #4a69bd;
      color: #fff;
      border: none;
    }

    button.language-selector-btn-save:not(:disabled):hover {
      background-color: #4a69bd;
    }

    button.language-selector-btn-save:disabled {
      opacity: 0.4;
      cursor: default;
    }

    button.language-selector-btn-cancel {
      background-color: #fff;
      color: #4a69bd;
      border: 1px solid #4a69bd;
    }

    button.language-selector-btn-cancel:hover {
      background-color: #e8e8e8;
    }

    .language-selector-message-alert {
      color: red;
      font-size: 14px; 
      margin-left: 0.4rem;
    }
  }
}
