div.single-tab-detection-component {
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
  font-family: Montserrat;
  user-select: none;
  background: linear-gradient(0deg, #4a69bd -30%, #fff 35%);

  div.single-tab-detection-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

    div.single-tab-detection-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding: 10px 20px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgb(0 0 0 / 4%) 0px 6px 10px, rgb(0 0 0 / 0%) 0px 1px 11px;

      border-radius: 10px;
      min-height: 200px;
      width: 450px;

      background-color: #f0f4ff;

      img {
        width: 200px;
      }

      span.single-tab-detection-box-message {
        text-align: center;
      }
      span.single-tab-detection-box-message-info {
        font-size: 11px;
        font-style: italic;
        text-align: center;
      }

      div.single-tab-detection-box-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        span {
          color: #fff;
          padding: 6px 10px;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.3s;
        }

        span.single-tab-detection-box-footer-close {
          background-color: #707070;
        }

        span.single-tab-detection-box-footer-close:hover {
          background-color: #606060;
        }

        span.single-tab-detection-box-footer-use {
          background-color: #4a69bd;
        }
        span.single-tab-detection-box-footer-use:hover {
          background-color: #3150a6;
        }
      }
    }
  }

  div.single-tab-detection-footer {
    display: flex;
    // overflow: hidden;
    flex-wrap: nowrap;
    // height: 50px;

    svg {
      width: 856px;
      height: 67.19px;
      margin-left: -65px;
    }
  }
}
