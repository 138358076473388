.summary-status-list-finalized {
  width: 70%;
  background: #EEFFF7 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font: normal normal bold calc(0.2em + 0.7vw) Roboto;
  letter-spacing: 0px;
  color: #2A9562;
  display: flex;
  justify-content: center;
}

.summary-status-list-failure {
  width: 70%;
  background: #FFD4B0 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font: normal normal bold calc(0.2em + 0.7vw) Roboto;
  letter-spacing: 0px;
  color: #FF8119;
  display: flex;
  justify-content: center;
}

.summary-status-list-processing {
  width: 70%;
  background: #EEF3FF 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font: normal normal bold calc(0.2em + 0.7vw) Roboto;
  letter-spacing: 0px;
  color: #0C2461;
  display: flex;
  justify-content: center;
}