div.toolbar-color-picker-component {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 36px;
  height: 34px;
  transition: 0.3s;
  border-radius: 5px;
  
  div.toolbar-color-picker-swatch {
    cursor: pointer;
    position: relative;
    display: flex;
    
    svg {
      color: #888;
      stroke-width: 1.3;
    }

    svg.toolbar-color-picker-text {
      width: 30px;
      min-width: 30px;
      height: 30px;
    }

    div.toolbar-color-picker-color-text {
      width: 20px;
      height: 2px;
      position: absolute;
      bottom: 5px;
      left: 5px;
    }


    div.toolbar-color-picker-color-background {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      bottom: -1px;
      right: 0px;
    }
  }

  div.toolbar-color-picker-popover {
    position: absolute;
    z-index: 2;
    bottom: 40px;
    border: 1px solid #ececec;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;

    div.sketch-picker {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      box-shadow: unset !important;
    }

    button {
      width: 100%;
      outline: none;
      border: none;
      background-color: #fff;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #ececec;
      

      span.selected-color {
        height: 20px;
        width: 20px;
        border-radius: 5px;
        margin-right: 10px;
        border: 1px solid #717171;
      }
    }
  }
}

div.toolbar-color-picker-component:hover {
  background: #EEF3FF;
}
