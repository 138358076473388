div.chat-editor-component {
  display: grid;
  flex-direction: column;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 46px 1fr;
  padding: 2px;

  div.chat-editor-reply-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    padding: 10px;
    background-color: #e3e3e3;
    border-radius: 5px;
    gap: 10px;
    overflow: hidden;

    div.chat-editor-reply-message {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #c8c8c8;
      border-left: 5px solid#4a69bd;
      border-radius: 5px;
      padding: 4px;
      padding-left: 10px;
      overflow: hidden;

      span.chat-editor-reply-message-author {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: -4px;
        user-select: none;
      }

      span.chat-editor-reply-message-content {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: default;

        svg {
          width: 18px;
          height: 18px;
          color: #4a69bd;
          margin-top: -3px;
          margin-right: 4px;
        }
      }
    }

    span.chat-editor-reply-cancel {
      cursor: pointer;
      color: #707070;
    }
  }

  div.chat-editor-config {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 5px;

    div.chat-editor-config-left {
      display: flex;

      div.chat-editor-config-message-type {
        display: flex;
        align-items: center;
        position: relative;
        user-select: none;

        div.chat-editor-config-message-type-select {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          background-color: #ececec;
          padding: 6px 4px 6px 8px;
          border-radius: 5px;
          transition: 0.3s;
          font-size: 16px;
          min-width: 210px;

          span.chat-editor-config-message-type-value {
            font-weight: 500;
            display: flex;
            align-items: center;

            svg {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
          }

          svg.chat-editor-config-arrow {
            width: 20px;
            height: 20px;
            margin-left: 3px;
            transition: 0.3s;
          }
        }

        div.chat-editor-config-message-type-select:not(.chat-editor-config-message-type-blocked ):hover {
          background-color: #dbdbdb;
        }

        div.chat-editor-config-message-type-select.chat-editor-config-message-type-opened {
          svg.chat-editor-config-arrow {
            transform: scaleY(-1);
          }
        }

        div.chat-editor-config-message-type-select.chat-editor-config-message-type-blocked {
          opacity: 0.6;
          cursor: default;

          svg.chat-editor-config-arrow {
            display: none;
          }
        }

        div.chat-editor-config-message-type-popup {
          position: absolute;
          background-color: #fff;
          bottom: 40px;
          border-radius: 5px;
          box-shadow: -1px 0px 11px -7px rgba(0, 0, 0, 0.7);
          border: 1px solid #ececec;
          min-width: 210px;
          z-index: 2;

          p.chat-editor-config-message-type-popup-header {
            font-weight: 500;
            margin-left: 4px;
            padding: 5px 6px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #ececec;
          }

          div.chat-editor-config-message-type-options-list {
            display: flex;
            flex-direction: column;
            padding: 5px 6px;

            span.chat-editor-config-message-type-option {
              display: flex;
              align-items: center;
              // font-size: 16px;
              cursor: pointer;
              transition: 0.3s;
              border-radius: 5px;
              padding: 5px 4px;

              svg {
                width: 20px;
                height: 20px;
                margin-right: 4px;
              }
            }

            span.chat-editor-config-message-type-option:hover {
              background-color: #ececec;
            }
          }
        }
      }

      div.chat-editor-config-channel-info {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        line-height: 18px;
        cursor: default;

        span.chat-editor-consumer-info-label {
          font-size: 13px;
        }
        span.chat-editor-consumer-info-value {
          margin-top: -1px;
          display: flex;
          gap: 4px;

          span.chat-editor-consumer-info-value-email,
          span.chat-editor-consumer-info-value-more-emails {
            font-size: 14px;
            background-color: #e5ecff;
            padding: 0px 4px;
            border-radius: 5px;
            transition: 0.3s;
          }

          span.chat-editor-consumer-info-value-more-emails:hover {
            background-color: #cfdaf9;
            cursor: pointer;
          }
        }
      }
    }

    div.chat-editor-config-actions {
      display: flex;
      align-items: center;
      gap: 4px;
      position: relative;
    }
  }

  div.chat-editor-container {
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-template-rows: 1fr auto;
    overflow: auto;
    display: flex;
    flex-direction: column;

    div.chat-editor-container-column {
      display: grid;
      grid-template-columns: 1fr auto;
      // grid-template-rows: 1fr auto;
      overflow: auto;
      height: 100%;
      margin-bottom: 4px;
      scrollbar-width: thin;

      div.chat-editor-field.empty-editor {
        div.public-DraftEditorPlaceholder-inner {
          display: block;
        }
      }

      div.chat-editor-field.chat-editor-template-message {
        div.DraftEditor-root {
          background-color: #e7edfd;
          cursor: default;
        }
      } 
  
      div.chat-editor-field.chat-editor-internal-note {
        div.DraftEditor-root {
          background-color: #ffe8d5;
        }
  
        div.public-DraftEditorPlaceholder-inner {
          color: #838383;
        }
      }
  
      div.chat-editor-field {
        display: grid;
        min-height: 0;
        padding: 0px 5px;
  
        div.public-DraftEditorPlaceholder-inner {
          color: #bebebe;
          position: absolute;
          font-size: 16px;
          user-select: none;
          cursor: text;
          display: none;
          transition: 0.3s;
        }
  
        div.DraftEditor-root {
          position: relative;
          overflow: auto;
          scrollbar-width: thin;
          background-color: #f8f8f8;
          padding: 8px 8px;
          border-radius: 5px;
          // margin-bottom: 3px;
          transition: 0.3s;
  
          div.public-DraftEditorPlaceholder-root {
            div.public-DraftEditorPlaceholder-inner {
              padding-right: 10px;
            }
          }
        }
      }

      div.file-uploader-field {
        overflow: auto;
      }

      div.show-recorder-field {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }


    div.chat-editor-field-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // background-color: #f9f9f9;

      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: thin;
      min-height: 45px;
      margin-bottom: 1px;
      
      div.chat-editor-field-toolbar {
        // background-color: #f9f9f9;
        // width: 100%;
        // padding-bottom: 5px;
        padding-left: 5px;

        div.t16lpgj {
          display: flex;
          align-items: center;
          border: none;
          box-shadow: unset;
          gap: 2px;
          // background-color: #f9f9f9;

          span.field-toolbar-action:not(.span-toolbar-unstyled) {
            width: 36px;
            height: 34px;

            div.bi09khh {
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                height: 100%;
                width: 100%;
              }
            }

            div.bi09khh,
            div.toolbar-attachment,
            div.toolbar-audio-recorder,
            div.e183m4hm {
              width: 36px !important;
              height: 34px !important;
  
              button {
                padding: 0;
                transition: 0.3s;
                background-color: unset !important;
                border-radius: 5px !important;
                display: flex;
                align-items: center;
                justify-content: center;
              }
  
              button:hover {
                background-color: #EEF3FF !important;
              }
  
              button.e8k2yoa { // Emoji
                font-size: 28px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 5px;
              }
            }

            div.e183m4hm {
              div.ejr02pv {
                border-radius: 5px;

                div.e1kg9q3n {
                  max-height: 150px;
                }
              }
            }
          }

          span.span-toolbar-active {
            div.toolbar-attachment,
            div.toolbar-audio-recorder,
            div.color-picker-component,
            div.linkify-component {
              background-color: #EEF3FF !important;
            }
          }

          span.field-toolbar-action.span-toolbar-disabled {
            div.toolbar-attachment,
            div.toolbar-audio-recorder {
              opacity: 0.4;
              cursor: default;
            }
          }

          div.toolbar-attachment,
          div.toolbar-audio-recorder {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: 0.3s;
            border-radius: 5px;

            svg {
              color: #888888;
            }
          }

          div.toolbar-attachment:hover,
          div.toolbar-audio-recorder:hover {
            background-color: #EEF3FF !important;
          }
        }

        div.chat-editor-field-toolbar-disabled-message {
          display: flex;
          align-items: center;
          gap: 5px;
          
          span.chat-editor-field-toolbar-disabled-text {
            background-color: #ececec;
            color: #717171;
            border-radius: 5px;
            padding: 2px 6px;
            font-size: 15px;
            cursor: default;
            user-select: none;

            display: flex;
            align-items: center;
            gap: 3px;

            svg {
              width: 18px;
              min-width: 18px;
              height: 18px;
            }
          }

          span.chat-editor-field-template-message-clear {
            color: #f36a6b;
            background-color: #f3f3f3;
            border-radius: 5px;
            padding: 2px 4px;
            cursor: pointer;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
        
            svg {
              width: 20px;
              min-width: 20px;
            }
          }
        
          span.chat-editor-field-template-message-clear:hover {
            background-color: #dddddd;
          }
        }
      }

      button.chat-editor-field-send {
        border: none;
        margin-right: 5px;
        border-radius: 5px;
        padding: 2px 10px;
        color: #fff;
        background-color: #4a69bd;
      }

      button.chat-editor-field-send:disabled {
        opacity: 0.6;
      }
    }
  }
}

div.chat-editor-component.chat-editor-create-ticket {
  background-color: #fff;
  border-radius: 5px;
  position: relative;
}

div.chat-editor-component.chat-editor-without-config {
  grid-template-rows: 1fr !important;
  background-color: #fff;
  border-radius: 5px;
  position: relative;

  div.chat-editor-container-column {
    div.chat-editor-field,
    div.file-uploader-field,
    div.show-recorder-field {
      padding-top: 5px !important;
    }
  }
}

div.chat-editor-component.chat-editor-component-reply {
  grid-template-rows: 75px 46px 1fr !important;
}