div.popup-template-messages-component {
  position: relative;
  
  span.popup-template-messages-trigger {
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 2px 4px;
    cursor: pointer;
    transition: 0.3s;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #606060;

    svg {
      width: 24px;
      min-width: 24px;
    }
  }

  span.popup-template-messages-trigger:hover {
    background-color: #dadada;
  }
  span.popup-template-messages-trigger:hover,
  span.popup-template-messages-trigger.popup-template-messages-component-opened {
    background-color: #dddddd;
  }

  div.popup-template-messages {
    position: absolute;
    right: 0;
    bottom: 40px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 1px 8px -2px rgb(0, 0, 0, 0.44);
    border-radius: 5px;
    padding: 10px;
    min-width: 440px;
    max-width: 440px;

    display: flex;
    flex-direction: column;
    z-index: 10;

    div.popup-template-messages-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      user-select: none;

      span.popup-template-messages-header-title {
        color: #0c2461;
        font-weight: 600;
        font-family: Montserrat, system-ui;
        font-size: 18px;
        gap: 3px;
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          min-width: 20px;
        }
      }

      div.popup-template-messages-header-tabs {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        
        span.popup-template-messages-header-tab-item {
          font-size: 12px;
          background-color: #ececec;
          padding: 3px 6px;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.3s;
        }

        span.popup-template-messages-header-tab-item:hover {
          background-color: #cecece;
        }

        span.popup-template-messages-header-tab-item.popup-template-messages-header-tab-active {
          background-color: #4a69bd;
          color: #fff;
        }
      }
    }
    
    div.popup-template-messages-search {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      input {
        width: 100%;
        padding: 6px 30px 6px 35px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-color: #f4f4f4;
        color: #616161;
      }

      svg.search-icon-label {
        position: absolute;
        left: 5px;
        color: #9a9a9a;
      }

      svg.search-icon-clear {
        position: absolute;
        color: #f36a6b;
        right: 5px;
        width: 20px;
        min-width: 20px;
        cursor: pointer;
        transition: 0.3s;
      }

      svg.search-icon-clear:hover {
        color: #e45555;
      }
    }

    div.popup-template-messages-body {
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 100px;
      max-height: 200px;
      overflow-y: auto;
      margin-top: 5px;
      scrollbar-width: thin;
      scrollbar-gutter: stable;
      border-radius: 5px;

      div.popup-template-messages-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        user-select: none;

        svg {
          color: #4a69bd;
          height: 45px;
          width: 45px;
          min-width: 45px;
          stroke-width: 1.5;
        }

        span {
          margin-top: 5px;
          color: #4a69bd;
          text-align: center;
        }
      }

      div.popup-template-message-body-item:not(:last-child) {
        margin-bottom: 5px;
      }

      div.popup-template-message-body-item {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        padding: 10px;
        cursor: pointer;
        transition: 0.3s;
        background-color: #f9f9f9;
        border-radius: 5px;
        margin-left: 2px;
        margin-right: 2px;

        span.popup-template-message-body-item-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 5px;

          span.popup-template-message-body-item-header-title {
            font-weight: 600;
            word-wrap: break-word;
            word-break: break-word;
            white-space: normal;
          }

          svg {
            color: #4a69bd;
            margin-left: 5px;
            width: 18px;
            min-width: 18px;
            height: 18px;
          }
        }

        span.popup-template-message-body-item-message {
          word-wrap: break-word;
          word-break: break-word;
          white-space: normal;
        }

        span.popup-template-message-body-item-footer {
          margin-top: 5px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 12px;
          color: #919191;
        }
      }

      div.popup-template-message-body-item:hover {
        background-color: #e4e4e4;
      }

      div.popup-template-message-body-item.template-message-selected {
        background-color: #d0d9f0;
        cursor: default;
      }
    }

    div.popup-template-messages-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;

      span.popup-template-messages-footer-action {
        display: flex;
        align-items: center;
        gap: 3px;
        color: #f36a6b;
        background-color: #f3f3f3;
        border-radius: 5px;
        padding: 2px 8px;
        cursor: pointer;
        transition: 0.3s;

        svg {
          width: 20px;
          min-width: 20px;
        }
      }

      span.popup-template-messages-footer-action:hover {
        background-color: #dedede;
      }
    }
  }
}
