div.acquire-platform-bar-component {
  background-color: #e5ecff;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    padding: 5px 10px;

    span.acquire-platform-bar-message {
      color: #0c2461;
  
      span {
        color: #4a69bd;
        font-weight: bold;
      }
    }
  }
}


div.acquire-platform-bar-component:hover {
  background-color: #cdd9fa;
}