div.toolbar-linkify-component {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 36px;
  height: 34px;
  transition: 0.3s;
  border-radius: 5px;
  
  div.toolbar-linkify-swatch {
    cursor: pointer;
    position: relative;
    display: flex;
    
    svg {
      color: #888;
      stroke-width: 1.3;
    }

    div.toolbar-linkify-color {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      bottom: -1px;
      right: 0px;
    }
  }

  div.toolbar-linkify-popover {
    position: absolute;
    z-index: 2;
    bottom: 40px;
    border: 1px solid #ececec;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
    padding: 10px;

    div.toolbar-linkify-popover-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      span.toolbar-linkify-popover-title {
        font-size: 18px;
        font-weight: 500;
      }

      svg {
        height: 18px;
        width: 18px;
        min-width: 18px;
        color: #717171;
        cursor: pointer;
        transition: 0.3s;
      }

      svg:hover {
        color: #505050;
      }
    }

    div.toolbar-linkify-popover-body {
      display: flex;
      flex-direction: column;

      div.toolbar-linkify-popover-body-item {
        display: flex;
        align-items: center;

        span.toolbar-linkify-popover-body-item-text-label {
          text-wrap: nowrap;
        }
        input {
          margin-left: 10px;
          border-radius: 5px;
          border: 1px solid gainsboro;
          width: 100%;
          padding: 5px 6px;
        }

        input.toolbar-linkify-popover-url-invalid {
          border: 1px solid #f36a6b;
        }
      }

      div.toolbar-linkify-popover-body-item:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    div.toolbar-linkify-popover-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;

      button {
        border: none;
        border-radius: 5px;
        padding: 4px 6px;
        transition: 0.3s;
      }

      button:not(:last-child) {
        margin-right: 10px;
      }

      button.toolbar-linkify-popover-action-submit {
        background-color: #4a69bd;
        color: #fff;
      }

      button.toolbar-linkify-popover-action-cancel:hover {
        background-color: #cfcfcf;
      }
      button.toolbar-linkify-popover-action-submit:hover {
        background-color: #3957ac;
      }

      button.toolbar-linkify-popover-action-submit:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }

  }
}

div.toolbar-linkify-component:hover {
  background: #EEF3FF;
}
