.list-tickets-page {
  // display: flex;
  // flex-direction: column;
  width: 100%;
  display: grid;
  min-height: 0;
  min-width: 0;
  grid-template-rows: auto 1fr;

  div.page-header {
    // width: fit-content;
    display: flex;
    justify-content: space-between;

    div.header-info {
      // display: flex;
      // flex-direction: column;
      width: fit-content;
    }

    div.header-actions {
      // display: flex;
      width: fit-content;
    }
  }

  div.page-counters {
    width: 100%;
    margin-bottom: 14px;
  }

  div.list-tickets-filter-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px;
    border: 1px solid #ececec;
    margin: 0px 14px 14px 14px;
    border-radius: 5px;
    max-height: 56px;
    user-select: none;

    div.list-tickets-filter-info-left {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 8px;
      width: 100%;

      div.list-tickets-filter-info-label {
        span {
          color: #0C2461;
          font-weight: 600;
          font-size: 16px;
          white-space: nowrap;
        }
      }

      div.list-tickets-filter-info-list {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        overflow-y: auto;
        min-height: 38px;
        max-height: 38px;
        background-color: #f5f5f5;
        padding: 5px 8px;
        border-radius: 5px;
        width: 100%;

        span.list-tickets-filter-item {
          display: flex;
          align-items: center;
          background-color: #4a69bd;
          color: #fff;
          border-radius: 5px;
          font-size: 13px;
          padding: 1px 4px 1px 6px;
          gap: 5px;
          cursor: default;
          height: fit-content;
    
          svg {
            width: 13px;
            height: 13px;
            cursor: pointer;
          }
        }
      }

    }

    span.list-tickets-filter-info-clear {
      background-color: #e3eafa;
      color: #4a69bd;
      display: flex;
      height: 100%;
      padding: 7px 12px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
    }

    span.list-tickets-filter-info-clear:hover {
      background-color: #beccee;
    }
  }

  div.list-tickets-page-content {
    // display: flex;
    // width: fit-content;
    display: grid;
    min-height: 0;
    min-width: 0;
    padding: 0px 14px 14px 14px;
    // height: 100%;
  }

  div.list-tickets-page-content.compact-list {
    display: flex;
    // height: 100%;
  }

  div.list-tickets-page-content.list-tickets-compact-list-hide {
    height: 100%;
  }
}

div.list-tickets-page.list-tickets-sidebar-opened {
  transition: 0.3s;
  width: calc(100% - 247px);
}

div.list-tickets-page.list-tickets-sidebar-closed {
  transition: 0.3s;
  width: calc(100% - 52px);
}

@media only screen and (max-width: 870px) {
  div.list-tickets-page {
    div.list-tickets-page-content.compact-list {
      flex-direction: column;
    }
  }
}