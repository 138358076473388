div.custom-input-component {
  display: flex;
  align-items: center;
  width: 100%;

  input, textarea {
    color: #212529;
    font-size: 15px;
    min-height: 40px;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px 4px 5px 8px;
    transition: 0.3s;
  }

  textarea {
    max-height: 60px;
    height: unset;
    scrollbar-width: thin;
  }

  input::placeholder,
  textarea::placeholder {
    color: #919191;
  }

  input.custom-input-component-invalid,
  textarea.custom-input-component-invalid {
    border: 1px solid #f36a6b;
  }

  input.custom-input-component-invalid::placeholder,
  textarea.custom-input-component-invalid::placeholder {
    color: #ffa2a2;
  }

  input:not(.custom-input-component-invalid):focus,
  textarea:not(.custom-input-component-invalid):focus {
    border: 1px solid #4a69bd;
  }

  div.tags-input {
    width: 100%;

    .custom-input-tags {
      width: 100%;
      border-radius: 5px;
      min-height: 45px;
      max-height: 90px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 4px;
      scrollbar-width: thin;
      transition: 0.3s;
  
      --tag-bg: #cfdcff;
      --tag-hover: #cfdcff;
  
      .tagify__tag:hover {
        transform: none;
      }
    }
  }

  div.react-datepicker-wrapper {
    width: 100%;

    div.react-datepicker__input-container {
      display: flex;
      align-items: center;

      svg.react-datepicker__calendar-icon {
        padding: 6px;
        color: #707070;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: 0.3s;
      }

      svg.react-datepicker__calendar-icon:hover {
        color: #4f4f4f;
      }

      input.custom-input-datepicker {
        display: flex;
        align-items: center;
        padding-left: 30px;
        font-size: 15px;
        color: #707070;
        cursor: pointer;
        caret-color: transparent;
        padding-top: 7px;
      }

      button.custom-input-datepicker-clear {
        padding-right: 10px;
      }

      button.custom-input-datepicker-clear::after {
        color: #de2450;
        background-color: #fff;
        font-size: 28px;
        font-weight: 300;
      }
    }
  }

  div.react-datepicker__tab-loop {
    div.react-datepicker-popper {
      z-index: 11;
      
      div {
        div.react-datepicker {
          display: flex;
          border: 1px solid #ececec;
          box-shadow: 0px 0px 10px -6px #000;

          div.react-datepicker__triangle::before {
            border-bottom-color: #d1d1d1;
          }

          div.react-datepicker__triangle::after {
            border-bottom-color: #fff;
          }

          button.react-datepicker__navigation {
            top: 10px;
          }

          div.react-datepicker__month-container {
            div.react-datepicker__header {
              border-bottom: 1px solid #ececec;

              div.react-datepicker__current-month {
                text-transform: capitalize;
                color: #4a69bd;
              }

              div.react-datepicker__day-names {
                div.react-datepicker__day-name {
                  color: #707070;
                }
              }
            }
          }
        }

        div.react-datepicker.custom-input-datepicker-size-custom {
          div.react-datepicker__triangle {
            transform: translate(calc(240px / 2), 0px) !important;
            margin-left: unset !important;
          }
        }

        div.react-datepicker.custom-input-timepicker {
          width: 275px;

          div.react-datepicker__time-container {
            width: 275px;

            div.react-datepicker__time {
              div.react-datepicker__time-box {
                width: 275px;

                ul.react-datepicker__time-list {
                  display: flex;
                  flex-wrap: wrap;
                  scrollbar-width: thin;
                }
              }
            }
          }
        }
      }
    }
  }

  div.react-tel-input.custom-input-phone {
    input:not(.search-box) {
      padding: 5px 4px 5px 50px;
      width: 100%;
      font-size: 15px;
      color: #212529;
    }

    div.flag-dropdown.open {
      width: 100%;
    }

    div.flag-dropdown {
      div.selected-flag::before {
        outline: none;
        box-shadow: unset;
        border: none;
      }

      .custom-input-phone-dropdown {
        width: 100%;
        scrollbar-width: thin;
        box-shadow: -1px 0px 9px 0px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        border: 1px solid #ececec;
        user-select: none;

        li.search {
          box-shadow: 0px 2px 11px -7px rgba(0, 0, 0, 0.7) !important;
          height: 40px;
          padding: 0;

          input.search-box {
            margin-left: 0;
            border: none;
            padding: 10px 10px 10px 10px;
            color: #919191;
            font-size: 15px;
          }
        }

        li.country {
          padding: 5px 10px;
          display: flex;
          align-items: center;
          transition: 0.3s;

          div.flag {
            position: relative;
            left: unset;
            top: unset;
            margin-top: -5px;
          }
        }

        li.country:hover {
          background-color: #f7f7f7;
        }

        li.country:not(:last-child) {
          border-bottom: 1px solid #ececec;
        }
      }
    }
  }

  div.react-tel-input.custom-input-phone.custom-input-phone-dropdown-disabled {
    div.flag-dropdown {
      div.selected-flag {
        cursor: default !important;
      }
    }
  }
}

div.custom-input-component.custom-input-component-disabled {
  input, textarea {
    background-color: #ececec !important;
    cursor: default !important;
  }

  svg {
    cursor: default !important;
  }
}