div.filtered-view-bar-component {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px;
  border: 1px solid #ececec;
  margin: 0px 14px 14px 14px;
  border-radius: 5px;
  max-height: 56px;
  user-select: none;

  div.filtered-view-bar-info-left {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
    width: 100%;

    div.filtered-view-bar-info-label {
      span {
        color: #0C2461;
        font-weight: 600;
        font-size: 16px;
        white-space: nowrap;
      }
    }

    div.filtered-view-bar-info-list {
      display: flex;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
      overflow-y: auto;
      min-height: 38px;
      max-height: 38px;
      background-color: #f5f5f5;
      padding: 5px 8px;
      border-radius: 5px;
      width: 100%;

      span.filtered-view-bar-item {
        display: flex;
        align-items: center;
        background-color: #4a69bd;
        color: #fff;
        border-radius: 5px;
        font-size: 13px;
        padding: 1px 4px 1px 6px;
        gap: 5px;
        cursor: default;
        height: fit-content;
  
        svg {
          width: 13px;
          height: 13px;
        }

        svg:not(.filtered-view-bar-item-icon-fixed) {
          cursor: pointer;
        }
      }

      span.filtered-view-bar-item.filtered-view-bar-item-fixed {
        background-color: #717171;
        font-weight: 600;
      }
    }

  }

  div.filtered-view-bar-actions {
    display: flex;
    align-items: center;
    gap: 10px;

    span.filtered-view-bar-info-clear {
      background-color: #e3eafa;
      color: #4a69bd;
      display: flex;
      height: 100%;
      padding: 7px 12px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
    }

    span.filtered-view-bar-info-clear:hover {
      background-color: #beccee;
    }
  
    span.filtered-view-bar-info-back {
      background-color: #717171;
      color: #fff;
      display: flex;
      height: 100%;
      padding: 7px 12px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
      text-wrap: nowrap;
    }

    span.filtered-view-bar-info-back:hover {
      background-color: #616161;
    }

  }

}