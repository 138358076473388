.dropdownButtonRedBorder {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid red !important;
    font: normal normal normal calc(0.2em + 0.5vw) 'Montserrat' !important;
    color: rgb(112, 112, 112) !important;
    width: 100% !important;
    height: 5vh !important;
    text-align: left !important;
    border-radius: 0.3rem !important;
}

.dropdownButtonRedBorder::after {
    color: #6A6A6A !important;
    margin-left: 55% !important;
    position: relative;
    border-top: 0.6em solid !important;
    border-left: 0.4em solid transparent !important;
    border-right: 0.4em solid transparent !important;
    vertical-align: 0.0em !important;
}


.dropdownButtonRedBorderFontNormal {
    font: normal normal normal calc(0.2em + 0.5vw) 'Montserrat' !important;
}

.dropdownButtonRedBorderRegisterMobile {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid red !important;
    font: var(--px10_13-font) /* !important */;
    color: rgb(112, 112, 112) !important;
    width: 100% !important;
    height: 5vh !important;
    text-align: left !important;
    border-radius: 0.3rem !important;
}

.dropdownButtonRedBorderRegisterMobile::after {
    color: #6A6A6A !important;
    margin-left: 40% !important;
    position: relative;
    border-top: 0.6em solid !important;
    border-left: 0.4em solid transparent !important;
    border-right: 0.4em solid transparent !important;
    vertical-align: 0.0em !important;
}

.backgroundImage {
    background-color: #232960;
}

.backgroundLogin {
    background-color: #F6F7F8;
    height: 90.5%;
    padding-top: 21.5vh;
}

.backgroundLogin2 {
    background-color: #FFFFFF;
    font: normal normal normal calc(0.2em + 0.7vw) Montserrat;
    color: #707070;
    height: 9.5%;
}

.textFontBlue {
    text-align: left;
    font: normal normal bold calc(0.5em + 0.7vw) Montserrat;
    color: #0C2461;
}

.textFontGlay {
    text-align: left;
    font: var(--px12-font) !important;
    color: #707070;
}

.textFontGlayMobile {
    text-align: left;
    font: var(--px17_24-font);
    color: #707070;
}

.textHeaderMobile {
    font: var(--px17_24_Bold-font) !important;
}

@media only screen and (min-width: 670px ) and (max-width: 1000px) {
    .textFontGlayMobile {
        text-align: left;
        font: var(--px16_24-font);
        color: #707070;
    }

    /* .textFontBlue {
        text-align: left;
        font: var(--px21_33-Montserrat);
    }*/

    .textHeaderMobile p {
        /* font: var(--px21_33_Bold-Montserrat) !important; */
        font: var(--px17_32_Bold-Montserrat) !important;
    }

    .paddingLarger {
        padding-left: 4.2vw;
        padding-right: 4.2vw;
        padding-bottom: 1vh;
    }

    .textBlackBoldMobile {
        font: var(--px16_24_Bold-font) !important;
    }
    .textBlackMobile {
        font: var(--px16_24_Bold-font) !important;
    }
} 

/* font: var(--px15_19-font) !important; */
/* 
    min(max(10px, calc(0.2em + 0.65vw)), 15px) 
    min(max(17px, calc(0.2em + 1.3vw)), 24px)
*/

.LinkGlay {
    text-decoration: none;
    color: #707070;
    font: normal normal normal calc(0.2em + 0.7vw) 'Montserrat';
}

.textBlack {
    text-align: left;
    font: normal normal bold calc(0.5em + 0.5vw) 'Montserrat';
    color: #1E1E1E;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer
}

.textBlackMobile {
    text-align: left;
    font: var(--px14_22_Bold-Montserrat) /* !important */;
    color: #1E1E1E !important;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer
}

.textBlackBold {
    font: var(--px15_19_Bold-font);
    color: var(--black-color);
    text-decoration: 'none';
    background-color: 'transparent';
    border: 'none';
    cursor: 'pointer';
}

.textBlackBoldMobile {
    font: var(--px14_22_Bold-Montserrat);
    color: var(--black-color);
    text-decoration: 'none';
    background-color: 'transparent';
    border: 'none';
    cursor: 'pointer';
}

.registerDesktopHeader {
    text-align: left;
    font: var(--px21_33-Montserrat);
    letter-spacing: 0px;
    color: #FFFFFF; 
}

.registerDesktopOrangeLabel { 
    background: #EF863C 0% 0% no-repeat padding-box; 
    border-radius: 3px;
    opacity: 1;
    padding: 0.8px 5px 0.8px 5px; 
    font: var(--px21_67_Bold-Montserrat);
}

.registerDesktopBoldText {
    font: var(--px21_33_Bold-Montserrat);
}

.boldTextHeader {
    font: var(--px21_33_Bold-Montserrat);
}

@media only screen and (max-width: 1280px) {
    .registerDesktopOrangeLabel {
        font: normal normal bold min(max(20px, calc(0.2em + 5vw)), 28px) 'Montserrat';
    }

    .registerDesktopHeader {
        font: normal normal normal min(max(18px, calc(0.2em + 4vw)), 26px) 'Montserrat';
    }

    .boldTextHeader {
        font: normal normal bold min(max(20px, calc(0.2em + 5vw)), 26px) 'Montserrat';
    }

    .backgroundLogin {
        padding-top: 12vh ;
    }
    
}