button.create-button-component {
  display: flex;
  align-items: center;
  background-color: #4a69bd;
  color: #fff;
  height: 35px;
  border-radius: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 10px;
  font-size: 14px;
  transition: 0.3s;
  cursor: pointer;
  border: none;
  outline: none;

  svg {
    height: 18px;
    width: 18px;
    margin-right: 4px;
    margin-bottom: 1px;
  }

  span.create-button-text {
    // margin-top: 1px;
    text-transform: capitalize;
  }
}

button.create-button-component:not(:disabled):hover {
  background-color: #2c4ba1;
}

button.create-button-component:disabled {
  opacity: 0.5;
  cursor: default;
}