.controlPanelSecurity {
  div.security-section {
    display: flex;
    flex-direction: column;

    span.section-title {
      border-bottom: 1px solid #ececec;
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-weight: 500;
    }

    div.section-content {
      display: flex;
      flex-direction: column;

      .section-form {
        div.content-step {
          display: flex;
          flex-direction: column;
          margin-bottom: 1vh;
          color: #0C2461;
          font-family: Montserrat;
  
          span.step {
            font-weight: 600;
          }
  
          img.qrcode {
            width: 166px;
            height: 166px;
            margin-top: 1vh;
            margin-bottom: 1vh;
            margin-left: 2vh;
          }
  
          span.secret {
            margin-left: 2vh;
            font-size: 15px;
          }
        }

        .error-code {
          position: absolute;
          bottom: -20px;
          margin-left: 2vh;
          width: fit-content;
        }
  
        input.input-code {
          margin-left: 2vh;
          max-width: 210px;
        }
  
        .font-gray {
          text-align: left;
          font: normal normal normal calc(0.2em + 0.7vw) Montserrat;
          letter-spacing: 0px;
          color: #707070;
          opacity: 1;
        }
      }

      .section-action {
        padding-left: 4rem;        
  
        button.btn-save-config {
          background-color: #2C4CA2;
          color: #fff;
          width: fit-content;
          outline: none;
          border: none;
          font-weight: 400;
          padding: 0.8vh 2vh;
          font-family: Montserrat;
        }
      }

      button.btn-remove-config {
        width: fit-content;
        background-color: #fff;
        border: 1px solid var(--red-color);
        border-radius: 4px !important;
        color: var(--red-color);
        border: 1px solid var(--red-color);
        opacity: 1;
        height: min(max(35px, 4.7vh), 70px) !important;
        font: var(--px15_19-font) !important;
      }

    }
  }
}
