div.modal-modal-expired-component {
  div.modal-header {
    gap: 10px;
    user-select: none;
    box-shadow: 0px 0px 12px -8px;
    z-index: 1;

    div.modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    button.btn-close {
      margin-top: -15px;
      transition: 0.3s;
    }

    div.modal-modal-expired-header-actions {
      display: flex;
      gap: 6px;

      svg {
        cursor: pointer;
        color: #707070;
        transition: 0.3s;
      }

      svg:hover {
        color: #606060;
      }
    }
  }

  div.modal-body {
    user-select: none;
    min-height: 86px;

    div.modal-modal-expired-section.section-margin-top {
      margin-top: 20px;
    }

    div.modal-modal-expired-section {
      display: flex;
      flex-direction: column;
      gap: 5px;

      div.modal-modal-expired-item {
        width: 100%;
        position: relative;
        text-align: justify;

        div.modal-modal-expired-item-header {
          user-select: none;

          span.modal-modal-expired-item-label {
            font-size: 16px;
            font-weight: 600;
            color: #717171;
          }

          span.modal-modal-expired-item-email {
            font-size: 16px;
            font-weight: 600;
            color: #717171;
            user-select: text;
          }
        }
      }
    }
  }

  div.modal-footer {
    border-top: unset;
    justify-content: space-between;
    box-shadow: 0px -3px 17px -13px #0000006e;
    z-index: 1;

    button {
      border-radius: 5px;
      padding: 5px 10px;
      transition: 0.3s;
      cursor: pointer;
      min-width: 100px;
    }

    button.modal-modal-expired-btn-save {
      background-color: #4a69bd;
      color: #fff;
      border: none;
    }

    button.modal-modal-expired-btn-save:not(:disabled):hover {
      background-color: #3856a7;
    }

    button.modal-modal-expired-btn-save:disabled {
      opacity: 0.4;
      cursor: default;
    }

    button.modal-modal-expired-btn-cancel {
      background-color: #fff;
      color: #4a69bd;
      border: 1px solid #4a69bd;
    }

    button.modal-modal-expired-btn-cancel:hover {
      background-color: #e8e8e8;
    }
  }
}