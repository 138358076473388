div.colorPicker {
  position: relative;

  .colorSets {
    padding: 12px 0px 0 12px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    position: absolute;
    z-index: 1000;
    box-sizing: content-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 30px 0 gainsboro;
    top: -45px;
  }
  
  .colorItem {
    width: 13px;
    height: 13px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
    position: relative;
    background-size: contain;
    overflow: hidden;
    box-sizing: border-box;
    vertical-align: middle;
    transition: transform 0.3s;
  }
  
  .empty {
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    background-image: linear-gradient(45deg, #ccc 25%, transparent 0), linear-gradient(-45deg, #ccc 25%, transparent 0), linear-gradient(45deg, transparent 75%, #ccc 0), linear-gradient(-45deg, transparent 75%, #ccc 0);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  
  .colorView{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
  }
  
  .active {
    border: 1px solid black;
  }
  
  .colorItem:hover {
    transform: scale(1.2);
  }
}

div.colorPicker.colorPickerActive {
  background-color: #EEF3;
}