div.popup-recipients-email-component {
  position: relative;
  
  span.popup-recipients-email-trigger {
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 2px 4px;
    cursor: pointer;
    transition: 0.3s;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #606060;

    svg {
      width: 24px;
      min-width: 24px;
    }
  }

  span.popup-recipients-email-trigger:hover {
    background-color: #dadada;
  }
  span.popup-recipients-email-trigger:hover,
  span.popup-recipients-email-trigger.popup-recipients-email-component-popup-opened {
    background-color: #dddddd;
  }

  div.popup-recipients-email-popup {
    position: absolute;
    right: 0;
    bottom: 40px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 1px 8px -2px rgb(0, 0, 0, 0.44);
    border-radius: 5px;
    padding: 10px;
    min-width: 370px;

    display: flex;
    flex-direction: column;
    z-index: 10;

    div.popup-recipients-email-popup-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      user-select: none;

      span.popup-recipients-email-popup-header-title {
        color: #0c2461;
        font-weight: 600;
        font-family: Montserrat, system-ui;
        font-size: 18px;
      }

      div.popup-recipients-email-popup-header-actions {
        display: flex;
        align-items: center;

        svg:not(:last-child) {
          margin-right: 4px;
        }

        svg {
          color: #707070;
          background-color: #f5f5f5;
          padding: 4px;
          width: 30px;
          height: 30px;
          border-radius: 5px;
          transition: 0.3s;
          cursor: pointer;
        }

        svg:hover,
        svg.popup-recipients-email-popup-header-action-active {
          background-color: #dcdcdc;
        }
      }
    }
    
    div.popup-recipients-email-popup-body {
      display: flex;
      flex-direction: column;
      position: relative;

      div.popup-recipients-email-popup-body-item:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    div.popup-recipients-email-popup-footer {
      display: flex;
      align-content: center;
      justify-content: flex-end;
      margin-top: 15px;

      button:not(:last-child) {
        margin-right: 10px;
      }

      button {
        outline: none;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        transition: 0.3s;
      }

      button.popup-recipients-email-popup-footer-save {
        background-color: #4a69bd;
        color: #fff;
      }

      button.popup-recipients-email-popup-footer-save:disabled {
        opacity: 0.5;
      }

      button.popup-recipients-email-popup-footer-cancel:hover {
        background-color: #dadada;
      }

      button.popup-recipients-email-popup-footer-save:hover {
        background-color: #2d4b9c;
      }
    }
  }
}
